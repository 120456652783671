import update from 'immutability-helper';
const INIT_STATE = {
  data:null,
  active_applicant:null,
}


export default (state = INIT_STATE,action) => {
    switch(action.type){
      case 'SET_DOCUMENTSCOLLECTION':
        return update(state,{
          data:{$set:action.payload}
        })
      default:
        return state
    }
  }
  