import React, { Component } from 'react'
import { connect } from 'react-redux'

import server from "../../api/server"

export class Identification extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             phone:"",
             disabled:false,
             started:false
        }
    }
    componentDidMount() {
        console.log(this.props)
    }
    formSubmit = async (e) =>{
        e.preventDefault();
        if(this.state.phone != "" && this.props.name){
            this.setState({disabled:true})
            const response = await server.post('/startvoi',{phone:this.state.phone,name:this.props.name,id:this.props.id})
            this.setState({started:true})
        }
    }

    renderContent = () =>{
        if(this.state.started || (this.props.VOI != null && this.props.VOI.progress == false)){
            return(
                <div class="card card-custom">
                <div class="card-header">
                <h3 class="card-title">
                Verification of Identity Started
                </h3>
                </div>

                <div class="card-body">
                    <p>Please follow the prompts send to your phone to complete your VOI.{this.props.VOI != null && this.props.VOI.progress == false ? <span> Alternatively open <a href={`https://mortgagechoice.au.idkit.com/${this.props.VOI.transaction_id}`} target="_blank">this</a> link on your mobile phone.</span>:null} Once complete you will be returned to your LoanGateway Dashboard.</p>


                </div>
                </div>
            )
        }else if(this.props.VOI != null && this.props.VOI.progress == true){
            return(
                <div class="card card-custom">
                <div class="card-header">
                <h3 class="card-title">
                Verification of Identity Processing
                </h3>
                </div>

                <div class="card-body">
                    <p>We are currently processing your verification of identity.</p>
                    <p>Thank you!</p>


                </div>
                </div>
            )
        }
        else{
            return(
                <div class="card card-custom">
                <div class="card-header">
                <h3 class="card-title">
                Start Verification of Identity
                </h3>
                </div>

                <form onSubmit = {(e) =>{this.formSubmit(e)}}>
                <div class="card-body">

                    <div className="form-group">
                        <span>Some text about the VOI and the process and what to do write sometrhing for this jobs jobs jobs rome wasnt built in a day.</span>
                    </div>

                    <div class="form-group">
                        <label>Mobile <span class="text-danger">*</span></label>
                        <input required value={this.state.phone} onChange={(e) =>{this.setState({phone:e.target.value})}} type="text" class="form-control"  placeholder="Phone Number"/>
                        {/* <span class="form-text text-muted">We'll never share your email with anyone else.</span> */}
                    </div>
                    </div>
                    <div class="card-footer">
                    <button disabled={this.state.disabled} type="submit" class={`btn btn-primary ${this.state.disabled ? "spinner spinner-white spinner-right":""}`}>{this.state.disabled ? "Starting Verification":"Start Verification"}</button>
                    {/* <button type="reset" class="btn btn-secondary">Cancel</button> */}
                    </div>
                </form>

                </div>
            )
        }
    }

    render() {
        return (
            <div>
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    {this.renderContent()}
                </div>


            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    id:state.id,
    VOI:state.VOI
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Identification)
