import React from 'react';
import { connect } from 'react-redux';
import { updateData } from '../../actions';
import server from '../../api/server';



class Loan_Requirements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            why: {
                wide_choice: false,
                expert_advice: false,
                convienence: false,
                legwork: false,
                existing_customer: false
            },
            what_can_assist: {
                purchase: false,
                refinance: false,
                build_new_home: false,
                existing_loan: false
            },
            property: "",
            payment_plan: {
                repayment_prior_to_retirement: false,
                downsizing: false,
                sale_of_assets: false,
                super_income: false,
                super_lump_sum: false,
                savings: false,
                other_investments: false,
                co_app_income: false,
                other_check: false,
                other: ""
            },
            loan_term: "",
            loan_term_other: "",
            changes_in_financial_circum: "",
            changes_in_financial_circum_yes: "",
            loan_repayment_type: "",
            interest_only_term: "",
            rate_type: "",
            important_features: {
                basic_home_loan: {
                    required: true,
                    importance: ""
                },
                offset_account: {
                    required: true,
                    importance: ""
                },
                redraw_facility: {
                    required: true,
                    importance: ""
                },
                professional_packages: {
                    required: true,
                    importance: ""
                },
                extra_repayments_allowed: {
                    required: true,
                    importance: ""
                }
            }
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if ('Loan_Requirements' in this.props.data) {
            const property = { ...this.state };
            Object.keys(this.props.data['Loan_Requirements']).map(key => {
                if (key != "errorMessage") {
                    property[key] = this.props.data['Loan_Requirements'][key];
                }
            });
            this.setState(property);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        clearTimeout(prevState.timeoutID);
    }

    formChange = (e) => {
        const update = async () => {
            this.props.updateData({ state: this.state, type: "Loan_Requirements" });
        };
        const timeoutID = setTimeout(function () {
            update();
        }, 250);
        this.setState({ timeoutID: timeoutID });
    };

    setChecked = (category, field) => {
        const property = { ...this.state[category] };
        property[field] = !property[field];
        this.setState({ [category]: property });
    };

    handleChange = (e, category, field) => {

        const property = { ...this.state[category] };
        property[field] = e.target.value;
        this.setState({ [category]: property });

    };

    handleSelectChange = (e, category) => {
    };

    setImportantFeaturesCheck = (category) => {
        const property = { ...this.state.important_features };
        property[category].required = !property[category].required;
        this.setState({ important_features: property });
    };

    setImportantFeaturesImportance = (e, category) => {
        const property = { ...this.state.important_features };
        if (property[category].importance == e.target.value) {
            property[category].importance = "";
        } else {
            property[category].importance = e.target.value;
        }
        this.setState({ important_features: property });
    };

    checkImportantFeatures = () => {
        var flag = false;
        Object.keys(this.state.important_features).forEach(key => {
            if (this.state.important_features[key].importance == "") {
                console.log(this.state.important_features[key].importance);
                flag = true;
            }
        });
        console.log(flag);
        return flag;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.checkSubmit("why") == false) {
            this.setState({ errorMessage: "Please select at least one option for Question 1" });
        } else if (this.checkSubmit("what_can_assist") == false) {
            this.setState({ errorMessage: "Please select at least one option for Question 2" });
        } else if (this.state.property == '') {
            window.scrollTo(0, 0);
            this.setState({ errorMessage: "Please select an option for Question 3" });
        } else if (this.checkSubmit("payment_plan") == false) {
            this.setState({ errorMessage: "Please select at least one option for Question 4" });
        } else if (this.state.loan_term == '') {
            window.scrollTo(0, 0);
            this.setState({ errorMessage: "Please select an option for Question 5" });
        } else if (this.state.changes_in_financial_circum == "") {
            window.scrollTo(0, 0);
            this.setState({ errorMessage: "Please select an option for Question 6" });

        } else if (this.state.loan_repayment_type == "") {
            window.scrollTo(0, 0);
            this.setState({ errorMessage: "Please select an option for Question 7" });

        } else if (this.state.rate_type == "") {
            window.scrollTo(0, 0);
            this.setState({ errorMessage: "Please select an option for Question 9" });

        } else if (this.checkImportantFeatures()) {
            window.scrollTo(0, 0);
            this.setState({ errorMessage: "Please select an answer for each option for Question 10" });

        } else {
            server.post("/submit", { application_id: this.props.id });
            this.props.updateData({ state: this.state, type: "Loan_Requirements" });
            this.props.changeForm("Completed_Form");
        }

    };

    checkSubmit = (field) => {
        var flag = false;
        Object.keys(this.state[field]).map(key => {
            if (this.state[field][key] == true) {
                flag = true;
            }
        });
        if (!flag) {
            window.scrollTo(0, 0);
        }
        return flag;
    };


    renderErrors = () => {
        if (this.state.errorMessage) {
            return (
                <div class="ui red message">{this.state.errorMessage}</div>
            );
        } else {

        }
    };

    renderButtons = () => {
        if (this.props.showButtons === 'false') {
            return (null);
        } else {
            return (
                <div className="">
                    <button className="ui button primary " onClick={(e) => { this.props.changeForm("Assets"); }}>Previous Page</button>
                    <button className="ui button primary " type="submit" style={{ float: "right" }}>Complete Form</button>
                </div>
            );
        }
    };
    render() {
        return (
            <div>
                <form onSubmit={(e) => { this.handleSubmit(e); }} onChange={this.formChange}>
                    <div class="header">
                        <div class="tagline">
                            <h2>Your Loan Requirements</h2>
                        </div>
                        <div class="logo">
                            <a href="#"> <img src="images/logo.png" alt="" /> </a>
                        </div>
                    </div>
                    {this.renderErrors()}

                    <div class="flex-row">
                        <div class="col-50">
                            <div class="gridContent">
                                <h4><span>1 -</span> Why did you reach out to Mortgage Choice?</h4>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Wide choice of home loans - get a great deal
                                        <input type="checkbox" checked={this.state.why.wide_choice} onChange={() => this.setChecked('why', 'wide_choice')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Expert advice
                                        <input type="checkbox" checked={this.state.why.expert_advice} onChange={() => this.setChecked('why', 'expert_advice')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Convienence
                                        <input type="checkbox" checked={this.state.why.convienence} onChange={() => this.setChecked('why', 'convienence')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Someone to do all the legwork
                                        <input type="checkbox" checked={this.state.why.legwork} onChange={() => this.setChecked('why', 'legwork')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Existing customer of Mortgage Choice
                                        <input type="checkbox" checked={this.state.why.existing_customer} onChange={() => this.setChecked('why', 'existing_customer')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-50">
                            <div class="gridContent">
                                <h4><span>2 -</span> What can Mortgage Choice assist you with?</h4>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Purchase a property
                                        <input type="checkbox" checked={this.state.what_can_assist.purchase} onChange={() => this.setChecked('what_can_assist', 'purchase')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Refinance
                                        <input type="checkbox" checked={this.state.what_can_assist.refinance} onChange={() => this.setChecked('what_can_assist', 'refinance')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Build a new house
                                        <input type="checkbox" checked={this.state.what_can_assist.build_new_home} onChange={() => this.setChecked('what_can_assist', 'build_new_home')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Increase an existing loan
                                        <input type="checkbox" checked={this.state.what_can_assist.existing_loan} onChange={() => this.setChecked('what_can_assist', 'existing_loan')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex-row">
                        <div class="col-100">
                            <div class="gridContent">
                                <h4><span>3 -</span> Is this for your Primary Residence or an Investment Property?</h4>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Primary Residence
                                        <input type="checkbox" checked={this.state.property == 'Primary_Residence'} onChange={(e) => this.setState({ property: e.target.value })} defaultValue="Primary_Residence" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Investment Property
                                        <input type="checkbox" checked={this.state.property == 'Investment_Property'} onChange={(e) => this.setState({ property: e.target.value })} defaultValue="Investment_Property" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="flex-row">
                        <div class="col-50">
                            <div class="gridContent">
                                <h4><span>4 -</span> How do you plan to repay this loan?</h4>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Repayment of loan prior to retirement
                                        <input type="checkbox" checked={this.state.payment_plan.repayment_prior_to_retirement} onChange={() => this.setChecked('payment_plan', 'repayment_prior_to_retirement')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Downsizing home
                                        <input type="checkbox" checked={this.state.payment_plan.downsizing} onChange={() => this.setChecked('payment_plan', 'downsizing')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Sale of assets
                                        <input type="checkbox" checked={this.state.payment_plan.sale_of_assets} onChange={() => this.setChecked('payment_plan', 'sale_of_assets')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Recurring income from superannuation
                                        <input type="checkbox" checked={this.state.payment_plan.super_income} onChange={() => this.setChecked('payment_plan', 'super_income')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Superannuation lump sum following retirement
                                        <input type="checkbox" checked={this.state.payment_plan.super_lump_sum} onChange={() => this.setChecked('payment_plan', 'super_lump_sum')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Savings
                                        <input type="checkbox" checked={this.state.payment_plan.savings} onChange={() => this.setChecked('payment_plan', 'savings')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Income for other investments
                                        <input type="checkbox" checked={this.state.payment_plan.other_investments} onChange={() => this.setChecked('payment_plan', 'other_investments')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Co-applicant's income
                                        <input type="checkbox" checked={this.state.payment_plan.co_app_income} onChange={() => this.setChecked('payment_plan', 'co_app_income')} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox otherBox">
                                    <label class="checkBox-label">
                                        Other
                                        <input type="checkbox" checked={this.state.payment_plan.other_check} onChange={() => this.setChecked('payment_plan', 'other_check')} />
                                        <span class="checkmark"></span>
                                    </label>

                                    <div class="otherInput">
                                        <input type="text" value={this.state.payment_plan.other || ''} onChange={(e) => this.handleChange(e, 'payment_plan', 'other')} name="" defaultValue="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-50">
                            <div class="gridContent">
                                <h4><span>5 -</span> Preferred loan term (in years) <small>- commonly 30 years</small></h4>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        30 years
                                        <input type="checkbox" checked={this.state.loan_term == '30_Years'} onChange={(e) => this.setState({ loan_term: e.target.value })} defaultValue="30_Years" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        25 years
                                        <input type="checkbox" checked={this.state.loan_term == '25_Years'} onChange={(e) => this.setState({ loan_term: e.target.value })} defaultValue="25_Years" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        20 years
                                        <input type="checkbox" checked={this.state.loan_term == '20_Years'} onChange={(e) => this.setState({ loan_term: e.target.value })} defaultValue="20_Years" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox otherBox">
                                    <label class="checkBox-label">
                                        Other
                                        <input type="checkbox" checked={this.state.loan_term == 'Other'} onChange={(e) => this.setState({ loan_term: e.target.value })} defaultValue="Other" />
                                        <span class="checkmark"></span>
                                    </label>

                                    <div class="otherInput">
                                        <input class="w30" type="text" value={this.state.loan_term_other || ''} onChange={(e) => this.setState({ loan_term_other: e.target.value })} name="" defaultValue="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-row">
                        <div class="col-60">
                            <div class="gridContent">
                                <h4><span>6 -</span> Do you foresee any changes to your financial circumstances?</h4>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        No
                                        <input type="checkbox" checked={this.state.changes_in_financial_circum == 'No'} onChange={(e) => this.setState({ changes_in_financial_circum: e.target.value })} defaultValue="No" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="checkBox otherBox">
                                    <label class="checkBox-label">
                                        Yes
                                        <input type="checkbox" checked={this.state.changes_in_financial_circum == 'Yes'} onChange={(e) => this.setState({ changes_in_financial_circum: e.target.value })} defaultValue="Yes" />
                                        <span class="checkmark"></span>
                                    </label>

                                    <div class="otherInput">
                                        <input type="text" value={this.state.changes_in_financial_circum_yes || ''} onChange={(e) => this.setState({ changes_in_financial_circum_yes: e.target.value })} name="" defaultValue="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex-row">
                        <div class="col-60">
                            <div class="gridContent">
                                <h4><span>7 -</span> Which Loan Repayment type would you prefer? <a href="https://www.youtube.com/watch?v=Pb6RtxJt-3M" target="_blank"><i class="info circle icon"></i></a></h4>

                                <div class="selectBox">
                                    <select class="" name="" value={this.state.loan_repayment_type} onChange={(e) => { this.setState({ loan_repayment_type: e.target.value }); }}>
                                        <option value=""></option>
                                        <option defaultValue="">Principle & Interest - Minimise interest paid over life of loan</option>
                                        <option defaultValue="">Principle & Interest - Build up equity from the start</option>
                                        <option defaultValue="">Interest Only - Anticipated nonrecurring expense item</option>
                                        <option defaultValue="">Interest Only - Temporary reduction in income</option>
                                        <option defaultValue="">Interest Only - Variable and unpredictable income</option>
                                        <option defaultValue="">Interest Only - Taxation or accounting reasons</option>
                                        <option defaultValue="">Line of Credit - Assists with investment purchases and tax planning</option>
                                        <option defaultValue="">Line of Credit - Anticipated variable cash flows</option>
                                        <option defaultValue="">Line of Credit - Allows access to funds at any time</option>
                                        <option defaultValue="">Line of Credit - Flexibility to manage repayments</option>
                                        <option defaultValue="">Line of Credit - No need to apply for further lending in future</option>
                                        <option defaultValue="">Line of Credit - Ongoing need for funds</option>
                                        <option defaultValue="">Discuss with broker</option>
                                    </select>
                                </div>

                                <h4><span>9 -</span> Which Rate Type would you prefer? <a href="https://www.youtube.com/watch?v=xHdTugUFodw" target="_blank"><i class="info circle icon"></i></a></h4>
                                <div class="selectBox">
                                    <select class="" name="" value={this.state.rate_type} onChange={(e) => { this.setState({ rate_type: e.target.value }); }}>
                                        <option defaultValue=""></option>
                                        <option defaultValue="">No preference</option>
                                        <option defaultValue="">Fixed - Certainty of repayments</option>
                                        <option defaultValue="">Fixed - Make budgeting easier</option>

                                        <option defaultValue="">Variable - Take advanatage of potential decreases in rates</option>
                                        <option defaultValue="">Variable - Flexibility with regards to repayments</option>
                                        <option defaultValue="">Discuss with broker</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-40">
                            <div class="gridContent">
                                <h4><span>8 -</span> If interest only, select term</h4>

                                <div class="selectBox">
                                    <select class="" name="" value={this.state.interest_only_term} onChange={(e) => { this.setState({ interest_only_term: e.target.value }); }}>
                                        <option defaultValue=""></option>
                                        <option defaultValue="">1 Year</option>
                                        <option defaultValue="">2 Year</option>
                                        <option defaultValue="">3 Year</option>
                                        <option defaultValue="">4 Year</option>
                                        <option defaultValue="">5 Year</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex-row">
                        <div class="gridContent"></div>
                        <div class="col-100">
                            <div class="gridContent">
                                <h4><span>10 -</span> Which features are important to you for this loan?</h4>
                            </div>
                        </div>
                    </div>

                    <div class="flex-row">
                        <div class="col-20">
                            <div class="gridContent">
                                <h2>&nbsp;</h2>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Basic Home Loan <a href="https://www.youtube.com/watch?v=EhkHk8VFkrw" target="_blank"><i class="info circle icon"></i></a>
                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Offset Account <a href="https://www.youtube.com/watch?v=VSC0-y0e3Xg" target="_blank"><i class="info circle icon"></i></a>

                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Redraw Facility <a href="https://www.youtube.com/watch?v=VSC0-y0e3Xg" target="_blank"><i class="info circle icon"></i></a>

                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Professional Packages <a href="https://www.youtube.com/watch?v=EhkHk8VFkrw" target="_blank"><i class="info circle icon"></i></a>

                                    </label>
                                </div>

                                <div class="checkBox">
                                    <label class="checkBox-label">
                                        Extra Repayments Allowed

                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-20">
                            <div class="gridContent inlineCheck">
                                <h2>Must Have</h2>

                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.basic_home_loan.importance == 'Must Have'} onChange={(e) => this.setImportantFeaturesImportance(e, "basic_home_loan")} defaultValue="Must Have" />
                                    <span class="checkmark"></span>
                                </label>
                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.offset_account.importance == 'Must Have'} onChange={(e) => this.setImportantFeaturesImportance(e, "offset_account")} defaultValue="Must Have" />
                                    <span class="checkmark"></span>
                                </label>
                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.redraw_facility.importance == 'Must Have'} onChange={(e) => this.setImportantFeaturesImportance(e, "redraw_facility")} defaultValue="Must Have" />
                                    <span class="checkmark"></span>
                                </label>
                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.professional_packages.importance == 'Must Have'} onChange={(e) => this.setImportantFeaturesImportance(e, "professional_packages")} defaultValue="Must Have" />
                                    <span class="checkmark"></span>
                                </label>

                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.extra_repayments_allowed.importance == 'Must Have'} onChange={(e) => this.setImportantFeaturesImportance(e, "extra_repayments_allowed")} defaultValue="Must Have" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>

                        <div class="col-20">
                            <div class="gridContent inlineCheck">
                                <h2>Nice To Have</h2>

                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.basic_home_loan.importance == 'Nice To Have'} onChange={(e) => this.setImportantFeaturesImportance(e, "basic_home_loan")} defaultValue="Nice To Have" />
                                    <span class="checkmark"></span>
                                </label>
                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.offset_account.importance == 'Nice To Have'} onChange={(e) => this.setImportantFeaturesImportance(e, "offset_account")} defaultValue="Nice To Have" />
                                    <span class="checkmark"></span>
                                </label>
                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.redraw_facility.importance == 'Nice To Have'} onChange={(e) => this.setImportantFeaturesImportance(e, "redraw_facility")} defaultValue="Nice To Have" />
                                    <span class="checkmark"></span>
                                </label>
                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.professional_packages.importance == 'Nice To Have'} onChange={(e) => this.setImportantFeaturesImportance(e, "professional_packages")} defaultValue="Nice To Have" />
                                    <span class="checkmark"></span>
                                </label>

                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.extra_repayments_allowed.importance == 'Nice To Have'} onChange={(e) => this.setImportantFeaturesImportance(e, "extra_repayments_allowed")} defaultValue="Nice To Have" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>

                        <div class="col-20">
                            <div class="gridContent inlineCheck">
                                <h2>Must Not Have</h2>

                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.basic_home_loan.importance == 'Must Not Have'} onChange={(e) => this.setImportantFeaturesImportance(e, "basic_home_loan")} defaultValue="Must Not Have" />
                                    <span class="checkmark"></span>
                                </label>
                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.offset_account.importance == 'Must Not Have'} onChange={(e) => this.setImportantFeaturesImportance(e, "offset_account")} defaultValue="Must Not Have" />
                                    <span class="checkmark"></span>
                                </label>
                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.redraw_facility.importance == 'Must Not Have'} onChange={(e) => this.setImportantFeaturesImportance(e, "redraw_facility")} defaultValue="Must Not Have" />
                                    <span class="checkmark"></span>
                                </label>
                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.professional_packages.importance == 'Must Not Have'} onChange={(e) => this.setImportantFeaturesImportance(e, "professional_packages")} defaultValue="Must Not Have" />
                                    <span class="checkmark"></span>
                                </label>

                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.extra_repayments_allowed.importance == 'Must Not Have'} onChange={(e) => this.setImportantFeaturesImportance(e, "extra_repayments_allowed")} defaultValue="Must Not Have" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>

                        <div class="col-20">
                            <div class="gridContent inlineCheck">
                                <h2>Discuss with Broker</h2>

                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.basic_home_loan.importance == 'Discuss with broker'} onChange={(e) => this.setImportantFeaturesImportance(e, "basic_home_loan")} defaultValue="Discuss with broker" />
                                    <span class="checkmark"></span>
                                </label>
                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.offset_account.importance == 'Discuss with broker'} onChange={(e) => this.setImportantFeaturesImportance(e, "offset_account")} defaultValue="Discuss with broker" />
                                    <span class="checkmark"></span>
                                </label>
                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.redraw_facility.importance == 'Discuss with broker'} onChange={(e) => this.setImportantFeaturesImportance(e, "redraw_facility")} defaultValue="Discuss with broker" />
                                    <span class="checkmark"></span>
                                </label>
                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.professional_packages.importance == 'Discuss with broker'} onChange={(e) => this.setImportantFeaturesImportance(e, "professional_packages")} defaultValue="Discuss with broker" />
                                    <span class="checkmark"></span>
                                </label>

                                <br />
                                <label class="checkBox-label">
                                    &nbsp;
                                    <input type="checkbox" checked={this.state.important_features.extra_repayments_allowed.importance == 'Discuss with broker'} onChange={(e) => this.setImportantFeaturesImportance(e, "extra_repayments_allowed")} defaultValue="Discuss with broker" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="flex-row">
                        <div class="col-100">
                            <div class="gridContent">
                                <h4><span>11 -</span> Anything else you need to mention?</h4>
                                <textarea style={{ resize: "none" }} value={this.state.other_info} onChange={(e) => { this.setState({ other_info: e.target.value }); }} style={{ width: "100%", height: "150px" }} lines="10"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="flex-row">
                        <div class="gridContent"></div>
                        <div class="col-100">
                            <div class="gridContent educational-links">
                                <h4>Education</h4>
                                <iframe width="300" height="200" src="https://www.youtube.com/embed/VSC0-y0e3Xg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <iframe width="300" height="200" src="https://www.youtube.com/embed/EhkHk8VFkrw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <iframe width="300" height="200" src="https://www.youtube.com/embed/xHdTugUFodw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <iframe width="300" height="200" src="https://www.youtube.com/embed/Pb6RtxJt-3M" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>

                    </div>
                    {this.renderButtons()}
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { data: state.data, id: state.id };
};

export default connect(mapStateToProps, { updateData })(Loan_Requirements);