import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
var firebaseConfig
  // Initialize Firebase


if(process.env.REACT_APP_ENVIRONMENT == "production"){
  firebaseConfig = {
    apiKey: "AIzaSyDXT4rSJUJ3XymQJd7jD43zo76CIIWFyvg",
    authDomain: "genial-retina-298922.firebaseapp.com",
    projectId: "genial-retina-298922",
    storageBucket: "genial-retina-298922.appspot.com",
    messagingSenderId: "1063471211107",
    appId: "1:1063471211107:web:43629b728b74887ba3ce56",
    measurementId: "G-35QMQCJ9HG"
  };
}else{
  firebaseConfig = {
    apiKey: "AIzaSyAtelE3DOUSB2zAnTMHnrwKUZwWjV-mfkM",
    authDomain: "loangateway-test.firebaseapp.com",
    projectId: "loangateway-test",
    storageBucket: "loangateway-test.appspot.com",
    messagingSenderId: "827179938227",
    appId: "1:827179938227:web:01548cc6964c1b00945bc6",
    // measurementId: "G-35QMQCJ9HG"
  };
}
firebase.initializeApp(firebaseConfig);
export default firebase;