import React, { Component } from 'react'
import { connect } from 'react-redux'
import DashboardContainer from './DashboardContainer'
import AddAgentModal from './elements/AddAgentModal'
import AgentRow from './elements/AgentRow'
import SubHeader from './SubHeader'

export class Agents extends Component {

  renderAgents = () =>{
    if(this.props.agents == undefined){
      return;
    }
    return this.props.agents.map(agent => {
      return <AgentRow key={agent.id} name={agent.name} email={agent.email} role={agent.role}/>
    })
  }
  render() {
    return (
      <DashboardContainer>
        <SubHeader name="Agents"></SubHeader>
        <AddAgentModal/>


        <div className='d-flex flex-column-fluid'>
          <div className='container'>
            <div className='card card-custom'>

              <div className='card-header flex-wrap border-0 pt-6 pb-0'>
                <div class="card-title">
                  <h3 class="card-label font-weight-bolder text-dark">Agents
                    <span class="d-block text-muted pt-2 font-size-sm">Manage your agents</span></h3>
                </div>

                <div class="card-toolbar">
                        <button data-toggle="modal" data-target="#AddAgentModal" type="reset" class="btn btn-success mr-2">Add Agent</button>
                    </div>
              </div>

              <div className='card-body'>
                <table class="table">
                  <thead>
                    <tr>

                      <th scope="col">Agent</th>
                      <th scope="col">Role</th>
                      <th scope="col text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderAgents()}
                    {/* <AgentRow name="Ryan Pappas" email="ryan@paps.ltd" role="admin"/>
                    <AgentRow name="Catherine Fon" email="big@cath.com" role="agent"/> */}
                  </tbody>
                </table>
              </div>

            </div>


          </div>

        </div>
      </DashboardContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  agents: state.organisation.users
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Agents)