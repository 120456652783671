import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SubHeader } from './SubHeader';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import VOI from './VOI';
import ApplicantList from './ApplicantList';
import DashboardContainer from './DashboardContainer';

import { checkAuth, fetchApplication } from "../../actions";
import DocumentCollection from './DocumentCollection';
import ResetDataModal from './elements/ResetDataModal';
import BankStatementCollection from './BankStatementCollection';

export class ClientPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            field: "factfind",

        };
    }

    componentDidMount() {
        if (this.props.user.token != null) {
            this.props.fetchApplication(this.props.match.params.id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user.token != this.props.user.token || (prevProps.match.params.id != this.props.match.params.id)) {
            this.props.fetchApplication(this.props.match.params.id);
        }
    }



    renderAvatar = () => {
        const males = [
            "001-boy.svg",
            // "004-boy-1.svg",
            // "007-boy-2.svg",
            // "008-boy-3.svg",
            // "009-boy-4.svg",
            // "011-boy-5.svg",
            // "015-boy-6.svg",
            // "016-boy-7.svg",
            // "024-boy-9.svg",
            // "026-boy-10.svg",
        ];
        const females = [
            "002-girl.svg",
            // "003-girl-1.svg",
            // "005-girl-2.svg",
            // "006-girl-3.svg",
            // "007-girl-2.svg",
            // "010-girl-4.svg",
            // "012-girl-5.svg",
            // "013-girl-6.svg",
            // "014-girl-7.svg",
            // "017-girl-8.svg",
            // "018-girl-9.svg",
        ];

        var avatar = males[Math.floor(Math.random() * males.length)];
        if (this.props.form.completed == true) {
            const applicant1 = this.props.form.data.General_Details.applicant1;
            if (applicant1.title == "Mrs" || applicant1.title == "Ms") {
                var avatar = females[Math.floor(Math.random() * females.length)];
            }
        }

        return avatar;
    };

    getName = (form) => {
        console.log(form);
        var name = "";
        if (form.completed == true) {
            name = `${form.data.General_Details.applicant1.given_name} ${form.data.General_Details.applicant1.family_name}`;
        }

        if (form.completed && form.data.General_Details.applicant2 != undefined && 'given_name' in form.data.General_Details.applicant2 && form.data.General_Details.applicant2.given_name != "" && 'family_name' in form.data.General_Details.applicant2 && form.data.General_Details.applicant2.family_name != "") {
            name = name + ` & ${form.data.General_Details.applicant2.given_name} ${form.data.General_Details.applicant2.family_name}`;
        }
        return name;
    };

    getEmail = (form) => {
        var email = "";
        if (form.data && "General_Details" in form.data && "applicant1" in form.data.General_Details && 'email' in form.data.General_Details.applicant1) {
            email = form.data.General_Details.applicant1.email;
        }
        return email;
    };

    getMobile = (form) => {
        var mobile = "";
        if (form.data && "General_Details" in form.data && "applicant1" in form.data.General_Details && 'mobile' in form.data.General_Details.applicant1) {
            mobile = form.data.General_Details.applicant1.mobile;
        }
        return mobile;
    };


    renderContent = () => {
        if (this.props.application == undefined || this.props.application.id != this.props.match.params.id) {
            return (<div></div>);
        }
        if (this.state.field == "information") {
            return (
                <div className="card card-custom card-stretch py-10">
                    <div className="card-header">
                        <div class="card-title align-items-start flex-column">\
                            <h3 class="card-label font-weight-bolder text-dark">Client Information</h3>
                            <span class="text-muted font-weight-bold font-size-sm mt-1">Your clients information</span>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.field == "factfind") {
            return (
                <div className="row">

                    <div className="col-lg-4">
                        <div className="card card-custom card-stretch py-10 gutter-b">
                            <div className="card-header border-0">
                                <div class="card-title align-items-start flex-column">
                                    <h3 class="card-label font-weight-bolder text-dark">Fact Find Versions</h3>
                                    <span class="text-muted font-weight-bold font-size-sm mt-1">Your borrowers fact find versions.</span>
                                </div>
                            </div>

                            <div className="card-body pt-2 pb-0 mt-n3">
                                <div className="tab-content">
                                    <div className="table-responsive">

                                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                                            <thead>
                                                <tr class="text-left text-uppercase">
                                                    <th>Version Date</th>
                                                    <th className="text-right">Actions</th>
                                                </tr>
                                            </thead>



                                            <tbody>
                                                {this.renderVersions()}
                                            </tbody>
                                        </table>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8">
                        <ApplicantList />
                    </div>

                    <div className="col-lg-12">

                        <VOI />
                    </div>


                    <div className='col-lg-12'>
                        <BankStatementCollection />
                    </div>



                    <div className='col-lg-12'>

                        <DocumentCollection />
                    </div>

                    {/* <div className="col-lg-12">
                        <div className="card card-custom card-stretch py-10">
                            <div className="card-header">
                                <div class="card-title align-items-start flex-column">
                                    <h3 class="card-label font-weight-bolder text-dark">Loan Supporting Documents</h3>
                                    <span class="text-muted font-weight-bold font-size-sm mt-1">Here is the documents that your client has uploaded to there link.</span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            );
        }
    };

    renderVersions = () => {
        var versions = [];
        versions.push(
            <tr>
                <td >
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">Current</span>
                </td>
                <td className="text-right"><a className="btn btn-icon btn-light btn-hover-primary btn-sm" target="_blank" href={`/adminform/${this.props.application.id}`}><i class="fas fa-print"></i></a></td>
            </tr>
        );
        if ('versions' in this.props.form) {
            Object.keys(this.props.form.versions).map(key => {
                versions.push(
                    <tr>
                        <td >
                            <span class=" font-weight-bold">{this.props.form.versions[key].date}</span>
                        </td>
                        <td className="text-right"><a className="btn btn-icon btn-light btn-hover-primary btn-sm" target="_blank" href={`/versions/${this.props.application.id}/${key}/${this.props.form.id}`}><i class="fas fa-print"></i></a></td>
                    </tr>
                );
            });
        }

        return versions;
    };




    render() {
        return (
            <DashboardContainer>
                <ResetDataModal />

                <div className="container">
                    <div className="row">
                        <div className='col-lg-12'>
                            {this.renderContent()}
                        </div>
                    </div>

                    {
                        this.props.application != undefined && this.props.application.id == this.props.match.params.id ?
                            <div className='mt-5'>
                                <button data-toggle="modal" data-target="#ResetDataModal" className='btn btn-danger' onClick={() => { window.$("#ResetDataModal").modal("show");}}>Reset Data</button>
                            </div> : null
                    }

                </div>
            </DashboardContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    form: state.admin.active_application.form,
    application: state.admin.active_application.application,
    user: state.user
});

const mapDispatchToProps = {
    fetchApplication,
    checkAuth
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientPage);
