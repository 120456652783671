import axios from 'axios';
import firebase from '../components/Firebase';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER
});

instance.interceptors.request.use(
  async request => {
    const user = firebase.auth().currentUser;
    if (user) {
      const token = await user.getIdToken();
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
    // return firebase.auth().onAuthStateChanged(async (user) => {

    //   if (user) {
    //     const token = await user.getIdToken();
    //     request.headers.Authorization = `Bearer ${token}`;
    //   }
    //   return request;
    // });

  }
);

instance.interceptors.response.use(response => {
  return response;
}, error => {
  console.log(error, 'ERROR HERE', error.response);
  if (error.response?.status === 401 && error.response.data === "Admin Only") {
    //place your reentry code
    console.log("401");
    return window.location.replace("/login");

  } else if (error.response?.status === 401 && error.response.data === "Unauthorized") {
    //place your reentry code
    console.log("401");
    return window.location.replace("/client");

  } else {
    return error;
  }
});


export default instance;
