import React, { Component } from 'react'
import { connect } from 'react-redux'
import server from '../../../api/server'

export class BankStatement extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       data:null
    }
  }
  

  renderStatements = () =>{
    var statements = []



    return (
      <div className='row'>
        {statements}
      </div>

    )
  }


  render() {
    return (
      <div>
          {
            this.props.html != null ? <iframe style={{width:"100%",height:"700px"}} srcdoc={this.props.html}></iframe> : <div>Loading...</div>
          }

          <div className=''>
            <button className=' mb-2 mt-2 btn btn-success float-right' onClick={async () =>{
              var response = await server.post("/bankstatements/download",{
                id: this.props.user.token,
                document_id:this.props.id,
                applicant_id:this.props.applicant_id,
                name:this.props.name,
                application_id:this.props.bankstatements.application_id
              }, { responseType: "blob" })
              let blob = new Blob([response.data], {
                type: "application/zip"
                //               OR
                // type:"application/zip, application/octet-stream"
            });
            console.log(response.data)
            let objectUrl = URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.href = objectUrl;
            link.download = `${this.props.name}.zip`;
            link.click();


            }}>Download Statements</button>
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  bankstatements:state.admin.active_application.bankstatements,
  user: state.user,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BankStatement)