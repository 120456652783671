import React from 'react';
import {connect} from 'react-redux'
import {updateData} from '../../actions'
import CurrencyInput from 'react-currency-input-field';

import DatePicker from './form_elements/DatePicker.js'



class Address_3_year_history extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      timeoutID:null,
      errorMessage:"",
      applicant1:{
      },
      applicant2:{

      }
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    if('Address_Details' in this.props.data){
      const property = {...this.state};
      Object.keys(this.props.data['Address_Details']).map(key =>{
        if(key != "errorMessage"){
          property[key] = this.props.data['Address_Details'][key];
        }
        })
      this.setState(property)
    }
  }

  componentDidUpdate(prevProps,prevState){
    clearTimeout(prevState.timeoutID)
  }

  formChange = (e) =>{
    const update = async () =>{
      this.props.updateData({state:this.state,type:"Address_Details"})
    }
    const timeoutID = setTimeout(function() {
      update()
    },250)
    this.setState({timeoutID:timeoutID})
  }
    handleInputChange = (e,applicant,field,currency) =>{
      var value = 0;
      if(currency){value = e}else{value = e.target.value}
      if(applicant == 1){
        const property = {...this.state.applicant1};
        property[field] = value
        this.setState({applicant1:property})
      }else if(applicant == 2){
        const property = {...this.state.applicant2};
        property[field] = value
        this.setState({applicant2:property})
      }
    }

    handleSubmit = (e) =>{
      e.preventDefault()
      const checks = this.checkHistory();
      if(!checks[0]){
        window.scrollTo(0, 0)
        this.setState({errorMessage:"Please provide atleast 3 years of previous address history for Applicant 1."})
      }else if(!checks[1]){
        window.scrollTo(0, 0)
        this.setState({errorMessage:"Please provide atleast 3 years of previous address history for Applicant 2."})

      }else{
      this.props.updateData({state:this.state,type:"Address_Details"})
      this.props.changeForm("Employment_Details")
      }
    }

    handlePreviousAddressChange = (e,key,applicant,field,currency) =>{
      var value = 0;
      if(currency){value = e}else{value = e.target.value}
      if(applicant == 1){
        const property = {...this.state.applicant1};
        property[key][field] = value
        this.setState({applicant1:property})
      }else if(applicant == 2){
        const property = {...this.state.applicant2};
        property[key][field] = value
        this.setState({applicant2:property})
      }
    }

    addNewField = () =>{
      var keys = []
      Object.keys(this.state.applicant1).map(key =>{
        if(key.startsWith("previousaddress")){
          keys.push(key)
        }
      })
      var variable = `previousaddress_${keys.length+1}`;
      var applicant1 = {...this.state.applicant1}
      var applicant2 = {...this.state.applicant2}
      
      applicant1[variable] = {
        variable:keys.length+1,
        street:"",
        suburb:"",
        state:"",
        postcode:"",
        address_type:"",
        address_amount:"",
        other_text:"",
        moved_in:{
          day:"",
          month:"",
          year:""
        },
        moved_out:{
          day:"",
          month:"",
          year:""
        }
      }

      applicant2[variable] = {
        variable:keys.length+1,
        street:"",
        suburb:"",
        state:"",
        postcode:"",
        address_type:"",
        address_amount:"",
        other_text:"",
        moved_in:{
          day:"",
          month:"",
          year:""
        },
        moved_out:{
          day:"",
          month:"",
          year:""
        }
      }

      this.setState({applicant1:applicant1,applicant2:applicant2})

    }

    deleteField = (deleteKey) =>{
      var keys = []
      Object.keys(this.state.applicant1).map(key =>{
        if(key.startsWith("previousaddress")){
          keys.push(key)
        }
      })
      var applicant1 = {...this.state.applicant1}
      var applicant2 = {...this.state.applicant2}

      delete applicant1[deleteKey]
      delete applicant2[deleteKey]
      this.props.updateData({state:this.state,type:"Address_Details"})
      this.setState({applicant1:applicant1,applicant2:applicant2})

    }

    renderPreviousAddresses = () =>{
      console.log(this.state)
        var keys = []
        var previous_addresses = []
        Object.keys(this.state.applicant1).map(key =>{
          if(key.startsWith("previousaddress")){
            keys.push(key)
          }
        })
        keys.forEach((key,index) =>{

          if(index != 0){
            previous_addresses.push(
              <tr className="form-spacer"> 
  
              </tr>
            )
          }
          previous_addresses.push(
            <tr>
              <td><strong>Previous Address {index+1}</strong> <button className="ui negative small icon button" type="button" onClick={(e) =>{this.deleteField(key)}}><i class="trash alternate outline icon"></i></button></td>
              <td>
                <div class="flex-input">
                  <label  for="">Street</label>
                  <input tabIndex={`${index+1}01`} required type="text" value={this.state.applicant1[key].street || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','street')}  name="" defaultValue="" />
                </div>
              </td>
              <td>
                <div class="flex-input">
                  <label for="">Street</label>
                  <input tabIndex={`${index+1}08`} value={this.state.applicant2[key].street || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','street')} type="text" name="" defaultValue="" />
                </div>
              </td>
            </tr>
          )
        
          previous_addresses.push(
            <tr>
            <td>&nbsp;</td>
            <td>
              <div class="flex-input">
                <label for="">Suburb</label>
                <input tabIndex={`${index+1}02`} required type="text" value={this.state.applicant1[key].suburb || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','suburb')} name="" defaultValue="" />
              </div>
            </td>
            <td>
              <div class="flex-input">
                <label for="">Suburb</label>
                <input tabIndex={`${index+1}09`} type="text" value={this.state.applicant2[key].suburb || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','suburb')} name="" defaultValue="" />
              </div>
            </td>
          </tr>
          )
        
          previous_addresses.push(
            <tr>
            <td>&nbsp;</td>
            <td>
              <div class="grid4060">
                <div class="flex-input">
                  <label for="">State</label>
                  <select tabIndex={`${index+1}03`} required className="ui dropdown form-dropdown" value={this.state.applicant1[key].state || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','state')}>
                      <option defaultValue = ""></option>
                      <option defaultValue = "NSW">NSW</option>
                      <option defaultValue = "QLD">QLD</option>
                      <option defaultValue = "SA">SA</option>
                      <option defaultValue = "VIC">VIC</option>
                      <option defaultValue = "WA">WA</option>
                      <option defaultValue = "ACT">ACT</option>
                      <option defaultValue = "NT">NT</option>
                    </select>  
                </div>
                <div class="flex-input">
                  <label for="">Postcode</label>
                  <input tabIndex={`${index+1}04`} required type="text" value={this.state.applicant1[key].postcode || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','postcode')} name="" defaultValue="" />
                </div>
              </div>
            </td>
            <td>
            <div class="grid4060">
                <div class="flex-input">
                  <label for="">State</label>
                  <select tabIndex={`${index+1}10`} className="ui dropdown form-dropdown" value={this.state.applicant2[key].state || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','state')}>
                      <option defaultValue = ""></option>
                      <option defaultValue = "NSW">NSW</option>
                      <option defaultValue = "QLD">QLD</option>
                      <option defaultValue = "SA">SA</option>
                      <option defaultValue = "VIC">VIC</option>
                      <option defaultValue = "WA">WA</option>
                      <option defaultValue = "ACT">ACT</option>
                      <option defaultValue = "NT">NT</option>
                    </select> 
                  </div>
                <div class="flex-input">
                  <label for="">Postcode</label>
                  <input tabIndex={`${index+1}11`}type="text" value={this.state.applicant2[key].postcode || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','postcode')} name="" defaultValue="" />
                </div>
              </div>
            </td>
          </tr>
          )
        
          previous_addresses.push(
            <tr>
            <td>Date moved in</td>
            {
                  "date_moved_in_day" in this.state.applicant1[key] && !("moved_in" in this.state.applicant1[key]) && this.props.showButtons == "false" ?
                  <td><input required class="w20" type="text" name="" defaultValue="" value={this.state.applicant1[key].date_moved_in_day || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','date_moved_in_day')} /> / <input required value={this.state.applicant1[key].date_moved_in_month || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','date_moved_in_month')} class="w20" type="text" name="" defaultValue="" /> / <input required value={this.state.applicant1[key].date_moved_in_year || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','date_moved_in_year')} class="w20" type="text" name="" defaultValue="" /></td>:
                  <td><DatePicker tabIndex={`${index+1}05`} field="moved_in" required={true} dateChange={this.previousAddressDateChange} applicant_number={1} objectKey={key} value = {this.state.applicant1[key].moved_in}></DatePicker>
                  </td>
            }

            {
                  "date_moved_in_day" in this.state.applicant2[key] && !("moved_in" in this.state.applicant2[key]) && this.props.showButtons == "false" ?
                  <td><input class="w20" type="text" name="" defaultValue="" value={this.state.applicant2[key].date_moved_in_day || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','date_moved_in_day')} /> / <input value={this.state.applicant2[key].date_moved_in_month || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','date_moved_in_month')} class="w20" type="text" name="" defaultValue="" /> / <input value={this.state.applicant2[key].date_moved_in_year || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','date_moved_in_year')} class="w20" type="text" name="" defaultValue="" /></td>:
                  <td><DatePicker tabIndex={`${index+1}12`} field="moved_in" required={false} dateChange={this.previousAddressDateChange} applicant_number={2} objectKey={key} value = {this.state.applicant2[key].moved_in}></DatePicker>
                  </td>
            }
            {/* <td><input required class="w20" type="text" name="" defaultValue="" value={this.state.applicant1[key].date_moved_in_day || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','date_moved_in_day')} /> / <input required value={this.state.applicant1[key].date_moved_in_month || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','date_moved_in_month')} class="w20" type="text" name="" defaultValue="" /> / <input required value={this.state.applicant1[key].date_moved_in_year || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','date_moved_in_year')} class="w20" type="text" name="" defaultValue="" /></td> */}
            {/* <td><input class="w20" type="text" name="" defaultValue="" value={this.state.applicant2[key].date_moved_in_day || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','date_moved_in_day')} /> / <input value={this.state.applicant2[key].date_moved_in_month || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','date_moved_in_month')} class="w20" type="text" name="" defaultValue="" /> / <input value={this.state.applicant2[key].date_moved_in_year || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','date_moved_in_year')} class="w20" type="text" name="" defaultValue="" /></td> */}
          </tr>
          )

          previous_addresses.push(
            <tr>
            <td>Date moved out</td>
            {
                  "date_moved_out_day" in this.state.applicant1[key] && !("moved_out" in this.state.applicant1[key]) && this.props.showButtons == "false" ?
                  <td><input required class="w20" type="text" name="" defaultValue="" value={this.state.applicant1[key].date_moved_out_day || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','date_moved_out_day')} /> / <input required value={this.state.applicant1[key].date_moved_out_month || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','date_moved_out_month')} class="w20" type="text" name="" defaultValue="" /> / <input required value={this.state.applicant1[key].date_moved_out_year || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','date_moved_out_year')} class="w20" type="text" name="" defaultValue="" /></td>:
                  <td><DatePicker tabIndex={`${index+1}06`} field="moved_out" required={true} dateChange={this.previousAddressDateChange} applicant_number={1} objectKey={key} value = {this.state.applicant1[key].moved_out}></DatePicker>
                  </td>
            }

            {
                  "date_moved_out_day" in this.state.applicant2[key] && !("moved_out" in this.state.applicant2[key]) && this.props.showButtons == "false" ?
                  <td><input class="w20" type="text" name="" defaultValue="" value={this.state.applicant2[key].date_moved_out_day || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','date_moved_out_day')} /> / <input value={this.state.applicant2[key].date_moved_out_month || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','date_moved_out_month')} class="w20" type="text" name="" defaultValue="" /> / <input value={this.state.applicant2[key].date_moved_out_year || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','date_moved_out_year')} class="w20" type="text" name="" defaultValue="" /></td>:
                  <td><DatePicker tabIndex={`${index+1}13`} field="moved_out" required={false} dateChange={this.previousAddressDateChange} applicant_number={2} objectKey={key} value = {this.state.applicant2[key].moved_out}></DatePicker>
                  </td>
            }
            {/* <td><input required class="w20" type="text" name="" defaultValue="" value={this.state.applicant1[key].date_moved_out_day || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','date_moved_out_day')} /> / <input required value={this.state.applicant1[key].date_moved_out_month || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','date_moved_out_month')} class="w20" type="text" name="" defaultValue="" /> / <input required value={this.state.applicant1[key].date_moved_out_year || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','date_moved_out_year')} class="w20" type="text" name="" defaultValue="" /></td>
            <td><input class="w20" type="text" name="" defaultValue="" value={this.state.applicant2[key].date_moved_out_day || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','date_moved_out_day')} /> / <input value={this.state.applicant2[key].date_moved_out_month || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','date_moved_out_month')} class="w20" type="text" name="" defaultValue="" /> / <input value={this.state.applicant2[key].date_moved_out_year || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','date_moved_out_year')} class="w20" type="text" name="" defaultValue="" /></td> */}
          </tr>
          )
        
          previous_addresses.push(
            <tr>
            <td>Own home / renting / other</td>
            <td>
            <select tabIndex={`${index+1}07`} required defaultValue="" className="ui dropdown form-dropdown" value = {this.state.applicant1[key].address_type || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','address_type')}>
              <option defaultValue = ""></option>
              <option defaultValue = "Own">Own</option>
              <option defaultValue = "Rent">Rent</option>
              <option defaultValue = "Parents">Live with Parents</option>
              <option defaultValue = "Other">Other</option>
            </select>  
            {this.state.applicant1[key].address_type === "Rent" ? 


                <div class="flex-input">
                  <label for="">Weekly Rent:</label>
                  <CurrencyInput required prefix="$" decimalsLimit={0} value={this.state.applicant1[key].weekly_rent || ''} onValueChange={(value, name) => this.handlePreviousAddressChange(value,key,'1','weekly_rent',true)}  name="" defaultValue={0} />

                </div>
              :null}

            {this.state.applicant1[key].address_type === "Other" ? 

            <div class="flex-input">
              <label for="">Other: </label>
              <input type="text" required name="" value={this.state.applicant1[key].other_text || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'1','other_text')} defaultValue="" />
            </div>
            :null}
            </td>


            <td>
            <select defaultValue="" tabIndex={`${index+1}14`} className="ui dropdown form-dropdown" value = {this.state.applicant2[key].address_type || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','address_type')}>
              <option defaultValue = ""></option>
              <option defaultValue = "Own">Own</option>
              <option defaultValue = "Rent">Rent</option>
              <option defaultValue = "Parents">Live with Parents</option>
              <option defaultValue = "Other">Other</option>
            </select>  
            {this.state.applicant2[key].address_type === "Rent" ? 


                <div class="flex-input">
                  <label for="">Weekly Rent:</label>
                  <CurrencyInput prefix="$" decimalsLimit={0} value={this.state.applicant2[key].weekly_rent || ''} onValueChange={(value, name) => this.handlePreviousAddressChange(value,key,'2','weekly_rent',true)}  name="" defaultValue={0} />

                </div>
              :null}

            {this.state.applicant2[key].address_type === "Other" ? 

            <div class="flex-input">
              <label for="">Other: </label>
              <input type="text" name="" value={this.state.applicant2[key].other_text || ''} onChange={(e) =>this.handlePreviousAddressChange(e,key,'2','other_text')} defaultValue="" />
            </div>
            :null}
            </td>
            </tr>
          )
        })

        return previous_addresses;

    }
    
    dateChange = (value,applicant_number,type,field) =>{
      var applicant = `applicant${applicant_number}`
      const property = {...this.state[applicant]};
      if(type != null){
        property[field][type] = value;
      }else{
        property[field] = value;
      }
      this.setState({[applicant]:property})
    
    }

    previousAddressDateChange = (value,applicant_number,type,field,key) =>{
      console.log(value,applicant_number,type,field,key)
      var applicant = `applicant${applicant_number}`
      const property = {...this.state[applicant]}

      if(type != null){
        property[key][field][type] = value;
      }else{
        property[key][field] = value;
      }

      this.setState({[applicant]:property})
    }


    checkHistory = () =>{
      // Check Applicant 1 AND Applicant 2;
      var app1_flag = false;
      var app2_flag = true;

      var app2_required = false;
      if(this.props.data.General_Details.applicant2.given_name != undefined){
        app2_required = true;
        app2_flag = false;
      }
      var targetYear = new Date().getFullYear() - 2;
      var previous_addresses = []

      Object.keys(this.state.applicant1).forEach(key =>{
        if(key.startsWith('previousaddress')){
          previous_addresses.push(key);
        }
      })


      if(this.state.applicant1.moved_in != undefined && this.state.applicant1.moved_in.year <= targetYear ){
        app1_flag = true;
      }

      if(app2_required && this.state.applicant2.moved_in != undefined && this.state.applicant2.moved_in.year <= targetYear ){
        app2_flag = true;
      }



      
      previous_addresses.forEach(prev_address =>{
        if(this.state.applicant1[prev_address].moved_in != undefined && this.state.applicant1[prev_address].moved_in.year != "" && this.state.applicant1[prev_address].moved_in.year <= targetYear){
          app1_flag = true;
        }

        if(app2_required && this.state.applicant2[prev_address].moved_in != undefined && this.state.applicant2[prev_address].moved_in.year != "" && this.state.applicant2[prev_address].moved_in.year <= targetYear){
          console.log(this.state.applicant2[prev_address].moved_in.year)
          app2_flag = true;
        }

      })

      return [app1_flag,app2_flag]

      
    }


    renderButtons = () =>{
      if(this.props.showButtons == 'false'){
        return(null)
      }else{
        return(
          <div className="">
          <button className="ui button primary " onClick={(e) =>{this.props.changeForm("General_Details")}}>Previous Page</button>
          <button tabIndex="9999" className="ui button primary " type="submit" style={{float:"right"}}>Next Page</button>
        </div>
        )
      }
    }

    renderErrors = () =>{
      if(this.state.errorMessage){
          return(
              <div class="ui red message">{this.state.errorMessage}</div>
          )
      }else{

      }
    }


    render(){
        return(
            <div>
              {this.renderErrors()}
              <form onSubmit = {(e) =>{this.handleSubmit(e)}} onChange = {this.formChange}>
        <table class="table">
          <thead>
            <tr>
              <th colspan="3">Current Address</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td><b>Applicant 1</b></td>
              <td><b>Applicant 2</b></td>
            </tr>

            <tr>
              <td>Current address</td>
              <td>
                <div class="flex-input">
                  <label for="">Street</label>
                  <input tabindex="1"  required type="text" value={this.state.applicant1.street || ''} onChange={(e) =>this.handleInputChange(e,'1','street')} name="" defaultValue="" />
                </div>
              </td>
              <td>
                <div class="flex-input">
                  <label for="">Street</label>
                  <input  value={this.state.applicant2.street || ''} onChange={(e) =>this.handleInputChange(e,'2','street')} type="text" name="" defaultValue="" />
                </div>
              </td>
            </tr>

            <tr>
              <td>&nbsp;</td>
              <td>
                <div class="flex-input">
                  <label for="">Suburb</label>
                  <input tabindex="2"  required type="text" value={this.state.applicant1.suburb || ''} onChange={(e) =>this.handleInputChange(e,'1','suburb')} name="" defaultValue="" />
                </div>
              </td>
              <td>
                <div class="flex-input">
                  <label for="">Suburb</label>
                  <input type="text" value={this.state.applicant2.suburb || ''} onChange={(e) =>this.handleInputChange(e,'2','suburb')} name="" defaultValue="" />
                </div>
              </td>
            </tr>

            <tr>
              <td>&nbsp;</td>
              <td>
                <div class="grid4060">
                  <div class="flex-input">
                    <label for="">State</label>
                    <select required defaultValue="" className="ui dropdown form-dropdown" value = {this.state.applicant1.state || ''} onChange={(e) =>this.handleInputChange(e,'1','state')}>
                        <option defaultValue = ""></option>
                        <option defaultValue = "NSW">NSW</option>
                        <option defaultValue = "QLD">QLD</option>
                        <option defaultValue = "SA">SA</option>
                        <option defaultValue = "VIC">VIC</option>
                        <option defaultValue = "WA">WA</option>
                        <option defaultValue = "ACT">ACT</option>
                        <option defaultValue = "NT">NT</option>
                      </select>                  
                </div>
                  <div class="flex-input">
                    <label for="">Postcode</label>
                    <input tabindex="3"  required type="text" value={this.state.applicant1.postcode || ''} onChange={(e) =>this.handleInputChange(e,'1','postcode')} name="" defaultValue="" />
                  </div>
                </div>
              </td>
              <td>
              <div class="grid4060">
                  <div class="flex-input">
                    <label for="">State</label>
                    <select className="ui dropdown form-dropdown" value = {this.state.applicant2.state || ''} onChange={(e) =>this.handleInputChange(e,'2','state')}>
                        <option defaultValue = ""></option>
                        <option defaultValue = "NSW">NSW</option>
                        <option defaultValue = "QLD">QLD</option>
                        <option defaultValue = "SA">SA</option>
                        <option defaultValue = "VIC">VIC</option>
                        <option defaultValue = "WA">WA</option>
                        <option defaultValue = "ACT">ACT</option>
                        <option defaultValue = "NT">NT</option>
                      </select> 
                      </div>
                  <div class="flex-input">
                    <label for="">Postcode</label>
                    <input tabindex="4"  type="text" value={this.state.applicant2.postcode || ''} onChange={(e) =>this.handleInputChange(e,'2','postcode')} name="" defaultValue="" />
                  </div>
                </div>
              </td>
            </tr>
            

            <tr>
              <td>Date moved in</td>

                {
                  "date_moved_in_day" in this.state.applicant1 && !("moved_in" in this.state.applicant1) && this.props.showButtons == "false" ?
                  <td><input required class="w20" type="text" name="" defaultValue="" value={this.state.applicant1.date_moved_in_day || ''} onChange={(e) =>this.handleInputChange(e,'1','date_moved_in_day')} /> / <input required value={this.state.applicant1.date_moved_in_month || ''} onChange={(e) =>this.handleInputChange(e,'1','date_moved_in_month')} class="w20" type="text" name="" defaultValue="" /> / <input required value={this.state.applicant1.date_moved_in_year || ''} onChange={(e) =>this.handleInputChange(e,'1','date_moved_in_year')} class="w20" type="text" name="" defaultValue="" /></td>:
                  <td><DatePicker field="moved_in" required={true} dateChange={this.dateChange} applicant_number={1} value = {this.state.applicant1.moved_in}></DatePicker>
                  </td>
                }

                {
                  "date_moved_in_day" in this.state.applicant2 && !("moved_in" in this.state.applicant2) && this.props.showButtons == "false" ?
                  <td><input class="w20" type="text" name="" defaultValue="" value={this.state.applicant2.date_moved_in_day || ''} onChange={(e) =>this.handleInputChange(e,'2','date_moved_in_day')} /> / <input value={this.state.applicant2.date_moved_in_month || ''} onChange={(e) =>this.handleInputChange(e,'2','date_moved_in_month')} class="w20" type="text" name="" defaultValue="" /> / <input value={this.state.applicant2.date_moved_in_year || ''} onChange={(e) =>this.handleInputChange(e,'2','date_moved_in_year')} class="w20" type="text" name="" defaultValue="" /></td>:
                  <td><DatePicker field="moved_in" required={false} dateChange={this.dateChange} applicant_number={2} value = {this.state.applicant2.moved_in}></DatePicker>
                  </td>
                }

              {/* <td><input required class="w20" type="text" name="" defaultValue="" value={this.state.applicant1.date_moved_in_day || ''} onChange={(e) =>this.handleInputChange(e,'1','date_moved_in_day')} /> / <input required value={this.state.applicant1.date_moved_in_month || ''} onChange={(e) =>this.handleInputChange(e,'1','date_moved_in_month')} class="w20" type="text" name="" defaultValue="" /> / <input required value={this.state.applicant1.date_moved_in_year || ''} onChange={(e) =>this.handleInputChange(e,'1','date_moved_in_year')} class="w20" type="text" name="" defaultValue="" /></td> */}
              {/* <td><input class="w20" type="text" name="" defaultValue="" value={this.state.applicant2.date_moved_in_day || ''} onChange={(e) =>this.handleInputChange(e,'2','date_moved_in_day')} /> / <input value={this.state.applicant2.date_moved_in_month || ''} onChange={(e) =>this.handleInputChange(e,'2','date_moved_in_month')} class="w20" type="text" name="" defaultValue="" /> / <input value={this.state.applicant2.date_moved_in_year || ''} onChange={(e) =>this.handleInputChange(e,'2','date_moved_in_year')} class="w20" type="text" name="" defaultValue="" /></td> */}
            </tr>

            <tr>
              <td>Own home / renting / other</td>
              <td>
              <select required defaultValue="" className="ui dropdown form-dropdown" value = {this.state.applicant1.address_type || ''} onChange={(e) =>this.handleInputChange(e,'1','address_type')}>
                <option defaultValue = ""></option>
                <option defaultValue = "Own">Own</option>
                <option defaultValue = "Rent">Rent</option>
                <option defaultValue = "Parents">Live with Parents</option>
                <option defaultValue = "Other">Other</option>
              </select>  
              {this.state.applicant1.address_type === "Rent" ? 


                  <div class="flex-input">
                    <label for="">Weekly Rent:</label>
                    <CurrencyInput prefix="$" required decimalsLimit={0} value={this.state.applicant1.weekly_rent || ''} onValueChange={(value, name) => this.handleInputChange(value,'1','weekly_rent',true)}  name="" defaultValue={0} />

                  </div>
                :null}

              {this.state.applicant1.address_type === "Other" ? 

              <div class="flex-input">
                <label for="">Other: </label>
                <input required type="text" name="" value={this.state.applicant1.other_text || ''} onChange={(e) =>this.handleInputChange(e,'1','other_text')} defaultValue="" />
              </div>
              :null}
              </td>


              <td>
              <select defaultValue="" className="ui dropdown form-dropdown" value = {this.state.applicant2.address_type || ''} onChange={(e) =>this.handleInputChange(e,'2','address_type')}>
                <option defaultValue = ""></option>
                <option defaultValue = "Own">Own</option>
                <option defaultValue = "Rent">Rent</option>
                <option defaultValue = "Parents">Live with Parents</option>
                <option defaultValue = "Other">Other</option>
              </select>  
              {this.state.applicant2.address_type === "Rent" ? 


                  <div class="flex-input">
                    <label for="">Weekly Rent:</label>
                    <CurrencyInput prefix="$" decimalsLimit={0} value={this.state.applicant2.weekly_rent || ''} onValueChange={(value, name) => this.handleInputChange(value,'2','weekly_rent',true)}  name="" defaultValue={0} />

                  </div>
                :null}

              {this.state.applicant2.address_type === "Other" ? 

              <div class="flex-input">
                <label for="">Other: </label>
                <input required type="text" name="" value={this.state.applicant2.other_text || ''} onChange={(e) =>this.handleInputChange(e,'2','other_text')} defaultValue="" />
              </div>
              :null}
              </td>
            </tr>


            


            


            {/* Previous Address */}
            <tr className="form-spacer"> 

            </tr>
            <tr>
              <td><strong>Mailing address (if different)</strong></td>
              <td>
                <div class="flex-input">
                  <label for="">Street</label>
                  <input tabindex="5"  type="text" value={this.state.applicant1.mailing_street || ''} onChange={(e) =>this.handleInputChange(e,'1','mailing_street')} name="" defaultValue="" />
                </div>
              </td>
              <td>
                <div class="flex-input">
                  <label for="">Street</label>
                  <input value={this.state.applicant2.mailing_street || ''} onChange={(e) =>this.handleInputChange(e,'2','mailing_street')} type="text" name="" defaultValue="" />
                </div>
              </td>
            </tr>

            <tr>
              <td>&nbsp;</td>
              <td>
                <div class="flex-input">
                  <label for="">Suburb</label>
                  <input tabindex="6"  type="text" value={this.state.applicant1.mailing_suburb || ''} onChange={(e) =>this.handleInputChange(e,'1','mailing_suburb')} name="" defaultValue="" />
                </div>
              </td>
              <td>
                <div class="flex-input">
                  <label for="">Suburb</label>
                  <input type="text" value={this.state.applicant2.mailing_suburb || ''} onChange={(e) =>this.handleInputChange(e,'2','mailing_suburb')} name="" defaultValue="" />
                </div>
              </td>
            </tr>

            <tr>
              <td>&nbsp;</td>
              <td>
                <div class="grid4060">
                  <div class="flex-input">
                    <label for="">State</label>
                    <select defaultValue="" className="ui dropdown form-dropdown" value = {this.state.applicant1.mailing_state || ''} onChange={(e) =>this.handleInputChange(e,'1','mailing_state')}>
                        <option defaultValue = ""></option>
                        <option defaultValue = "NSW">NSW</option>
                        <option defaultValue = "QLD">QLD</option>
                        <option defaultValue = "SA">SA</option>
                        <option defaultValue = "VIC">VIC</option>
                        <option defaultValue = "WA">WA</option>
                        <option defaultValue = "ACT">ACT</option>
                        <option defaultValue = "NT">NT</option>
                      </select>                  
                </div>
                  <div class="flex-input">
                    <label for="">Postcode</label>
                    <input tabindex="7"  type="text" value={this.state.applicant1.mailing_postcode || ''} onChange={(e) =>this.handleInputChange(e,'1','mailing_postcode')} name="" defaultValue="" />
                  </div>
                </div>
              </td>
              <td>
              <div class="grid4060">
                  <div class="flex-input">
                    <label for="">State</label>
                    <select className="ui dropdown form-dropdown" value = {this.state.applicant2.mailing_state || ''} onChange={(e) =>this.handleInputChange(e,'2','mailing_state')}>
                        <option defaultValue = ""></option>
                        <option defaultValue = "NSW">NSW</option>
                        <option defaultValue = "QLD">QLD</option>
                        <option defaultValue = "SA">SA</option>
                        <option defaultValue = "VIC">VIC</option>
                        <option defaultValue = "WA">WA</option>
                        <option defaultValue = "ACT">ACT</option>
                        <option defaultValue = "NT">NT</option>
                      </select> 
                      </div>
                  <div class="flex-input">
                    <label for="">Postcode</label>
                    <input type="text" value={this.state.applicant2.mailing_postcode || ''} onChange={(e) =>this.handleInputChange(e,'2','mailing_postcode')} name="" defaultValue="" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="table" onMouseEnter={console.log('Enter')}>
          <thead>
            <tr>
              <th colspan="3">Previous Address - 3 Year History</th>
            </tr>
          </thead>
          <tbody>
          {this.renderPreviousAddresses()}
          <button className="ui button add-field" type="button" onClick={(e) =>{this.addNewField()}}><i class="plus circle icon"></i>Add Address</button>
          </tbody>
        </table>
            {this.renderButtons()}
              </form>
            </div>
        )

    }
}

const mapStateToProps = (state) =>{
  return {data:state.data}
}

export default connect(mapStateToProps,{updateData})(Address_3_year_history)