import React, { Component } from 'react';
import { connect } from 'react-redux';

import server from '../../../api/server';
import BankStatement from './BankStatement';
import { Document, Page } from 'react-pdf';

import BankStatementPDF from './BankStatementPDF';

export class BankStatements extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null
        };
    }

    componentDidMount() {
        if (this.state.data == null) {

            this.fetchData();
        }
    }

    fetchData = async () => {
        if (this.props.bankstatements == null) {
            return;
        }
        var response = await server.post("/bankstatements/data", { id: this.props.user.token, application_id: this.props.bankstatements.application_id, applicant_id: this.props.applicant_id });
        if (response.data && response.data.success == true) {
            this.setState({
                data: response.data.htmlArray
            });
        }
    };

    renderFiles = () => {
        var files = [];
        // if (this.props.documents.applicants[this.props.applicant_id].requested_documents == undefined) {
        //     return;
        // }


        if (this.state.data != null) {
            // Bank Statements.
            var statements = this.props.bankstatements.applicants[this.props.applicant_id].statements;

            Object.keys(statements).forEach((key, index) => {
                var data = null;

                if (this.state.data != null) {
                    var statementData = this.state.data.find(x => x.fileID == key);
                    if (statementData != undefined) {
                        data = statementData.html;
                    }
                }
                files.push(

                    <div class="card">
                        <div class="card-header">
                            <div class="card-title collapsed" data-toggle="collapse" data-target={`#${this.props.applicant_id}_bankstatements_${index}`}>
                                {statements[key].name} <span class="label label-primary label-inline font-weight-bolder ml-2">Bank Statement</span>
                            </div>
                        </div>
                        <div id={`${this.props.applicant_id}_bankstatements_${index}`} class="collapse" data-parent={`#${this.props.applicant_id}_bankstatements_${index}`}>
                            <div class="card-body">
                                <BankStatement name={statements[key].name} statements={statements[key].statements} html={data} id={key} applicant_id={this.props.applicant_id} />
                            </div>
                        </div>
                    </div>
                );
            });
        } else {
            return (<span>This client is yet to provide any of their bank statements.</span>);
        }








        return files;
    };


    renderButton = () => {
        if (this.props.documents.applicants[this.props.applicant_id].requested_documents == undefined) {
            return;
        } else {

        }
    };

    renderPDFs = () => {
        var returnStatements = [];
        var statements = this.props.bankstatements.applicants[this.props.applicant_id].statements;
        if (!statements) return;
        Object.keys(statements).forEach((key, index) => {

            var files = [];
            // console.log(statements[key].statements, 'STATEMENTS');
            statements[key].statements.forEach(statement => {
                if (statement.type == ".pdf") {
                    returnStatements.push(
                        <BankStatementPDF applicant_id={this.props.applicant_id} uri={statement.URI}></BankStatementPDF>
                    );
                }


            });

            console.log(files, 'STATEMENTS');


            // returnStatements.push(
            //     <div className='mb-4'>
            //         <h4>{statements[key].name}</h4>
            //         <div className='grid grid-cols-4'>
            //             {files}

            //         </div>
            //     </div>
            // );
            // if (statements[key].type == ".pdf") {
            //     returnStatements.push(<BankStatementPDF uri={statements[key].uri}></BankStatementPDF>);
            // }
        });


        return <div className='grid grid-cols-4 gap-y-5 gap-x-2'>
            {returnStatements}
        </div>;
    };


    render() {
        if (this.props.active_application && this.props.active_application.application == undefined && this.props.active_application.application.applicants[this.props.applicant_id] == undefined) {
            return null;
        } else {
            return (
                // null
                <div>
                    <div class="h2">{`${this.props.active_application.application.applicants[this.props.applicant_id].name}`} <small class="text-muted">Bank Statements</small></div>

                    {(this.props.bankstatements.applicants[this.props.applicant_id].statements == undefined) ?
                        <div>
                            <div>This applicant is yet to upload any bank statements</div>

                        </div>
                        :
                        <div>
                            <div class="accordion accordion-toggle-arrow" id="accordionExample1">
                                {/* {this.renderFiles()} */}
                            </div>

                            <div class="separator separator-solid mt-5 mb-5" />
                        </div>
                    }


                    {/* <div className='grid grid-cols-4'> */}
                    {this.renderPDFs()}
                    {/* </div> */}


                </div>


            );
        }

    }
}

const mapStateToProps = (state) => ({
    documents: state.admin.active_application.documents,
    bankstatements: state.admin.active_application.bankstatements,
    user: state.user,
    active_application: state.admin.active_application,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BankStatements);