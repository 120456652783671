import React, { Component } from 'react'
import { connect } from 'react-redux'

import {Helmet} from "react-helmet"

export class NotFound extends Component {
    renderHelmet = () =>{
        return(
            <Helmet defer={false}>

            <meta charset="utf-8" />
            <title>Not Found | LoanGateway</title>
            <meta name="description" content="Page with empty content" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <link rel="canonical" href="https://keenthemes.com/metronic" />

            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"
            />

            <link
                href={
                    process.env.PUBLIC_URL +
                    `metronic/assets/plugins/custom/fullcalendar/fullcalendar.bundle.css`
                }
                rel="stylesheet"
                type="text/css"
            />

            <link
                href={
                    process.env.PUBLIC_URL +
                    "metronic/assets/plugins/global/plugins.bundle.css"
                }
                rel="stylesheet"
                type="text/css"
            />
            <link
                href={
                    process.env.PUBLIC_URL +
                    "metronic/assets/plugins/custom/prismjs/prismjs.bundle.css"
                }
                rel="stylesheet"
                type="text/css"
            />

            <link
                href={
                    process.env.PUBLIC_URL +
                    "metronic/assets/css/themes/layout/header/base/light.css"
                }
                rel="stylesheet"
                type="text/css"
            />
            <link
                href={
                    process.env.PUBLIC_URL +
                    "metronic/assets/css/themes/layout/header/menu/light.css"
                }
                rel="stylesheet"
                type="text/css"
            />
            <link
                href={
                    process.env.PUBLIC_URL +
                    "metronic/assets/css/themes/layout/brand/dark.css"
                }
                rel="stylesheet"
                type="text/css"
            />
            <link
                href={
                    process.env.PUBLIC_URL +
                    "metronic/assets/css/themes/layout/aside/dark.css"
                }
                rel="stylesheet"
                type="text/css"
            />
                                        <link
                href={
                    process.env.PUBLIC_URL + "metronic/assets/css/style.bundle.css"
                }
                rel="stylesheet"
                type="text/css"
            />

            <link rel="shortcut icon" href="assets/media/logos/favicon.ico" />
            
        </Helmet>
        )
    }

    render() {
        return (
            <div class="d-flex flex-column flex-root">
                {this.renderHelmet()}

			<div class="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center not-found-page">

				<div class="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
					<h1 class="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12" style={{fontSize:"14rem"}}>Oops!</h1>
					<p class="font-weight-boldest display-4">Something went wrong here.</p>
					<p class="font-size-h3">We're working on it and we'll get it fixed as soon possible.</p>
				</div>

			</div>

		</div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFound)
