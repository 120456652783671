import React, { Component } from 'react';
import { connect } from 'react-redux';
import ClientEntry from './ClientEntry';
import { SubHeader } from './SubHeader';
import firebase from '../Firebase.js';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import server from '../../api/server';
import history from '../../history';
import ApplicationAddModal from './ApplicationAddModal';
import DashboardContainer from './DashboardContainer';

import { fetchApplications } from "../../actions";

import _, { map } from 'underscore';
import $ from 'jquery';


// import {fetchAdminForms} from '../../actions'



export class Applications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            all_applications: [],
            current_applications: [],
            search: false,
            sort: null,
            sort_order: null
        };
    }

    getMostRecentDate = (app) => {
        const lastUpdated = new Date(app.last_updated || 0);
        const created = new Date(app.created || 0);
        return Math.max(lastUpdated.getTime(), created.getTime());
    };

    componentDidMount() {
        // this.checkAuth()

        var current_applications = this.props.applications;

        // Sort by the most recent date (either created or last_updated)
        var sorted = _.sortBy(current_applications, app => -this.getMostRecentDate(app));
        console.log(sorted, 'SORTED');

        this.setState({
            all_applications: this.props.applications,
            current_applications: sorted,
            sort: "recent_date",
            sort_order: "-"
        });



        // this.fetchForms()
    }


    checkAuth = () => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                const token = await user.getIdToken();
                this.setState({ loggedin: true });
                this.props.fetchApplications(token);
                // User is signed in.
            } else {
                history.push("/login");
                // No user is signed in.
            }
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.search == false && this.state.current_applications.length != this.props.applications.length) {

            var current_applications = this.props.applications;

            // Sort by the most recent date (either created or last_updated)
            var sorted = _.sortBy(current_applications, app => -this.getMostRecentDate(app));
            console.log(sorted, 'SORTED');

            this.setState({
                all_applications: this.props.applications,
                current_applications: sorted,
                sort: "recent_date",
                sort_order: "-"
            });

        }
    }

    refreshApplications = async () => {
        this.checkAuth();
        // const response = await server.get("/forms")
        // if(response.data){
        //     this.setState({forms:response.data})
        // }
    };

    fetchForms = async () => {
        // firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(async (idToken) => {
        //     const response = await server.post("/forms",{id:idToken})
        //     if(response.data !== false){
        //         this.setState({all_clients:response.data,current_clients:response.data})
        //     }else{
        //         // history.push("/login")
        //     }
        // }).catch(function(error) {
        //     // history.push("/login")
        // });
        // // const response = await server.get("/forms")
        // // if(response.data){
        // //     this.setState({forms:response.data})
        // // }
    };

    onInputChange = (e) => {
        const search_term = e.target.value.toLowerCase();
        var search_results = [];
        if (search_term != "") {
            this.props.applications.forEach(application => {
                const application_name = application.name;
                var search_string = `${application_name.toLowerCase()}`;
                Object.keys(application.applicants).forEach(key => {
                    var string = "";
                    if (application.applicants[key].name != undefined) string = string + `${application.applicants[key].name.toLowerCase()}`;
                    if (application.applicants[key].mobile != undefined) string = string + `${application.applicants[key].mobile.toLowerCase()}`;
                    if (application.applicants[key].email != undefined) string = string + `${application.applicants[key].email.toLowerCase()}`;
                    search_string = search_string + ` ${string}`;
                });
                if (search_string.includes(search_term)) {
                    search_results.push(application);
                }
            });
            // this.props.setResults(search_results,true);
            this.setState({ current_applications: search_results, search: true });
        } else {
            // this.props.setResults(this.props.forms,false);
            this.setState({ current_applications: this.props.applications, all_applications: this.props.applications, search: false });
        }

        this.setState({ search_term: e.target.value });


    };

    renderApplications = () => {
        var applications = [];
        this.state.current_applications.forEach(application => {
            var name = application.name;
            var email = application.email;
            applications.push(
                <ClientEntry
                    name={this.getName(application) || name}
                    fact_find_completed={application.completed}
                    email={this.getEmail(application) || email}
                    client_data={application}
                ></ClientEntry>
            );
        });
        return applications;
    };

    sort = (type) => {
        var order = this.state.sort_order === "+" ? "-" : "+";
        this.setState({ sort: type, sort_order: order });

        var sorted;
        if (type === "recent_date" || type === "created") {
            sorted = _.sortBy(this.state.current_applications, app => -this.getMostRecentDate(app));
        } else {
            sorted = _.sortBy(this.state.current_applications, type);
        }

        if (order === "+") {
            sorted = sorted.reverse();
        }
        this.setState({ current_applications: sorted });

    };

    getName = (form) => {
        var name = "";
        if (form.completed == true) {
            name = `${form.data.General_Details.applicant1.given_name} ${form.data.General_Details.applicant1.family_name}`;
        }

        if (form.completed && form.data.General_Details.applicant2 != undefined && 'given_name' in form.data.General_Details.applicant2 && form.data.General_Details.applicant2.given_name != "" && 'family_name' in form.data.General_Details.applicant2 && form.data.General_Details.applicant2.family_name != "") {
            name = name + ` & ${form.data.General_Details.applicant2.given_name} ${form.data.General_Details.applicant2.family_name}`;
        }
        return name;
    };

    getEmail = (form) => {
        var email = "";
        if (form.data && "General_Details" in form.data && "applicant1" in form.data.General_Details && 'email' in form.data.General_Details.applicant1) {
            email = form.data.General_Details.applicant1.email;
        }
        return email;
    };

    render() {
        return (
            <DashboardContainer>
                <SubHeader name="Applications"></SubHeader>
                <ApplicationAddModal></ApplicationAddModal>
                <div className="d-flex flex-column-fluid">

                    <div className="container">
                        <div className="card card-custom card-stretch gutter-b">
                            <div class="card-header border-0 py-5">
                                <h3 class="card-title align-items-start flex-column">
                                    <span class="card-label font-weight-bolder text-dark">Applications</span>
                                    <span class="text-muted mt-3 font-weight-bold font-size-sm">Your Applications</span>
                                </h3>
                                <div class="card-toolbar">
                                    <div class="input-icon mr-3">
                                        <input type="text" onChange={(e) => { this.onInputChange(e); }} class="form-control" placeholder="Search..." id="kt_datatable_search_query" />
                                        <span>
                                            <i class="flaticon2-search-1 text-muted"></i>
                                        </span>
                                    </div>
                                    <button onClick={() => { window.$("#ApplicationAddModal").modal("show"); }} data-toggle="modal" data-target="#ApplicationAddModal" class="btn btn-info font-weight-bolder font-size-sm mr-3">New Application</button>
                                    <button title="Refresh Clients" onClick={(e) => { this.refreshApplications(); }} data-placement="left" data-original-title="Refresh Applications" data-toggle="tooltip" class="btn btn-icon btn-success btn-hover-primary"><i class="ki ki-reload"></i></button>
                                </div>
                            </div>


                            <div className="card-body pt-0 pb-3">
                                <div className="tab-content">
                                    <div className="table-responsive">

                                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                                            <thead>
                                                <tr class="text-left text-uppercase">
                                                    <th style={{ cursor: "pointer" }} onClick={() => { this.sort("name"); }}>Name</th>
                                                    <th style={{ cursor: "pointer" }} onClick={() => { this.sort("recent_date"); }}>Last Update</th>

                                                    <th style={{ cursor: "pointer" }} onClick={() => { this.sort("created"); }}>Date Created</th>
                                                    <th className="text-right">Identification</th>
                                                    <th className="text-right">Documents</th>
                                                    <th className="text-right">Fact Find</th>
                                                </tr>
                                            </thead>



                                            <tbody>
                                                {this.renderApplications()}
                                            </tbody>
                                        </table>


                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>

                </div>
            </DashboardContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    applications: state.admin.applications
});

const mapDispatchToProps = {
    // fetchAdminForms
    fetchApplications
};

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
