import React, { Component } from 'react'
import { connect } from 'react-redux'

import server from "../../api/server"

import {updateVOIData} from "../../actions"

export class VOIBox extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             mobile:"",
             disabled:false
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    startVOI =async  (applicant_id,name,identification_id) =>{
        this.setState({disabled:true})
        const response = await server.post("/startvoi",{id:this.props.applicant.id,mobile:this.state.mobile,name:this.props.applicant.name,identification_id:this.props.VOI.id,application_id:this.props.id});
        this.props.updateVOIData(response.data);
        this.setState({disabled:false})
    }
    
    render() {
        return (
            <div className="dashboard-module">
            <h2>{this.props.applicant.name}</h2>
            <p style={{"marginTop":"7px",marginBottom:"14px"}}>Please enter your mobile number below. Once you hit 'Start' you will receive a SMS to your mobile with a unique link to finalise the process. </p>
            
            <form className="ui form" onSubmit={(e) =>{e.preventDefault()}}>
                <div className="field">
                    <label>Mobile Number</label>
                    <input value={this.state.mobile} onChange={(e) =>{this.setState({mobile:e.target.value})}} required  type="text" />
                </div>

                <button className={`ui button dashboard-module-button ${this.state.disabled == true ? "disabled":null}`} type="button" onClick={(e) =>{this.startVOI()}}>Start Verification of Identity</button>
            </form>
            
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    VOI:state.VOI,
    id:state.id
})

const mapDispatchToProps = {
    updateVOIData
}

export default connect(mapStateToProps, mapDispatchToProps)(VOIBox)
