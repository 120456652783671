import React, { Component } from 'react'
import { connect } from 'react-redux'
import DashboardContainer from './DashboardContainer'
import SubHeader from './SubHeader'
import BillingRow from './elements/BillingRow'
import server from '../../api/server';


export class Billing extends Component {

    constructor(props) {
      super(props)
    
      this.state = {
         total:0,
         invoice:null
      }
    } 

    componentDidMount() {
        this.getInvoice()
        
    }

    getInvoice = async () =>{
        const response = await server.post("/admin/invoice",{
            organisation_id:this.props.organisation.id,
            id:this.props.user.token

        })
        if(response.data && response.data.success){
            this.setState({
                invoice:response.data.invoice,
                total:0
            })
        }
    }
    renderInvoice = () =>{

        if(this.state.invoice == null){
            return;
        }

        var invoice = this.state.invoice;

        var rows = []
        var fact_find_price = 1.99
        var file_upload_price = 0.10;
        var identification_price = 7.99;
        var bank_statement_price = 6.99;
        var seat_price = 15;




        var fact_finds = invoice.fact_finds;
        var file_uploads = invoice.file_uploads;
        var identifications = invoice.identifications;
        var bank_statements = invoice.bank_statements;
        var seats = invoice.seats;

        var total = 0
        total = (fact_find_price*fact_finds) + (file_upload_price*file_uploads) + (identification_price*identifications) + (bank_statement_price*bank_statements) + (seat_price*seats);
        total = total.toFixed(2)
        console.log(total,'TOTAL')
        if(total != this.state.total){
            this.setState({total:total})
        }

        rows.push(<BillingRow label = "Fact Finds" qty={fact_finds} price={fact_find_price}/>)
        rows.push(<BillingRow label = "File Uploads" qty={file_uploads} price={file_upload_price}/>)
        rows.push(<BillingRow label = "Identifications" qty={identifications} price={identification_price}/>)
        rows.push(<BillingRow label = "Bank Statements" qty={bank_statements} price={bank_statement_price}/>)
        rows.push(<BillingRow label = "Agents" qty={seats} price={seat_price}/>)




        return rows;

    }
  render() {
    return (
      <DashboardContainer>
        <SubHeader name="Billing"></SubHeader>

        <div className='container'>
            <div className='flex-row-fluid ml-lg-8'>
            <div className='card card-custom gutter-b'>

                <div className='card-header border-0 py-5'>
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark">Current Month</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-sm">This month's billing breakdown</span>
                    </h3>

                </div>
                <div className='card-body pt-0'>

                    <div className='row justify-content-center'>
                    <div className='table-responsive'>
                            <table className='table'>

                                <thead>
                                    <tr>
                                        <th className='pl-0 font-weight-bold text-muted text-uppercase'>Items</th>
                                        <th className='text-right pr-0 font-weight-bold text-muted text-uppercase'>Qty</th>
                                        <th className='text-right pr-0 font-weight-bold text-muted text-uppercase'>Unit Price</th>
                                        <th className='text-right pr-0 font-weight-bold text-muted text-uppercase'>Amount</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.renderInvoice()}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className='text-primary font-size-h3 font-weight-boldest text-right'>${this.state.total}</td>
                                    </tr>
                                </tbody>

                            </table>
                    </div>
                    </div>

                    <div>

                    </div>

                </div>
            </div>
            </div>

        </div>
      </DashboardContainer>
    )
  }
}

const mapStateToProps = (state) => ({
    organisation: state.organisation,
    user: state.user
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Billing)