import React, { Component } from 'react'
import { connect } from 'react-redux'
import DashboardContainer from './DashboardContainer'
import SubHeader from './SubHeader'

export class User extends Component {


    constructor(props) {
      super(props)
    
      this.state = {
         menu:"profile"
      }
    }
    renderContent = () =>{

        if(this.state.menu == 'profile'){
            return(
                <div className='card card-custom'>
                <div class="card-header py-3">
                    <div class="card-title align-items-start flex-column">
                        <h3 class="card-label font-weight-bolder text-dark">Profile Overview</h3>
                        <span class="text-muted font-weight-bold font-size-sm mt-1">Change your account settings</span>
                    </div>
                    <div class="card-toolbar">
                        <button type="reset" class="btn btn-success mr-2">Save Changes</button>
                        <button type="reset" class="btn btn-secondary">Cancel</button>
                    </div>
                </div>

                <form className='form'>
                    <div className='card-body'>
                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Name</label>
                            <div class="col-lg-9 col-xl-6">
                                <input class="form-control form-control-lg form-control-solid" type="text" value={this.props.user.name} />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Email Address</label>
                            <div class="col-lg-9 col-xl-6">
                                <div class="input-group input-group-lg input-group-solid">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="la la-at"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control form-control-lg form-control-solid" value={this.props.user.email} placeholder="Email" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            )
        }else if(this.state.menu == 'password'){
            return(
                <div className='card card-custom'>
                <div class="card-header py-3">
                    <div class="card-title align-items-start flex-column">
                        <h3 class="card-label font-weight-bolder text-dark">Change Password</h3>
                        <span class="text-muted font-weight-bold font-size-sm mt-1">Change your account settings</span>
                    </div>
                    <div class="card-toolbar">
                        <button type="reset" class="btn btn-success mr-2">Save Changes</button>
                        <button type="reset" class="btn btn-secondary">Cancel</button>
                    </div>
                </div>

                <form className='form'>
                    <div className='card-body'>
                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Current Password</label>
                            <div class="col-lg-9 col-xl-6">
                                <input class="form-control form-control-lg form-control-solid" type="password" value={this.props.user.name} />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">New Password</label>
                            <div class="col-lg-9 col-xl-6">
                                <input class="form-control form-control-lg form-control-solid" type="password" value={this.props.user.name} />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Verify Password</label>
                            <div class="col-lg-9 col-xl-6">
                                <input class="form-control form-control-lg form-control-solid" type="password" value={this.props.user.name} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            )
        }
    }
    render() {
        return (
            <DashboardContainer>
                <SubHeader name="User" />


                <div className='d-flex flex-column-fluid'>
                    <div className='container'>

                        <div className='d-flex flex-row'>

                            {/* Side Bar */}
                            <div className='flex-row-auto offcanvas-mobile w-250px w-xxl-350px'>


                                <div className='card card-custom card-stretch'>

                                    <div className='card-body pt-4'>

                                        <div className='d-flex align-items-center'>
                                            <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                                <div class="symbol-label"></div>
                                                <i class="symbol-badge bg-success"></i>
                                            </div>

                                            <div>
                                                <a href="#" class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{this.props.user.name}</a>
                                                <div class="text-muted">{this.props.user.email}</div>
                                                
                                            </div>

                                        </div>


                                        <div className='navi navi-bold navi-hover navi-active navi-link-rounded mt-10'>
                                            <div class="navi-item mb-2">
                                            <div href="/metronic/demo1/custom/apps/profile/profile-1/overview.html" class="navi-link py-4 cursor-pointer" onClick={() =>{
                                                    this.setState({menu: 'profile'})
                                                }}>                                                    
                                                <span class="navi-icon mr-2">
                                                        <i class="flaticon2-user-outline-symbol"></i>
                                                    </span>
                                                    <span class="navi-text font-size-lg">Profile Overview</span>
                                                </div>
                                            </div>

                                            <div class="navi-item mb-2">
                                                <div href="/metronic/demo1/custom/apps/profile/profile-1/overview.html" class="navi-link py-4 cursor-pointer" onClick={() =>{
                                                    this.setState({menu: 'password'})
                                                }}>
                                                    <span class="navi-icon mr-2">
                                                        <i class="flaticon2-lock"></i>
                                                    </span>
                                                    <span class="navi-text font-size-lg">Change Password</span>
                                                </div>
                                            </div>
{/* 
                                            <div class="navi-item mb-2">
                                                <a href="/metronic/demo1/custom/apps/profile/profile-1/overview.html" class="navi-link py-4">
                                                    <span class="navi-icon mr-2">
                                                        <i class="flaticon2-mail"></i>
                                                    </span>
                                                    <span class="navi-text font-size-lg">Email Settings</span>
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                            </div>


                            {/* Main Content */}
                            <div className='flex-row-fluid ml-lg-8'>

                                {this.renderContent()}


                            </div>



                        </div>
                    </div>

                </div>
            </DashboardContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(User)