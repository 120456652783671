import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addClient, setOrganisation } from '../../actions';

import $ from "jquery";
import 'bootstrap';
import server from '../../api/server';


export class DocumentTypeModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            label: "",
            min_documents: 1,
            description: "",
            category: "",
            disabled: false
        };
    }

    componentDidMount() {
        if (this.props.active_document.label != "") {
            this.setState(this.props.active_document);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.active_document.label != this.props.active_document.label) {
            this.setState(this.props.active_document);
        }
    }

    formSubmit = async (e) => {
        e.preventDefault();
        this.setState({ disabled: true });
        if (this.props.edit == true) {
            var obj = {
                label: this.state.label,
                min_documents: this.state.min_documents,
                description: this.state.description,
                category: this.state.category

            };

            var organisation = { ...this.props.organisation };

            organisation.file_upload_settings.files[this.props.index] = obj;
            var response = await server.post("/doctype/edit", {
                organisation_id: this.props.organisation.id,
                organisation: organisation
            });
            this.props.setOrganisation(organisation);

            // var obj = {...this.state};
            // obj.organisation_id = this.props.organisation.id;
            // var response = await server.post("/doctype/edit",obj)
            // if(response.data){
            //     this.props.setOrganisation(response.data)
            // }
        } else {
            var obj = { ...this.state };
            obj.organisation_id = this.props.organisation.id;
            var response = await server.post("/doctype/create", obj);
            if (response.data) {
                this.props.setOrganisation(response.data);
            }
        }
        this.setState({
            disabled: false,
            label: "",
            category: "",
            min_documents: 1,
            description: "",
        });
        window.$("#DocumentTypeModal").modal("hide");
        window.$('.modal-backdrop').remove();
    };


    deleteDocumentType = async () => {
        var organisation = { ...this.props.organisation };
        organisation.file_upload_settings.files.splice(this.props.index, 1);
        console.log(organisation);
        var response = await server.post("/doctype/edit", {
            organisation_id: this.props.organisation.id,
            organisation: organisation
        });
        this.props.setOrganisation(organisation);
        window.$("#DocumentTypeModal").modal("hide");
        window.$('.modal-backdrop').remove();

    };

    render() {
        return (
            <div>
                <div class="modal fade" id="DocumentTypeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog " role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Edit Document Type</h5>
                                <button onClick={() => { window.$("#DocumentTypeModal").modal("hide"); window.$('.modal-backdrop').remove(); }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <i aria-hidden="true" class="ki ki-close"></i>
                                </button>
                            </div>
                            <form onSubmit={(e) => { this.formSubmit(e); }}>
                                <div class="modal-body">
                                    <div>
                                        <div class="form-group">
                                            <label>Document Name<span class="text-danger">*</span></label>
                                            <input required value={this.state.label} onChange={(e) => { this.setState({ label: e.target.value }); }} type="text" class="form-control" placeholder="Document Name" />

                                        </div>

                                        <div class="form-group">
                                            <label>Minimum Required Documents<span class="text-danger">*</span></label>
                                            <input required value={this.state.min_documents} onChange={(e) => { this.setState({ min_documents: e.target.value }); }} type="number" class="form-control" placeholder="Minimum Required Documents" />
                                        </div>

                                        <div class="form-group">
                                            <label>Document Category<span class="text-danger">*</span></label>
                                            <input required value={this.state.category} onChange={(e) => { this.setState({ category: e.target.value }); }} type="text" class="form-control" placeholder="Document Category" />
                                        </div>

                                        <div class="form-group">
                                            <label>Document Description<span class="text-danger">*</span></label>
                                            <input required value={this.state.description} onChange={(e) => { this.setState({ description: e.target.value }); }} type="text" class="form-control" placeholder="Document Description" />
                                        </div>
                                    </div>


                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal" onClick={() => { window.$("#DocumentTypeModal").modal("hide"); window.$('.modal-backdrop').remove(); }}>Close</button>
                                    {this.props.edit == true ? <button onClick={() => {
                                        this.deleteDocumentType();
                                    }} type="button" class="btn btn-danger font-weight-bold">Delete Document Type</button> : null}

                                    <button disabled={this.state.disabled} type="submit" class="btn btn-primary font-weight-bold">{this.props.edit == true ? 'Edit' : "Add"} Document Type</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    organisation: state.organisation
});

const mapDispatchToProps = {
    addClient,
    setOrganisation
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypeModal);
