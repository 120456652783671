import React from 'react';
import {connect} from 'react-redux'
import {updateData} from '../../actions'
import CurrencyInput from 'react-currency-input-field';


class Income extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      monthly_living_expenses_post_loan:{

      },
      test:0,
      total:0

    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    if('Income_Details' in this.props.data){
      const property = {...this.state};
      Object.keys(this.props.data['Income_Details']).map(key =>{
        property[key] = this.props.data['Income_Details'][key];
      })
      this.setState(property)
    }
  }

    componentDidUpdate(prevProps,prevState){
      clearTimeout(prevState.timeoutID)
      this.calculateTotal()
    }


    formChange = (e) =>{
      const update = async () =>{
        this.props.updateData({state:this.state,type:"Income_Details"})
      }
      const timeoutID = setTimeout(function() {
        update()
      },250)
      this.setState({timeoutID:timeoutID})
    }


    handleInputChange = (value,field) =>{
      const property = {...this.state.monthly_living_expenses_post_loan}
      property[field] = value;
      this.setState({monthly_living_expenses_post_loan:property})
    }

    calculateTotal = () =>{
      var new_total = 0;
      Object.keys(this.state.monthly_living_expenses_post_loan).map(key =>{
        if(key != "other" && !(isNaN(this.state.monthly_living_expenses_post_loan[key]))){
          new_total += Number(this.state.monthly_living_expenses_post_loan[key])
        }
      })
      if(this.state.total != new_total){
        this.setState({total:new_total})
      }

    }


    handleSubmit = (e) =>{
      e.preventDefault()
      this.props.updateData({state:this.state,type:"Income_Details"})
      this.props.changeForm("Liabilities_Debts")
    }

    renderButtons = () =>{
      if(this.props.showButtons == 'false'){
        return(null)
      }else{
        return(
          <div className="">
          <button className="ui button primary " onClick={(e) =>{this.props.changeForm("Employment_Details")}}>Previous Page</button>
          <button className="ui button primary " type="submit" style={{float:"right"}}>Next Page</button>
        </div>
        )
      }
    }



    render(){
        return(
            <div>
        <form onSubmit = {(e) =>{this.handleSubmit(e)}} onValueChange = {this.formChange}>
        <table class="table2">
          <thead>
            <tr>
              <th colspan="2">Monthly living expenses <small>(following settlement of your loan)</small></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Groceries</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput required prefix="$" id="input-example" name="input-name"  decimalsLimit={0} value={this.state.monthly_living_expenses_post_loan.groceries || ""} onValueChange={(value, name) => this.handleInputChange(value,"groceries")}/>
                </div>
              </td>
            </tr>

            <tr>
              <td>Clothing and Personal Care</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput required prefix="$" decimalsLimit={0} required value={this.state.monthly_living_expenses_post_loan.clothing || ""} onValueChange={(value, name) => this.handleInputChange(value,"clothing")}  name="" defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>Transport</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput required  prefix="$" min="0"  required value={this.state.monthly_living_expenses_post_loan.transport || ""} onValueChange={(value, name) => this.handleInputChange(value,"transport")}  name="" defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>Communication</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput required prefix="$" min="0" required value={this.state.monthly_living_expenses_post_loan.communication || ""} onValueChange={(value, name) => this.handleInputChange(value,"communication")}  name="" defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>Recreation and Entertainment</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput required  prefix="$" min="0" required value={this.state.monthly_living_expenses_post_loan.recreation || ""} onValueChange={(value, name) => this.handleInputChange(value,"recreation")}  name="" defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>Child Care</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput  prefix="$" min="0" value={this.state.monthly_living_expenses_post_loan.child_care || ""} onValueChange={(value, name) => this.handleInputChange(value,"child_care")}  name="" defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>Pet Care</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput prefix="$" min="0" value={this.state.monthly_living_expenses_post_loan.pet_care || ""} onValueChange={(value, name) => this.handleInputChange(value,"pet_care")}  name="" defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>Medical and Health</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput required prefix="$" min="0" required value={this.state.monthly_living_expenses_post_loan.health || ""} onValueChange={(value, name) => this.handleInputChange(value,"health")}  name="" defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>Health Insurance</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput required prefix="$" min="0" required value={this.state.monthly_living_expenses_post_loan.health_insurance || ""} onValueChange={(value, name) => this.handleInputChange(value,"health_insurance")}  name="" defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>General Basic Insurance</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput prefix="$" min="0" value={this.state.monthly_living_expenses_post_loan.general_insurance || ""} onValueChange={(value, name) => this.handleInputChange(value,"general_insurance")}  name="" defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>Sickness, Accident, Life Insurance</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput prefix="$" min="0" value={this.state.monthly_living_expenses_post_loan.sickness || ""} onValueChange={(value, name) => this.handleInputChange(value,"sickness")}  name="" defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>Public School Education</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput prefix="$" min="0" value={this.state.monthly_living_expenses_post_loan.public_school || ""} onValueChange={(value, name) => this.handleInputChange(value,"public_school")}  name="" defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>Private School Education and Tutoring</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput prefix="$" min="0"  value={this.state.monthly_living_expenses_post_loan.private_school || ""} onValueChange={(value, name) => this.handleInputChange(value,"private_school")} name="" defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>Higher Education and Professional Fees</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput prefix="$" min="0"  name="" value={this.state.monthly_living_expenses_post_loan.higher_edu || ""} onValueChange={(value, name) => this.handleInputChange(value,"higher_edu")} defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>Primary Residence running costs <span data-tooltip="This is your running costs for your residence (power, water, maintenance etc) not your rent/loan repayment."><i class="info circle icon"></i></span>
              </td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput required prefix="$" min="0" required  name="" value={this.state.monthly_living_expenses_post_loan.primary_res_running_costs || ""} onValueChange={(value, name) => this.handleInputChange(value,"primary_res_running_costs")} defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>Primary Residence strata fees and taxes</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput required prefix="$" min="0"  name="" value={this.state.monthly_living_expenses_post_loan.primary_res_strata || ""} onValueChange={(value, name) => this.handleInputChange(value,"primary_res_strata")} defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>Investment property running costs</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput prefix="$" min="0"  name="" value={this.state.monthly_living_expenses_post_loan.investment_running || ""} onValueChange={(value, name) => this.handleInputChange(value,"investment_running")} defaultValue={0} />
                </div>
              </td>
              </tr>

              <tr>
              <td>Secondary Residence running costs</td>
              <td>
                <div class="flex-input">
                  
                  <CurrencyInput prefix="$" min="0"  name="" value={this.state.monthly_living_expenses_post_loan.secondary_res_running || ""} onValueChange={(value, name) => this.handleInputChange(value,"secondary_res_running")}  defaultValue={0} />
                </div>
              </td>
              </tr>

            <tr>
              <td>
                <div class="flex-input">
                  <label for="">Other (please specify)</label>
                  <input min="0" type="text" name="" value={this.state.monthly_living_expenses_post_loan.other || ''} onChange={(e) =>this.handleInputChange(e.target.value,"other")} defaultValue="" />
                </div>
              </td>
              <td>
                <div class="flex-input">
                
                  <CurrencyInput prefix="$" min="0"  name="" value={this.state.monthly_living_expenses_post_loan.other_amount || ""} onValueChange={(value, name) => this.handleInputChange(value,"other_amount")}  defaultValue={0} />

                </div>
              </td>
            </tr>

            <tr>
              <td>Total</td>
              <td>
                <div class="flex-input">
                  <CurrencyInput disabled prefix="$" min="0"  name="" value={this.state.total}  defaultValue={0} />

                </div>
              </td>
            </tr>
          </tbody>
        </table>   
           
           {this.renderButtons()}
           </form>
            </div>
        )

    }
}


const mapStateToProps = (state) =>{
  return {data:state.data}
}

export default connect(mapStateToProps,{updateData})(Income)