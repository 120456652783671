import React, { Component } from 'react';
import { connect } from 'react-redux';

import server from '../../../api/server';
import BankStatement from './BankStatement';
import { saveAs } from 'file-saver';
// import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { Disclosure } from '@headlessui/react';

export class Documents extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null
        };
    }

    componentDidMount() {
        if (this.state.data == null) {

            this.fetchData();
        }
    }

    fetchData = async () => {
        if (this.props.documents.applicants[this.props.applicant_id].requested_documents == undefined || this.props.bankstatements == null) {
            return;
        }
        var response = await server.post("/bankstatements/data", { id: this.props.user.token, application_id: this.props.bankstatements.application_id, applicant_id: this.props.applicant_id });
        if (response.data && response.data.success == true) {
            this.setState({
                data: response.data.htmlArray
            });
        }
    };

    renderFiles = (catFiles) => {
        var files = [];
        if (catFiles == undefined) {
            return;
        }



        catFiles.forEach((file, index) => {
            var file_objects = [];
            if (file.files.length > 0) {
                file.files.forEach(uploadedFile => {
                    file_objects.push(
                        <div class=" col-lg-3 d-flex flex-column align-items-center cursor-pointer mb-5" onClick={async () => {
                            var response = await server.post("/docs/download", { file_name: uploadedFile.file_name, id: this.props.user.token, file_upload_id: this.props.active_application.documents.id, path: uploadedFile.path }, { responseType: "blob" });
                            if (response.data) {
                                var blob = new Blob([response.data], { type: "application/octet-stream" });
                                saveAs(blob, uploadedFile.file_name);


                                // jpg
                                // let blob = new Blob([response.data], {
                                //     // type: "image/jpeg"
                                //     //               OR
                                //     // type:"application/zip, application/octet-stream"
                                // });
                                // let objectUrl = URL.createObjectURL(blob);
                                // let link = document.createElement('a');
                                // link.href = objectUrl;
                                // link.download = `test.jpg`;
                                // link.click();
                            }
                        }}>
                            <img alt="" class="max-h-65px" src={process.env.PUBLIC_URL + "/metronic/assets/media/svg/files/doc.svg"} />
                            <span className='text-dark-75 font-weight-bold mt-5 font-size-lg' style={{ width: "150px", textAlign: "center" }}>{uploadedFile.file_name}</span>
                        </div>
                    );
                });
            } else {
                file_objects.push(
                    <div class="col-lg-12 d-flex flex-column align-items-center">
                        <span>Your client is yet to upload any documents for this category.</span>
                    </div>
                );
            }



            files.push(

                <Disclosure>
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex w-full justify-between rounded-sm bg-[#F3F6F9] px-5 py-4 text-left text-lg font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                <span>{file.label} <span className={`${file.files.length == 0 ? "text-danger" : "text-success"}`}>({file.files.length})</span></span>
                                {/* <ChevronUpIcon
                                className={`${open ? 'rotate-180 transform' : ''
                                    } h-5 w-5 text-purple-500`}
                            /> */}
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                                {file_objects}
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                // <div class="card">
                //     <div class="card-header">
                //         <div class="card-title collapsed" id={`${this.props.applicant_id}_${index}_${file.label.split(" ").join("-")}`} data-toggle="collapse" data-target={`#${this.props.applicant_id}_${index}_${file.label.split(" ").join("-")}`}>
                //             {file.label} <span className={`${file.files.length == 0 ? "text-danger" : "text-success"}`}>({file.files.length})</span>
                //         </div>
                //     </div>
                //     <div id={`${this.props.applicant_id}_${index}_${file.label.split(" ").join("-")}`} class="collapse" data-parent={`#${this.props.applicant_id}_${index}_${file.label.split(" ").join("-")}`}>
                //         <div class="card-body">
                //             <div className='row'>
                //                 {file_objects}
                //             </div>
                //         </div>
                //     </div>
                // </div>
            );

        });


        // Object.keys(this.props.documents.applicants[this.props.applicant_id].requested_documents).forEach((key, index) => {
        //     var file_objects = [];
        //     if (this.props.documents.applicants[this.props.applicant_id].requested_documents[key].files.length > 0) {
        //         this.props.documents.applicants[this.props.applicant_id].requested_documents[key].files.forEach((file, index) => {

        //             file_objects.push(

        //                 <div class=" col-lg-3 d-flex flex-column align-items-center cursor-pointer mb-5" onClick={async () => {
        //                     var response = await server.post("/docs/download", { id: this.props.user.token, file_upload_id: this.props.active_application.documents.id, path: file.path });
        //                     if (response.data) {
        //                         window.open(response.data);
        //                     }
        //                 }}>
        //                     <img alt="" class="max-h-65px" src={process.env.PUBLIC_URL + "/metronic/assets/media/svg/files/doc.svg"} />
        //                     <span className='text-dark-75 font-weight-bold mt-5 font-size-lg' style={{ width: "150px", textAlign: "center" }}>{file.file_name}</span>
        //                 </div>
        //             );

        //         });
        //     } else {
        //         file_objects.push(
        //             <div class="col-lg-12 d-flex flex-column align-items-center">
        //                 <span>Your client is yet to upload any documents for this category.</span>
        //             </div>
        //         );
        //     }

        //     files.push(

        //         <div class="card">
        //             <div class="card-header">
        //                 <div class="card-title collapsed" data-toggle="collapse" data-target={`#${this.props.applicant_id}_${index}`}>
        //                     {this.props.documents.applicants[this.props.applicant_id].requested_documents[key].label} <span className={`${this.props.documents.applicants[this.props.applicant_id].requested_documents[key].files.length == 0 ? "text-danger" : "text-success"}`}>({this.props.documents.applicants[this.props.applicant_id].requested_documents[key].files.length})</span>
        //                 </div>
        //             </div>
        //             <div id={`${this.props.applicant_id}_${index}`} class="collapse" data-parent={`#${this.props.applicant_id}_${index}`}>
        //                 <div class="card-body">
        //                     <div className='row'>
        //                         {file_objects}
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // });





        return files;
    };


    renderButton = () => {
        if (this.props.documents.applicants[this.props.applicant_id].requested_documents == undefined) {
            return;
        } else {

        }
    };

    renderCategories = () => {
        var categories = {};

        Object.keys(this.props.documents.applicants[this.props.applicant_id].requested_documents).forEach((key, index) => {
            var file = this.props.documents.applicants[this.props.applicant_id].requested_documents[key];
            var category = "Files";
            if (file.category != undefined) {
                category = file.category;
            }
            if (categories[category] == undefined) {
                categories[category] = [];
            }

            categories[category].push(file);
        });

        var categories_array = [];

        Object.keys(categories).forEach((key, index) => {
            categories_array.push(
                <div>
                    <h4 className="mb-4 mt-4">{key}</h4>

                    <div class="accordion accordion-toggle-arrow" id={`accordion_${key}`}>
                        {this.renderFiles(categories[key])}
                    </div>
                </div>
            );
        });

        return categories_array;
    };


    render() {
        if (this.props.active_application.application.applicants[this.props.applicant_id] == undefined) {
            return null;
        } else {
            return (
                <div>
                    <div class="h2 mb-3">{`${this.props.active_application.application.applicants[this.props.applicant_id].name}`} <small class="text-muted">Files</small></div>

                    {(this.props.documents.applicants[this.props.applicant_id].requested_documents == undefined) ?
                        <div>
                            <div>You have not requested any documents for this applicant.</div>

                        </div>
                        :
                        <div>
                            {this.renderCategories()}
                            {/* <div class="accordion accordion-toggle-arrow" id="accordionExample1">
                                {this.renderFiles()}
                            </div> */}

                            <button className='btn btn-info mt-5 mb-5' onClick={async () => {
                                var response = await server.post("/docs/all", { file_upload_id: this.props.active_application.documents.id, id: this.props.user.token, path: `${this.props.documents.id}/${this.props.applicant_id}` }, { responseType: "blob" });
                                console.log(response.data);
                                let blob = new Blob([response.data], {
                                    type: "application/zip"
                                    //               OR
                                    // type:"application/zip, application/octet-stream"
                                });
                                let objectUrl = URL.createObjectURL(blob);
                                let link = document.createElement('a');
                                link.href = objectUrl;
                                link.download = `${this.props.active_application.application.applicants[this.props.applicant_id].name}.zip`;
                                link.click();

                            }}>Download Applicant Files</button>
                            <div class="separator separator-solid mb-5" />
                        </div>
                    }


                </div>


            );
        }

    }
}

const mapStateToProps = (state) => ({
    documents: state.admin.active_application.documents,
    bankstatements: state.admin.active_application.bankstatements,
    user: state.user,
    active_application: state.admin.active_application,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);