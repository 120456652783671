import {combineReducers} from 'redux'


import idReducer from './idReducer'
import dataReducer from './dataReducer'
import formReducer from './formReducer'
import authReducer from './authReducer'
import adminReducer from './adminReducer'
import voireducer from './voireducer'
import requestedInfo from './requestedInfo'
import userReducer from './userReducer'
import documentsReducer from './docsumentsReducer'
import organizationReducer from './organizationReducer'
import eventsReducer from './eventsReducer'
import bankStatementsReducer from './bankstatementsReducer'
// import pageReducer from './pageReducer'


export default combineReducers({
    id:idReducer,
    data:dataReducer,
    auth:authReducer,
    admin:adminReducer,
    VOI:voireducer,
    requested_info:requestedInfo,
    user:userReducer,
    organisation:organizationReducer,
    document_collection:documentsReducer,
    events:eventsReducer,
    bankstatements:bankStatementsReducer
})
