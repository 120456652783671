import React, { Component } from 'react'
import { connect } from 'react-redux'

import Helmet from "react-helmet"
import  Aside  from "./Aside";
import  Header  from "./Header";

import firebase from '../Firebase.js'
import server from '../../api/server'
import history from '../../history'
import {fetchApplications,checkAuth} from "../../actions"

export class DashboardContainer extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             loggedIn:false
        }
    }
    
    componentDidMount() {
        if(this.props.user.token == null){
            this.props.checkAuth()
        }
    }


    renderHelmet = () =>{
        return(
            <Helmet>
            <meta charset="utf-8" />
            <title>Admin | LoanGateway</title>
            <meta name="description" content="Page with empty content" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <link rel="canonical" href="https://keenthemes.com/metronic" />

            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"
            />

            <link
                href={
                    process.env.PUBLIC_URL +
                    `/metronic/assets/plugins/custom/fullcalendar/fullcalendar.bundle.css`
                }
                rel="stylesheet"
                type="text/css"
            />

            <link
                href={
                    process.env.PUBLIC_URL +
                    "/metronic/assets/plugins/global/plugins.bundle.css"
                }
                rel="stylesheet"
                type="text/css"
            />
            <link
                href={
                    process.env.PUBLIC_URL +
                    "/metronic/assets/plugins/custom/prismjs/prismjs.bundle.css"
                }
                rel="stylesheet"
                type="text/css"
            />

            <link
                href={
                    process.env.PUBLIC_URL +
                    "/metronic/assets/css/themes/layout/header/base/light.css"
                }
                rel="stylesheet"
                type="text/css"
            />
            <link
                href={
                    process.env.PUBLIC_URL +
                    "/metronic/assets/css/themes/layout/header/menu/light.css"
                }
                rel="stylesheet"
                type="text/css"
            />
            <link
                href={
                    process.env.PUBLIC_URL +
                    "/metronic/assets/css/themes/layout/brand/dark.css"
                }
                rel="stylesheet"
                type="text/css"
            />
            <link
                href={
                    process.env.PUBLIC_URL +
                    "/metronic/assets/css/themes/layout/aside/dark.css"
                }
                rel="stylesheet"
                type="text/css"
            />
            <link
                href={
                    process.env.PUBLIC_URL + "/metronic/assets/css/style.bundle.css"
                }
                rel="stylesheet"
                type="text/css"
            />

            <link rel="shortcut icon" href="/assets/media/logos/favicon.ico" />

        </Helmet>
        )
    }
    render() {
        return (
            <div className="page-loading-enabled page-loading">
            {this.renderHelmet()}
            <body className="header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable page-loading">
                {/* {/* <!--[[html-partial:include:{"file":"partials/_header-mobile.html"}]/--> */}
                <div class="d-flex flex-column flex-root">
                    {/* <!--[begin::Page--> */}
                    <div class="d-flex flex-row flex-column-fluid page">
                        {/* <!--[[html-partial:include:{"file":"partials/_aside.html"}]/--> */}
                        <Aside></Aside>

                        {/* <!--[begin::Wrapper--> */}
                        <div
                            class="d-flex flex-column flex-row-fluid wrapper"
                            id="kt_wrapper"
                        >
                            {/* <!--[[html-partial:include:{"file":"partials/_header.html"}]/--> */}
                            <Header></Header>

                            {/* <!--[begin::Content--> */}
                            <div
                                class="content d-flex flex-column flex-column-fluid"
                                id="kt_content"
                            >
                                {/* <!--[[html-partial:include:{"file":"partials/_subheader/subheader-v1.html"}]/--> */}
                                {/* <Clients/> */}
                                {this.props.children}
                            </div>

                            {/* <!--[end::Content--> */}
                        </div>

                        {/* <!--[end::Wrapper--> */}
                    </div>

                    {/* <!--[end::Page--> */}
                </div>
            </body>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user:state.user
})

const mapDispatchToProps = {
    fetchApplications,
    checkAuth
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
