import React, { Component } from 'react'
import { connect } from 'react-redux'

export class BillingRow extends Component {
  render() {
    return (
        <tr className='font-weight-boldest'>
        <td className='pt-7 align-middle'>{this.props.label}</td>
        <td className='text-right pt-7 align-middle'>{this.props.qty}</td>
        <td className='text-right pt-7 align-middle'>${this.props.price.toFixed(2)}</td>
        <td className='text-primary text-right pt-7 align-middle'>${(this.props.qty * this.props.price).toFixed(2)}</td>
    </tr>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BillingRow)