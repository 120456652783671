import React, { Component } from 'react'
import { connect } from 'react-redux'

import Nav from "../Nav"

import { Helmet } from "react-helmet"

import {fetchVOIData,fetchForm,updateVOIData} from "../../actions";

export class VOICompleted extends Component {

    componentDidMount() {
        this.props.fetchForm(this.props.match.params.id)
        this.props.fetchVOIData(this.props.match.params.id);
    }
    render() {
        return (
            <div>
                <Helmet>
                    <title>Fact Find - Mortgage Choice Sydney CBD</title>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" integrity="sha512-8bHTC73gkZ7rZ7vpqUQThUDhqcNFyYi2xgDgPDHc+GXVGHXq+xPjynxIopALmOPqzo9JZj0k6OqqewdGO3EsrQ==" crossorigin="anonymous" />
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/components/grid.min.css" integrity="sha512-RtktBL0Hhw9K2e2rGLZGadK8cF6Q0wKxRUkfh4pJszwnarmh3GbqHCiLm3UZFA1dRiFqtPkVrcby0hXWsqoDNA==" crossorigin="anonymous" />
                    <link rel="stylesheet" href={process.env.PUBLIC_URL + `/css/style.css`} />

                </Helmet>
                <Nav showProgress="false"></Nav>

                <div>
                    <section className="main-wrap">
                        <div className="dashboard-container">
                        <div className="dashboard-module">
                                <h2>Thank you!</h2>
                                <p style={{"marginTop":"7px",marginBottom:"14px"}}>Thank you for completing your Verification of Identity. </p>
                            
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    fetchVOIData,
    fetchForm,
    updateVOIData
}

export default connect(mapStateToProps, mapDispatchToProps)(VOICompleted)
