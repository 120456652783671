import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import history from '../../history';
import firebase from '../Firebase.js';
export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: "Active_Forms",
            email: "",
            password: "",
            errorMessage: "",
            logging_in: false
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.email && this.state.password) {
            this.setState({ logging_in: true });
            firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
                .then((user) => {
                    // Signed in 

                    history.push("/admin");
                    // ...
                })
                .catch((error) => {
                    var errorMessage = error.message;
                    this.setState({ errorMessage: errorMessage, logging_in: false, password: "" });

                });
        }
    };
    renderHelmet = () => {
        return (
            <Helmet defer={false}>

                <meta charset="utf-8" />
                <title>Login | Loan Gateway</title>
                <meta name="description" content="Page with empty content" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <link rel="canonical" href="https://keenthemes.com/metronic" />

                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"
                />

                <link
                    href={
                        process.env.PUBLIC_URL +
                        `metronic/assets/plugins/custom/fullcalendar/fullcalendar.bundle.css`
                    }
                    rel="stylesheet"
                    type="text/css"
                />



                <link
                    href={
                        process.env.PUBLIC_URL +
                        "metronic/assets/plugins/global/plugins.bundle.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />
                <link
                    href={
                        process.env.PUBLIC_URL +
                        "metronic/assets/plugins/custom/prismjs/prismjs.bundle.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />

                <link
                    href={
                        process.env.PUBLIC_URL +
                        "metronic/assets/css/themes/layout/header/base/light.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />
                <link
                    href={
                        process.env.PUBLIC_URL +
                        "metronic/assets/css/themes/layout/header/menu/light.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />
                <link
                    href={
                        process.env.PUBLIC_URL +
                        "metronic/assets/css/themes/layout/brand/dark.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />
                <link
                    href={
                        process.env.PUBLIC_URL +
                        "metronic/assets/css/themes/layout/aside/dark.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />
                <link
                    href={
                        process.env.PUBLIC_URL + "metronic/assets/css/style.bundle.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />

                <link
                    href={
                        process.env.PUBLIC_URL + "metronic/assets/css/pages/login/login-2.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />


                <link rel="shortcut icon" href="assets/media/logos/favicon.ico" />

            </Helmet>
        );
    };

    render() {
        return (
            <body id="kt_body" class="header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable page-loading">

                {this.renderHelmet()}
                <div class="d-flex flex-column flex-root">

                    <div class="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">

                        <div class="login-aside order-2 order-lg-1 d-flex flex-row-auto position-relative overflow-hidden">

                            <div class="d-flex flex-column-fluid flex-column justify-content-between py-9 px-7 py-lg-13 px-lg-35">

                                <a href="#" class="text-center pt-2">
                                    <img src={process.env.PUBLIC_URL + "/images/logos/dark_transparent.png"} class="max-h-75px" alt="" />
                                </a>

                                <div class="d-flex flex-column-fluid flex-column flex-center">

                                    <div class="login-form login-signin py-11">

                                        <form onSubmit={(e) => { this.handleSubmit(e); }} class="form" novalidate="novalidate" id="kt_login_signin_form">

                                            <div class="text-center pb-8">
                                                <h2 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Sign In</h2>
                                                <span class="text-muted font-weight-bold font-size-h4"><span>Or </span>
                                                    <a href="#" class="text-primary font-weight-bolder" id="kt_login_signup">Create An Account</a></span>
                                            </div>

                                            <div class="form-group">
                                                <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
                                                <input value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }); }} required class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" name="username" autocomplete="on" />
                                            </div>

                                            <div class="form-group">
                                                <div class="d-flex justify-content-between mt-n5">
                                                    <label class="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
                                                    <a href="javascript:;" class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5" id="kt_login_forgot">Forgot Password ?</a>
                                                </div>
                                                <input value={this.state.password} onChange={(e) => { this.setState({ password: e.target.value }); }} required class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="password" name="password" autocomplete="on" />
                                            </div>
                                            <div class="fv-plugins-message-container"><div data-field="username" data-validator="notEmpty" class="fv-help-block">{this.state.errorMessage}</div></div>

                                            <div class="text-center pt-2">
                                                <button disabled={this.state.logging_in} id="kt_login_signin_submit" class="btn btn-dark font-weight-bolder font-size-h6 px-8 py-4 my-3">Sign in</button>
                                            </div>

                                        </form>

                                    </div>


                                </div>



                            </div>

                        </div>

                        <div class="content order-1 order-lg-2 d-flex flex-column w-100 pb-0" style={{ backgroundColor: "#B1DCED" }}>

                            <div class="d-flex flex-column justify-content-center text-center pt-lg-40 pt-md-5 pt-sm-5 px-lg-0 pt-5 px-7">
                                <h3 class="display4 font-weight-bolder my-7 text-dark" style={{ color: "#986923" }}>Loan Gateway</h3>
                                <p class="font-weight-bolder font-size-h2-md font-size-lg text-dark opacity-70">Fact Find, Verification of Identity & Document Collection made easy!
                                    <br />Made by brokers, for brokers.</p>
                            </div>

                            {/* Background Image style="background-image: url(assets/media/svg/illustrations/login-visual-2.svg);" */}
                            <div class="login-image content-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center" ></div>

                        </div>

                    </div>

                </div>
            </body>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
