import React from 'react';
import {connect} from 'react-redux'

import {fetchForms} from '../../actions'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import server from '../../api/server'
import firebase from './../Firebase.js'
import history from '../../history'

import AdminSearch from './admin_elements/AdminSearch.js'


class ArchivedForms extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            menu:"Active_Forms",
            all_forms:[],
            current_forms:[],
            search:false
        }
      }
    componentDidMount() {

        this.fetchForms()

    }

    fetchForms = async () =>{
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(async (idToken) => {
            console.log(idToken)
            const response = await server.post("/forms",{id:idToken})
            if(response.data && response.data != false){
                this.setState({all_forms:response.data,current_forms:response.data})
            }else{
                history.push("/login")
            }
        }).catch(function(error) {
            history.push("/login")
        });
        // const response = await server.get("/forms")
        // if(response.data){
        //     this.setState({forms:response.data})
        // }
    }

    archiveForm = async (e,id) =>{

        e.preventDefault()
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(async (idToken) => {
            const response = await server.post("/archive",{user_ID:idToken,id:id,archiveStatus:false})
            if(response.data){
                var forms = [...this.state.all_forms];
                forms.forEach(form =>{
                    if(form.id === id){
                        form.archived = !form.archived;
                    }
                })
            }else{
                history.push("/login")
            }
            this.setState({all_forms:forms,current_forms:forms})
        }).catch(function(error) {
            history.push("/login")
        });
    }

    renderName = (form) =>{
        console.log(form)
        var name = "";
        if(form.completed == true){
            name = `${form.data.General_Details.applicant1.given_name} ${form.data.General_Details.applicant1.family_name}`
        }

        if(form.completed && form.data.General_Details.applicant2 != undefined &&  'given_name' in form.data.General_Details.applicant2 && form.data.General_Details.applicant2.given_name != "" && 'family_name' in form.data.General_Details.applicant2 && form.data.General_Details.applicant2.family_name != "" ){
            name = name + ` & ${form.data.General_Details.applicant2.given_name} ${form.data.General_Details.applicant2.family_name}`
        }
        return name
    }


    formClick = (e,id) =>{
        if(this.state.version_dropdown == id){
            this.setState({version_dropdown:""})
        }else{
            this.setState({version_dropdown:id})
        }
    }

    renderMobile = (form) =>{
        var mobile = "";
        if(form.data && "General_Details" in form.data && "applicant1" in form.data.General_Details && 'mobile' in form.data.General_Details.applicant1 ){
            mobile = form.data.General_Details.applicant1.mobile
        }
        return mobile
    }





    renderForms = () =>{
        const forms = []
        if(this.state.current_forms.length >0){
            this.state.current_forms.forEach(form =>{
                if(this.state.search == true || form.archived == true){
                    forms.push(
                        <tr key={form.id}>
                            <td style={{cursor:"pointer"}} onClick={(e) =>{this.formClick(e,form.id)}} colSpan="2" data-label="Name">{this.renderName(form) || form.name} {'versions' in form ? <i class="caret down icon"></i>:null} {form.completed === true ? <a style={{float:"right"}} class="ui green label small completed-label">Completed</a> : <a class="ui grey label small" style={{float:"right"}}>In Progress</a>}</td>
                            <td colSpan="2" data-label="Mobile"><div><span>{this.renderMobile(form) || null}</span></div></td>
                            <td colSpan="2" data-label="email">{form.email}</td>
                            <td  data-label="Archive">
                                <div style={{width:"500px"}}>
                                    <button className="ui button negative small" onClick={(e) =>{this.archiveForm(e,form.id)}}>Unarchive</button>
                                    <CopyToClipboard text={`${process.env.REACT_APP_DOMAIN}dashboard/${form.id}`}>
                                        <button className="ui button primary small">Copy Client Link</button>
                                    </CopyToClipboard>
                                    <a className="ui button positive small" target="_blank" href={`/adminform/${form.id}`}>Print Version</a>

                                </div>
                            </td>
    
                        </tr>
                    )

                    if(this.state.version_dropdown == form.id && 'versions' in form){
                        var versions = []
                        Object.keys(form.versions).map(key =>{
                            versions.push(
                                <tr>
                                    <td>{form.versions[key].date}</td>
                                    <td><a className="ui button positive small" target="_blank" href={`/versions/${key}/${form.id}`}>Print Version</a></td>
                                </tr>
                            )
                        })
                        forms.push(
                            <tr>
                                <td colSpan="10">
                                    <table className="ui single line table" style={{width:"50%"}}>
                                        <thead>
                                            <tr>
                                                <th>Version Date</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {versions}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        )

                    }
                }
            })
        }
        return forms
    }

    setResults = (results,search) =>{
        this.setState({current_forms:results,search:search})
    }

    render(){
        return(
            <div>
                <AdminSearch setResults = {this.setResults} forms = {this.state.all_forms}></AdminSearch>
                <table class="ui celled table admin-table">
                    <thead>
                        <tr>
                            <th colSpan="2">Name</th>
                            <th colSpan="2">Mobile</th>
                            <th colSpan="2">Email</th>
                            <th colSpan="4" style={{width:"150px"}}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.renderForms()}
                    </tbody>
                </table>
            </div>
        )
    }

}

const mapStateToProps = (state) =>{
  return {forms:state.forms}
}

export default connect(mapStateToProps,{fetchForms})(ArchivedForms)
