const INIT_STATE = {
    logged_in:false,

}


export default (state = null,action) => {
    switch(action.type){
      case 'SET_AUTH':
        return true
      default:
        return state
    }
  }
  