import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Nav from './Nav';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import firebase from './Firebase';
import server from '../api/server';
import history from '../history';
export class ClientLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: "Active_Forms",
            email: "",
            password: "",
            errorMessage: "",
            logged_in: true,
            emailSent: false,
            error: ""
        };
    }

    componentDidMount() {
        this.checkAuth();
    }

    checkAuth = () => {
   
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                console.log('logged in.');
                var response = await server.post("/client/auth/redirect").catch(e => {
                    this.setState({ logged_in: false });
                });
                if (response && response.data != false) {
                    var application_id = response.data.application_id;
                    history.push(`/dashboard/${application_id}`);
                } else {
                    this.setState({ logged_in: false });
                }
                // User is signed in.
            } else {

                this.setState({ logged_in: false });
                // history.push("/login");
                // No user is signed in.
            }
        });
    };
    handleSubmit = async (e) => {
        e.preventDefault();
        const actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            // Append the email to the redirect URL.
            // Add the email to the redirect URL.
            url: process.env.REACT_APP_REDIRECT_URI + "?email=" + this.state.email,
            // This must be true.
            handleCodeInApp: true,
            // iOS: {
            //     bundleId: 'com.example.ios'
            // },
            // android: {
            //     packageName: 'com.example.android',
            //     installApp: true,
            //     minimumVersion: '12'
            // },
            // dynamicLinkDomain: 'example.page.link'
        };
        var emailCheck = await server.post("/client/emailValidate", { email: this.state.email });
        if (!emailCheck || emailCheck.data == false) {
            return this.setState({ errorMessage: "A client with this email was not found." });
        }
        firebase.auth().sendSignInLinkToEmail(this.state.email, actionCodeSettings)
            .then(() => {
                console.log("EMAIL SENT?");
                window.localStorage.setItem('emailForSignIn', this.state.email);
                this.setState({ emailSent: true });
            })
            .catch((e) => {
                this.setState({
                    errorMessage: e.message
                });
            });
    };

    renderHelmet = () => {
        return (
            <Helmet defer={false}>

                <meta charset="utf-8" />
                <title>Login | Loan Gateway</title>
                <meta name="description" content="Page with empty content" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <link rel="canonical" href="https://keenthemes.com/metronic" />

                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"
                />

                <link
                    href={
                        process.env.PUBLIC_URL +
                        `metronic/ assets / plugins / custom / fullcalendar / fullcalendar.bundle.css`
                    }
                    rel="stylesheet"
                    type="text/css"
                />



                <link
                    href={
                        process.env.PUBLIC_URL +
                        "metronic/assets/plugins/global/plugins.bundle.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />
                <link
                    href={
                        process.env.PUBLIC_URL +
                        "metronic/assets/plugins/custom/prismjs/prismjs.bundle.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />

                <link
                    href={
                        process.env.PUBLIC_URL +
                        "metronic/assets/css/themes/layout/header/base/light.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />
                <link
                    href={
                        process.env.PUBLIC_URL +
                        "metronic/assets/css/themes/layout/header/menu/light.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />
                <link
                    href={
                        process.env.PUBLIC_URL +
                        "metronic/assets/css/themes/layout/brand/dark.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />
                <link
                    href={
                        process.env.PUBLIC_URL +
                        "metronic/assets/css/themes/layout/aside/dark.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />
                <link
                    href={
                        process.env.PUBLIC_URL + "metronic/assets/css/style.bundle.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />

                <link
                    href={
                        process.env.PUBLIC_URL + "metronic/assets/css/pages/login/login-2.css"
                    }
                    rel="stylesheet"
                    type="text/css"
                />


                <link rel="shortcut icon" href="assets/media/logos/favicon.ico" />

            </Helmet>
        );
    };

    render() {
        if (this.state.logged_in == true) {
            return (
                <div className="dashboard">

                    <Helmet>
                        <title>Loan Gateway - Mortgage Choice Sydney CBD</title>

                        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" integrity="sha512-8bHTC73gkZ7rZ7vpqUQThUDhqcNFyYi2xgDgPDHc+GXVGHXq+xPjynxIopALmOPqzo9JZj0k6OqqewdGO3EsrQ==" crossorigin="anonymous" />
                        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/components/grid.min.css" integrity="sha512-RtktBL0Hhw9K2e2rGLZGadK8cF6Q0wKxRUkfh4pJszwnarmh3GbqHCiLm3UZFA1dRiFqtPkVrcby0hXWsqoDNA==" crossorigin="anonymous" />
                        <link rel="stylesheet" href={process.env.PUBLIC_URL + `/ css / style.css`} />
                    </Helmet>
                    <div className="form-loading">
                        <img style={{ width: "500px" }} src={process.env.PUBLIC_URL + '/images/logos/dark_transparent.png'} alt="" />
                    </div>
                </div>

            );
        } else {
            return (
                <body id="kt_body" class="header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable page-loading">

                    {this.renderHelmet()}
                    <div class="d-flex flex-column flex-root">

                        <div class="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">

                            <div class="login-aside order-2 order-lg-1 d-flex flex-row-auto position-relative overflow-hidden">

                                <div class="d-flex md:flex-column-fluid flex-column py-9 px-5 py-lg-13 px-lg-15">

                                    <a href="#" class="text-center pt-2">
                                        <img src={process.env.PUBLIC_URL + "/images/logos/dark_transparent.png"} class="max-h-75px" alt="" />
                                    </a>

                                    <div class="d-flex h-full flex-column justify-center flex-center">

                                        <div class="login-form login-signin py-11">

                                            {
                                                this.state.emailSent ?
                                                    <>
                                                        <div class="text-center pb-8 flex flex-col max-w-screen">
                                                            <h2 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Login email sent</h2>
                                                            <span class="text-muted font-weight-bold font-size-h4">Follow the link sent to your email to sign in. If you can't see the email in your inbox, check the spam or junk folder.</span>

                                                        </div>
                                                    </> :
                                                    <form onSubmit={(e) => { this.handleSubmit(e); }} class="form" novalidate="novalidate" id="kt_login_signin_form">

                                                        <div class="text-center pb-8">
                                                            <h2 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Sign In</h2>
                                                            <span class="text-muted font-weight-bold font-size-h4">Please provide your email used to sign in below.</span>

                                                        </div>

                                                        <div class="form-group">
                                                            <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
                                                            <input value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }); }} required class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="email" name="username" autocomplete="on" />
                                                        </div>
                                                        <div class="fv-plugins-message-container"><div data-field="username" data-validator="notEmpty" class="fv-help-block">{this.state.errorMessage}</div></div>

                                                        <div class="text-center pt-2">
                                                            <button disabled={this.state.logging_in} id="kt_login_signin_submit" class="btn btn-dark font-weight-bolder font-size-h6 px-8 py-4 my-3">Send Login Link</button>
                                                        </div>

                                                    </form>
                                            }

                                        </div>


                                    </div>



                                </div>

                            </div>

                            <div class="content order-1 order-lg-2 d-flex flex-column w-100 pb-0" style={{ backgroundColor: "#B1DCED" }}>

                                <div class="d-flex flex-column justify-content-center text-center pt-lg-40 pt-md-5 pt-sm-5 px-lg-0 pt-5 px-7">
                                    <h3 class="display4 font-weight-bolder my-7 text-dark" style={{ color: "#986923" }}>Loan Gateway</h3>
                                    <p class="font-weight-bolder font-size-h2-md font-size-lg text-dark opacity-70">Fact Find, Verification of Identity & Document Collection made easy!
                                        <br />Made by brokers, for brokers.</p>
                                </div>

                                {/* Background Image style="background-image: url(assets/media/svg/illustrations/login-visual-2.svg);" */}
                                <div class="login-image content-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center" ></div>

                            </div>

                        </div>

                    </div>
                </body>
            );
        }

    }

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ClientLogin);;