import React from 'react';
import {connect} from 'react-redux'


class DatePicker extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            value:{}
        }
      }
    componentDidMount() {

    }

    componentDidUpdate() {
        if(this.props.value == undefined){
            console.log('TRUE')
            this.props.dateChange({day:"",month:"",year:""},this.props.applicant_number,null,this.props.field,this.props.objectKey)
            this.setState({value:this.props.value})
        }

    }


    onChange = (value,type) =>{
        const property = {...this.state.value};
        property[type] = value;
        this.props.dateChange(value,this.props.applicant_number,type,this.props.field,this.props.objectKey)
        this.setState({value:property});
    }



    renderDays = () =>{
        const days = [];
        for (let index = 1; index <= 31; index++) {
           days.push(<option>{index}</option>)
        
        }
        return(
            <select required={this.props.required ? true : false} value = {this.props.value.day} onChange={(e) =>{this.onChange(e.target.value,"day")}} style={{width:"30%"}} className = "ui dropdown form-dropdown">
                <option value="" disabled selected hidden></option>
                {days}
            </select>
            )
    }

    renderMonths = () =>{
        const months = ['January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December']

        var month_options = []
        months.forEach(month =>{
            month_options.push(<option>{month}</option>)
        })
        return(
            <select required={this.props.required ? true : false} value = {this.props.value.month} onChange={(e) =>{this.onChange(e.target.value,"month")}} style={{width:"30%"}} className = "ui dropdown form-dropdown">
                <option value="" disabled selected hidden></option>
                {month_options}
            </select>
        )
    }

    renderYears = () =>{
        var current_year = new Date().getFullYear();
        if(this.props.future == true){
            current_year = current_year + 20;
        }
        var years = [];
        for (let index = current_year; index > 1900; index--) {
            years.push(<option>{index}</option>)
            
        }
        return(
            <select required={this.props.required ? true : false} value = {this.props.value.year} onChange={(e) =>{this.onChange(e.target.value,"year")}} style={{width:"30%"}} className = "ui dropdown form-dropdown">
                <option value="" disabled selected hidden></option>
                {years}
            </select>
        )
    }

    render(){
        if(this.props.value != undefined){
            return(
            <div>
                {this.renderDays()}
                {this.renderMonths()}
                {this.renderYears()}
            </div>

            )
        }
        return(<div></div>
        )
    }


}

const mapStateToProps = (state) =>{
  return {data:state.data.General_Details}
}

export default connect(mapStateToProps,{})(DatePicker)
