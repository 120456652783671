import React from 'react';
import {connect} from 'react-redux'


class HeldByDropDown extends React.Component{
    constructor(props){
        super(props)
        this.state = {
        }
      }
    componentDidMount() {
        console.log(this.props)
    }

    changeMenu = (menu) =>{
        this.setState({menu:menu})
    }


    renderDropDown = () =>{
        const options = []
        var names = [""]
        if('applicant1' in this.props.data){
            names.push(`${this.props.data.applicant1.given_name} ${this.props.data.applicant1.family_name}`)
        }

        if('applicant2' in this.props.data && this.props.data.applicant2.given_name != "" && this.props.data.applicant2.given_name != undefined && this.props.data.applicant2.family_name != undefined && this.props.data.applicant2.family_name != ""){
            names.push(`${this.props.data.applicant2.given_name} ${this.props.data.applicant2.family_name}`)
            
            if(this.props.joint == true){
                names.push("Jointly Held")
            }
        }
        if(this.props.value == "" || this.props.value == undefined){
            this.setOldValue(names);
        }

        console.log(this.props.value,names)
        names.forEach(name =>{
            options.push(
                <option defaultValue={"Test"} value={name}>{name}</option>
            )
        })
        return(
            <select value={this.props.value} onChange={(e) =>{this.props.changeFunction(e.target.value,this.props.keys.key,this.props.keys.type)}} required={this.props.required ? true:false} className="ui dropdown form-dropdown">
                {options}
            </select>
        )
    }

    setOldValue = (names,old) =>{
        if(names.length ==2){
            // Only 1 Option
            this.props.changeFunction(names[1],this.props.keys.key,this.props.keys.type);
        }
        else if(this.props.oldValues.app1 && this.props.oldValues.app2 && names.length ==3){
            this.props.changeFunction(names[3],this.props.keys.key,this.props.keys.type);
            
            // Both True
        }else if(this.props.oldValues.app1){
            // AP1 TRUE
            this.props.changeFunction(names[1],this.props.keys.key,this.props.keys.type);
        }else if(this.props.oldValues.app2){
            this.props.changeFunction(names[2],this.props.keys.key,this.props.keys.type);
            // APP2 TRUE
        }
    }


    render(){
        return(
            <div style={{minWidth:"100px",fontSize:"0.8em"}}>
                {this.renderDropDown()}
            </div>
        )
    }


}

const mapStateToProps = (state) =>{
  return {data:state.data.General_Details}
}

export default connect(mapStateToProps,{})(HeldByDropDown)
