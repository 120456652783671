import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editClient } from '../../actions';

import $ from "jquery";
import 'bootstrap';


export class EditClientModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            mobile: "",
            firebase_id: ""
        };
    }

    componentDidMount() {

    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.client != this.props.client) {
            this.setState({
                name: this.props.client.name,
                email: this.props.client.email,
                mobile: this.props.client.mobile,
                firebase_id: this.props.client.firebase_id
            });
        }
    }
    formSubmit = async (e) => {
        e.preventDefault();
        this.props.editClient(this.state, this.props.client_id);
        this.setState({
            name: "",
            email: "",
            mobile: "",
            firebase_id: ""
        });
        window.$("#EditClientModal").modal("hide");
        window.$('.modal-backdrop').remove();
    };

    render() {
        return (
            <div>
                <div class="modal fade" id="EditClientModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog " role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Edit Client</h5>
                                <button onClick={() => { window.$("#EditClientModal").modal("hide"); window.$('.modal-backdrop').remove(); }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <i aria-hidden="true" class="ki ki-close"></i>
                                </button>
                            </div>
                            <form onSubmit={(e) => { this.formSubmit(e); }}>
                                <div class="modal-body">
                                    <div>
                                        <div class="form-group">
                                            <label>Client Name<span class="text-danger">*</span></label>
                                            <input required value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }); }} type="text" class="form-control" placeholder="Client Name" />

                                        </div>

                                        <div class="form-group">
                                            <label>Client Mobile<span class="text-danger">*</span></label>
                                            <input required value={this.state.mobile} onChange={(e) => { this.setState({ mobile: e.target.value }); }} type="text" class="form-control" placeholder="Client Mobile" />
                                        </div>

                                        <div class="form-group">
                                            <label>Client Email<span class="text-danger">*</span></label>
                                            <input value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }); }} type="email" class="form-control" placeholder="Client Email" />
                                        </div>
                                    </div>


                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal" onClick={() => { window.$("#EditClientModal").modal("hide"); window.$('.modal-backdrop').remove(); }}>Close</button>
                                    <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal" onClick={() => {
                                        this.props.moveClient();
                                        window.$("#EditClientModal").modal("hide");
                                    }}>Move Client</button>
                                    <button type="submit" class="btn btn-primary font-weight-bold">Edit Client</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = {
    editClient
};

export default connect(mapStateToProps, mapDispatchToProps)(EditClientModal);
