import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteClient } from '../../actions'

import $ from "jquery";
import 'bootstrap'


export class DeleteClientModal extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             name:"",
             email:"",
             mobile:""
        }
    }

    formSubmit = async (e) =>{
        e.preventDefault()
        console.log(this.props.client)
        this.props.deleteClient(this.props.client.id)
        window.$("#DeleteClientModal").modal("hide")
        window.$('.modal-backdrop').remove()
    }
    
    render() {
        return (
            <div>
            <div class="modal fade" id="DeleteClientModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered " role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Delete Client</h5>
                            <button onClick={() => { window.$("#DeleteClientModal").modal("hide"); window.$('.modal-backdrop').remove(); }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <i aria-hidden="true" class="ki ki-close"></i>
                            </button>
                        </div>
                        <form onSubmit={(e) =>{this.formSubmit(e)}}>
                        <div class="modal-body">
                            <p>Are you sure you wish to remove <strong>{this.props.client.name}</strong> from this application?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal" onClick={() => { window.$("#DeleteClientModal").modal("hide"); window.$('.modal-backdrop').remove(); }}>Close</button>
                            <button type="submit" class="btn btn-danger font-weight-bold">Remove Client</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user:state.user
})

const mapDispatchToProps = {
    deleteClient
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteClientModal)
