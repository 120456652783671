import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dashboard from '../newdashboard/Dashboard'

import Nav from '../Nav'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'


export class Security extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Security - Mortgage Choice Sydney CBD</title>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" integrity="sha512-8bHTC73gkZ7rZ7vpqUQThUDhqcNFyYi2xgDgPDHc+GXVGHXq+xPjynxIopALmOPqzo9JZj0k6OqqewdGO3EsrQ==" crossorigin="anonymous" />
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/components/grid.min.css" integrity="sha512-RtktBL0Hhw9K2e2rGLZGadK8cF6Q0wKxRUkfh4pJszwnarmh3GbqHCiLm3UZFA1dRiFqtPkVrcby0hXWsqoDNA==" crossorigin="anonymous" />
                    <link rel="stylesheet" href={process.env.PUBLIC_URL + `/css/style.css`} />

                </Helmet>
                {/* <Nav showProgress="false"></Nav> */}

                <div>
                    <section className="main-wrap standalone-container">
                        <div>
                            <h1>Security – Is this safe?</h1>
                            <p>Loan Gateway is a cloud based, automated platform for collecting information, files and identification documents from customers.

                                Loan Gateway is all about using online tools to help manage client information. These alternatives to email and local data storage streamline the way we collect, store and share documents. They reduce handling errors, keep your data safe and compliant, and improve your overall productivity.

                                And in this world, security is paramount.</p>
                            <h1>Did you know?</h1>
                            <p>Most cyber attacks start via phishing email. Your company email is one of the weak points in cyber security, and having sensitive documents contained in your employee’s email inboxes is a huge risk and potential for data breach. Loan Gateway moves your document collection out of the inbox to an independent safe and secure platform that protects your data and keeps your reputation intact.</p>
                            <p>Our developers work hard to ensure that Loan Gateway remains safe and reliable place for our customers data. Multiple types of security practices and technologies are deployed to help ensure consistency and integrity of the platform.</p>

                            <h1>Data Centres</h1>
                            <p>Loan Gateway is deployed via MongoDB & Google Cloud Services. We have carefully selected they data centres as they are at the forefront of technology, innovation and most importantly, security.</p>
                            <h1>Secure Architecture</h1>
                            <p>Loan Gateway was designed with security as it’s core. The architecture we have deployed is based on best practise development principals separating file and associated meta data and keeping environment data separate and encrypted during transit and at rest.</p>
                            <h1>Encryption in Transit</h1>
                            <p>All http calls are encrypted with HTTPS SSL at the transport layer.</p>
                            <h1>Encryption in Storage</h1>
                            <p>Stored data such as relational databases, file stores and database backups are encrypted using multiple types of encryptions. Loan Gateway employs both container and object encryption for both files and database entries.</p>
                            <p>Each customer’s data is hosted in our shared infrastructure and logically separated from other customers’ data. We use a combination of storage technologies to ensure customer data is protected from hardware failures and returns quickly when requested.</p>
                            <h1>Access Control Authentication</h1>
                            <p>To further reduce the risk of unauthorised access to data, Loan Gateway employs multi-factor authentication for all access to systems with highly classified data, including our production environment, which houses our customer data.  We use private keys for authentication.</p>
                            <h1>Data & Privacy</h1>
                            <p>All information submitted to Loan Gateway is deemed as private and treated as such with the same measures and standards of security whether it was public or non-public</p>
                            <h1>Disclosure</h1>
                            <p>Private information will never be voluntarily disclosed to third parties unless required to do so by law, or as outlined under our <Link to="/privacy">Privacy Policy</Link></p>
                            <p>Personal information, data, and files uploaded to Loan Gateway using our services remains the property of the original owner. We will not use this data for our own purposes.</p>
                            <h1>Data Retention & Disposal</h1>
                            <p>Our hosting providers are responsible for ensuring removal of data from disks is performed in a responsible manner before they are repurposed.</p>
                            <h1>Questions</h1>
                            <p>Please reach out to us directly should you have any additional queries relating to the integrity of this platform.</p>



                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Security)