import update from 'immutability-helper';
const INIT_STATE = {
}


export default (state = INIT_STATE,action) => {
    switch(action.type){
      case 'SET_INITIAL_DATA':
        return action.payload
      default:
        return state
    }
  }
  