import React, { Component } from 'react'
import { connect } from 'react-redux'
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
export class AgentRow extends Component {


    renderRole = () =>{



        var color = "primary";

        if(this.props.role === "admin"){
            color = "primary";
        }else if(this.props.role === "agent"){
            color = "success";
        }

        return (
            <span class={"label label-lg font-weight-bold  label-light-"+color+" label-inline"}>
                {capitalizeFirstLetter(this.props.role)}
            </span>
        )
    }

    renderColor = () =>{
            
            var color = "primary";
    
            if(this.props.role === "admin"){
                color = "primary";
            }else if(this.props.role === "agent"){
                color = "success";
            }
    
            return color;   
    }
  render() {
    return (
        <tr>
        <td>
            <span style={{ width: "250px" }}>
                <div class="d-flex align-items-center">	
                    <div class={`symbol symbol-40 symbol-light-${this.renderColor()} flex-shrink-`}>
                        <span class="symbol-label font-size-h4 font-weight-bold">{this.props.name[0]}</span>	
                    </div>								
                    
                    <div class="ml-4">									
                        <div class="text-dark-75 font-weight-bolder font-size-lg mb-0">{this.props.name}</div>		
                        <a href="#" class="text-muted font-weight-bold text-hover-primary">{this.props.email}</a>			
                    </div>						
                </div>
            </span>
        </td>
        <td>
            {this.renderRole()}
        </td>

        <td>
          <div className=''>
            <button href="#" class="btn btn-icon btn-success">
                <i class="flaticon-edit"></i>
            </button>

            <button href="#" class="btn btn-icon btn-danger ml-2">
                <i class="flaticon-delete"></i>
            </button>
          </div>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AgentRow)