import React from 'react';
import {connect} from 'react-redux'
import {updateData} from '../../actions'
import HeldByDropDown from './form_elements/HeldByDropDown.js'
import CurrencyInput from 'react-currency-input-field';


class Liabilities_Debts extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      loans:{
      },
      credit_cards:{
      }

      }
  }
  
  componentDidMount() {
    console.log(this.state)
    window.scrollTo(0, 0)
    if('Liabilities_Debts_Details' in this.props.data){
      const property = {...this.state};
      Object.keys(this.props.data['Liabilities_Debts_Details']).map(key =>{
        property[key] = this.props.data['Liabilities_Debts_Details'][key];
      })
      this.setState(property)
    }
  }

    componentDidUpdate(prevProps,prevState){
      clearTimeout(prevState.timeoutID)
      this.updateFields()
    }

    formChange = (e) =>{
      const update = async () =>{
        this.props.updateData({state:this.state,type:"Liabilities_Debts_Details"})
      }
      const timeoutID = setTimeout(function() {
        update()
      },250)
      this.setState({timeoutID:timeoutID})
    }

    updateFields = () =>{

      // Update Credit Cards
      Object.keys(this.state.credit_cards).forEach(key =>{
        var property = {...this.state.credit_cards}
        console.log(this.state.credit_cards[key].payout)
        if((this.state.credit_cards[key] != undefined && this.state.credit_cards[key].payout !== "") && (this.state.credit_cards[key].payout == false || this.state.credit_cards[key].payout == true) ){
          if(this.state.credit_cards[key].payout == true ){
            property[key].payout = "Yes"
          }else if(this.state.credit_cards[key].payout == false){
            property[key].payout = "No"
          }
          this.setState({credit_cards:property})
        } 
        })

              // Update Loans
      Object.keys(this.state.loans).forEach(key =>{
        
        var property = {...this.state.loans}
        if((this.state.loans[key] != undefined && this.state.loans[key].payout !== "") && (this.state.loans[key].payout == false || this.state.loans[key].payout == true )){
          if(this.state.loans[key].payout == true ){
            property[key].payout = "Yes"
          }else if(this.state.loans[key].payout == false){
            property[key].payout = "No"
          }
          this.setState({loans:property})
        } 
        })
    }


    handleLoanChange = (e,field,loan_no,type,currency) =>{
      var value = 0;
      if(currency){
        value = e;
      }else{
        value = e.target.value
      }
        const property = {...this.state[type]}
        property[loan_no][field] = value
        this.setState({[type]:property})

    }
    
    handlePayout = (e,loan_no,type) =>{
      const property = {...this.state[type]}
      if(property[loan_no].payout == true){
        property[loan_no].payout = false;
      }else{
        property[loan_no].payout = true;
      }
      this.setState({[type]:property})
    }

    handleSubmit = (e) =>{
      e.preventDefault()
      this.props.updateData({state:this.state,type:"Liabilities_Debts_Details"})
      this.props.changeForm("Assets")
    }

    handleHeldByChange = (e,field,key,type) =>{
      const property = {...this.state[type]}
      if(property[key][field] == true){
        property[key][field] = false;
      }else{
        property[key][field] = true;
      }
      this.setState({[type]:property})
    }

    handleDropDownChange = (value,key,type) =>{
      const property = {...this.state[type]};
      property[key].held_by = value
      this.setState({[type]:property});
    }



    renderLoans = () =>{
      var keys = []
      var loans = []
      Object.keys(this.state.loans).map(key =>{
          keys.push(key)
      })
      keys.forEach(key =>{
           loans.push(            
          <tr>
          <td><input type="text" value={this.state.loans[key].bank || ''} onChange={(e) =>this.handleLoanChange(e,'bank',key,"loans")} name="" defaultValue="" /></td>
          <td>
            <div class="flex-input">
              <CurrencyInput prefix="$" decimalsLimit={0} value={this.state.loans[key].owing || ''} onValueChange={(value, name) => this.handleLoanChange(value,'owing',key,"loans",true)}  name="" defaultValue={0} />
            </div>
          </td>
          <td>
            <div class="flex-input">
              <CurrencyInput prefix="$" decimalsLimit={0} value={this.state.loans[key].repayment || ''} onValueChange={(value, name) => this.handleLoanChange(value,'repayment',key,"loans",true)}  name="" defaultValue={0} />
            </div>
          </td>
          <td>
            <div class="flex-input" data-tooltip="Does your loan have a credit limit that you can withdraw funds from? If no, leave blank">
              <CurrencyInput prefix="$"  decimalsLimit={0} value={this.state.loans[key].limit || ''} onValueChange={(value, name) => this.handleLoanChange(value,'limit',key,"loans",true)}  name="" defaultValue={0} />
            </div>
          </td>

        
          <td>
            <HeldByDropDown oldValues={{app1:this.state.loans[key].held_by_app1,app2:this.state.loans[key].held_by_app2}} keys = {{key:key,type:"loans"}} value={this.state.loans[key].held_by} changeFunction = {this.handleDropDownChange} joint={true} required={true} ></HeldByDropDown>
          </td>
          
          {/* <td class="text-center"><input type="radio" onClick={(e) => this.handlePayout(e,key,"loans")} checked={this.state.loans[key].payout === true} type="radio" defaultValue="true" /></td>
           */}
          <td data-tooltip="Are you intending to use the new loan proceeds to clear this facility?">
            <select  required value={this.state.loans[key].payout || ""} onChange={(e) =>this.handleLoanChange(e,'payout',key,"loans")} className="ui dropdown form-dropdown" style={{"minWidth":"75px"}}>
              <option></option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </td>

          <td class="text-center"><button className="ui negative small icon button" type="button" onClick={(e) =>{this.deleteField(key,"loans")}}><i class="trash alternate outline icon"></i></button></td>

          </tr>
          )
      })
      return loans;
    }

    renderLoanSpan = () =>{
      var span = 0
      Object.keys(this.state).map(key =>{
        if(key.startsWith("loan")){
          span +=1;
        }
      })
      return span +=2
    }

    renderBanks = () =>{
      var keys = []
      var credit_cards = []
      Object.keys(this.state.credit_cards).map(key =>{
          keys.push(key)
      })
      keys.forEach(key =>{
        credit_cards.push(            
          <tr>
          <td><input type="text" value={this.state.credit_cards[key].bank || ''} onChange={(e) =>this.handleLoanChange(e,'bank',key,'credit_cards')} name="" defaultValue="" /></td>
          <td>
            <div class="flex-input">
              <CurrencyInput prefix="$" decimalsLimit={0} value={this.state.credit_cards[key].owing || ''} onValueChange={(value, name) => this.handleLoanChange(value,'owing',key,"credit_cards",true)}  name="" defaultValue={0} />            
            </div>
          </td>

          <td>
            <div class="flex-input ui visible">
              <CurrencyInput prefix="$" decimalsLimit={0} value={this.state.credit_cards[key].limit || ''} onValueChange={(value, name) => this.handleLoanChange(value,'limit',key,"credit_cards",true)}  name="" defaultValue={0} /> 
            </div>
          </td>

          <td>
            <HeldByDropDown oldValues={{app1:this.state.credit_cards[key].held_by_app1,app2:this.state.credit_cards[key].held_by_app2}} keys = {{key:key,type:"credit_cards"}} value={this.state.credit_cards[key].held_by} changeFunction = {this.handleDropDownChange} joint={true} required={true} ></HeldByDropDown>
          </td>

          {/* <td class="text-center"><input type="radio" onClick={(e) => this.handlePayout(e,key,'credit_cards')} checked={this.state.credit_cards[key].payout === true} /></td> */}
          <td data-tooltip="Are you intending to use the new loan proceeds to clear this facility?">
            <select required value={this.state.credit_cards[key].payout || ""} onChange={(e) =>this.handleLoanChange(e,'payout',key,"credit_cards")} className="ui dropdown form-dropdown" style={{"minWidth":"75px"}}>
              <option></option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </td>
          
          <td class="text-center"><button className="ui negative small icon button" type="button" onClick={(e) =>{this.deleteField(key,"credit_cards")}}><i class="trash alternate outline icon"></i></button></td>
          </tr>
          )
      })
      return credit_cards;
    }

    renderBankSpan = () =>{
      var span = 0
      Object.keys(this.state).map(key =>{
        if(key.startsWith("credit_card")){
          span +=1;
        }
      })
      return span +=2
    }

    renderButtons = () =>{
      if(this.props.showButtons == 'false'){
        return(null)
      }else{
        return(
          <div className="">
          <button className="ui button primary " onClick={(e) =>{this.props.changeForm("Income")}}>Previous Page</button>
          <button className="ui button primary " type="submit" style={{float:"right"}}>Next Page</button>
        </div>
        )
      }
    }
    addNewLoan = () =>{
      var highestKey = 0
      Object.keys(this.state.loans).map(key =>{
          if(key > highestKey){
            highestKey = key;
          }
      })
      console.log(highestKey)
      highestKey +=1;
      var loansProperty = {...this.state.loans}
      loansProperty[highestKey] = {
        variable:highestKey,
        bank:"",
        owing:"",
        repayment:"",
        limit:"",
        held_by:"",
        payout:""
      }

      this.setState({loans:loansProperty})

    }

    addNewCreditCard = () =>{
      var highestKey = 0
      Object.keys(this.state.credit_cards).map(key =>{
          if(key > highestKey){
            highestKey = key;
          }
      })
      highestKey +=1;
      var creditCardsProperty = {...this.state.credit_cards}
      creditCardsProperty[highestKey] = {
        variable:highestKey,
        bank:"",
        owing:"",
        repayment:"",
        limit:"",
        held_by:"",
        payout:""
      }

      this.setState({credit_cards:creditCardsProperty})

    }

    deleteField = (deleteKey,type) =>{
      var property = {...this.state[type]}
      delete property[deleteKey]
      this.setState({[type]:property})
      this.props.updateData({state:this.state,type:"Liabilities_Debts_Details"})
      
    }


    render(){
        return(
            <div>
              <form onSubmit = {(e) =>{this.handleSubmit(e)}} onChange = {this.formChange}>
          <table class="table3 mb1">
          <thead>
            <tr>
              <th colspan="10">Personal Loans, Car Loans, HECS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td >Bank/Institution</td>
              <td >Amount owing</td>
              <td >Monthly repayment</td>
              <td >Limit</td>
              <td >Held by</td>
              <td >Payout / close?</td>
              <td ></td>
            </tr>
            {this.renderLoans()}
            <button className="ui button add-field" type="button" onClick={(e) =>{this.addNewLoan()}}><i class="plus circle icon"></i>Add Loan</button>

          </tbody>
        </table>

        <table class="table3 mt-15">
        <thead>
            <tr>
              <th colspan="10">Credit Cards</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Bank/Institution</td>
              <td >Amount owing</td>
              <td >Limit</td>
              <td>Held by</td>
              <td >Payout / close?</td>
              <td ></td>
            </tr>
            {this.renderBanks()}
            <button className="ui button add-field" type="button" onClick={(e) =>{this.addNewCreditCard()}}><i class="plus circle icon"></i>Add Credit Card</button>

          </tbody>
        </table>
            {this.renderButtons()}
            </form>
            </div>
        )

    }
}


const mapStateToProps = (state) =>{
  return {data:state.data}
}

export default connect(mapStateToProps,{updateData})(Liabilities_Debts)