import React from 'react';
import {connect} from 'react-redux'
import {updateData} from '../../actions'
import firebase from '../Firebase.js'

import {Link} from "react-router-dom"



class Header extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            date:"",
            hear_about:""
        }
      }

    componentDidMount() {
        if('Header' in this.props.data){
          const property = {...this.state};
          Object.keys(this.props.data['Header']).map(key =>{
            property[key] = this.props.data['Header'][key];
          })
          this.setState(property)
        }
        this.checkAuth()
      }

      checkAuth = () =>{
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              // User is signed in.
            } else {
                const today = new Date()
                this.setState({date:today.toLocaleDateString()})
                this.props.updateData({state:this.state,type:"Header"})

              // No user is signed in.
            }
          });
    }
    
        componentDidUpdate(prevProps,prevState){
          clearTimeout(prevState.timeoutID)
        }
    
        formChange = (e) =>{
          const update = async () =>{
            this.props.updateData({state:this.state,type:"Header"})
          }
          const timeoutID = setTimeout(function() {
            update()
          },250)
          this.setState({timeoutID:timeoutID})
        }

        renderEntryForm = () =>{
          if(this.props.show == "true"){
            return(
            <div class="entry-field">
              <div class="lableBox">
              <label for="">Date</label>
              <input style={{backgroundColor:"#f5fbfc",cursor:"not-allowed"}} disabled value={this.state.date || ""} type="text" name="" defaultValue="" />
              </div>

              <div class="lableBox">
                      <label for="">How did you hear about us?</label>
                      <input style={{backgroundColor:"#f5fbfc"}} type="text" value={this.state.hear_about || ""}onChange={(e) =>{this.setState({hear_about:e.target.value})}} name="" defaultValue="" />
                    </div>
          </div>
            )
          }else if (this.props.show == "admin"){
            return(
            <div className="entry-field">
                      <div class="lableBox">
                        <label for="">How did you hear about us?</label>
                        <input style={{backgroundColor:"#f5fbfc"}} type="text" value={this.state.hear_about || ""}onChange={(e) =>{this.setState({hear_about:e.target.value})}} name="" defaultValue="" />
                    </div>
            </div>)
          }else{
            return(null)
          }
        }
    render(){

        return(
            <div>
                <form onChange = {this.formChange} style={{paddingBottom:"0px"}}>

                    {this.renderEntryForm()}
                </form>
            </div>
        )

    }
}


const mapStateToProps = (state) =>{
    return {data:state.data}
  }

export default connect(mapStateToProps,{updateData})(Header)