import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchEvents, setLastEvent } from '../../../actions';
import Cookies from 'universal-cookie';
import moment from "moment";
import history from '../../../history';




export class Events extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            interval: null,
            items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'],
        };

        this.ref = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.getEvents();
        this.refreshEvents();
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        var interval = this.state.interval;
        if (interval != null) {
            clearInterval(interval);
            this.setState({ interval: null });
        }
    }

    getEvents = async () => {
        if (this.props.auth != null) {
            this.props.fetchEvents();
        }
    };

    handleClickOutside(event) {
        if (this.ref && !this.ref.current.contains(event.target)) {
            this.setState({ show: false });
        }
    }




    renderEvents = () => {
        var events = [];


        this.props.events.events.forEach(event => {
            var badge_type = "danger";
            var icon = "fa-times";
            if (event.type == "fact_find") {
                badge_type = "success";
                icon = "fa-clipboard";
            } else if (event.type == "voi") {
                badge_type = "warning";
                icon = "fa-user";
            } else if (event.type == 'document_collection') {
                badge_type = "info";
                icon = "fa-file";
            } else if (event.type == 'bank_statements') {
                badge_type = "danger";
                icon = "fa-piggy-bank";
            }

            events.push(
                <div class="timeline-item cursor-pointer" onClick={() => {
                    history.push("/admin/application/" + event.application_id);
                    this.setState({ show: false });
                }}>

                    <div class={`timeline-media bg-light-${badge_type}`}>
                        <i class={`fa ${icon} text-${badge_type}`}></i>
                    </div>

                    <div class={`timeline-desc timeline-desc-light-${badge_type}`}>
                        <span class={`font-weight-bolder text-${badge_type}`}>{moment(event.date).fromNow()}</span>
                        <p class="font-weight-normal text-dark-50 pb-2">
                            <strong>{event.name}</strong> just {event.event}
                        </p>
                    </div>

                </div>
            );
        });

        return events.reverse();
    };

    refreshEvents = () => {
        const interval = setInterval(async () => {
            this.getEvents();
        }, 5000);
        this.setState({ interval: interval });
    };

    renderSymbol = () => {
        if (!this.props.events?.events || this.props.events.events.length == 0) {
            return null;
        }
        var event = this.props.events.events[this.props.events.events.length - 1];
        var flag = false;
        var last_event = this.props.events.last_event;
        if (last_event == null) {
            const cookies = new Cookies();
            var last_event_cookie = cookies.get('last_event');
            if (last_event_cookie != null) {
                last_event = last_event_cookie;
            }
        }
        if (event != null && last_event != null) {
            if (moment(event.date).isAfter(moment(last_event))) {
                flag = true;
            }
        } else if (last_event == null) {
            flag = true;
        }
        if (flag) {
            return (<i class="symbol-badge bg-danger"></i>);
        } else {
            return null;
        }
    };


    render() {
        return (
            <div className='dropdown' ref={this.ref}>
                <div class="topbar-item">
                    <div class="btn btn-icon  btn-clean btn-lg mr-1" onClick={() => {
                        if (this.state.show == false && this.props.events.events.length > 0) {
                            this.props.setLastEvent(moment());
                        }
                        this.setState({ show: !this.state.show });
                    }} id="kt_quick_panel_toggle">
                        <div className='symbol symbol-circle symbol-30'>
                            {this.renderSymbol()}
                            <i class="fa fa-bell"></i>
                        </div>

                    </div>


                </div>

                {this.state.show == true ?
                    <div style={{
                        position: "absolute",
                        top: "65px",
                        right: "0",
                        left: "-295px",
                        maxHeight: "700px",
                        overflowY: "scroll",
                        // transform: "translate3d(-292px, 65px, 0px)"
                    }} className='dropdown-menu p-5 m-0 dropdown-menu-lg show feed-dropdown scroll scroll-pull' data-scroll="true" data-wheel-propagation="true">
                        <div class="timeline timeline-5 mt-3">
                            <div className='timeline-items'>
                                {this.renderEvents()}
                            </div>

                        </div>

                    </div> : null}

            </div>
        );

    }
}

const mapStateToProps = (state) => ({
    events: state.events,
    auth: state.auth
});

const mapDispatchToProps = {
    fetchEvents,
    setLastEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);