import React from 'react';
import { connect } from 'react-redux';

import Header from './form/Header';
import General_Details from './form/General_Details';
import Address_3y from './form/Address_3_year_history';
import Employment_Details from './form/Employment_Details';
import Income from './form/Income';
import Liabilities_Debts from './form/Liabilities_Debts';
import Assets from './form/Assets';
import Loan_Requirements from './form/Loan_Requirements';
import { fetchAdminForm, fetchVersion } from '../actions';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import $ from "jquery";
import firebase from './Firebase.js';

import { Helmet } from "react-helmet";
import history from '../history';


class AdminForm extends React.Component {

  checkAuth = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log(this.props.match);
        if (this.props.versions) {
          this.props.fetchVersion(this.props.match.params.application_id, this.props.match.params.version_id, this.props.match.params.form_id);
        } else {
          this.props.fetchAdminForm(this.props.match.params.id);
        }

        // User is signed in.
      } else {
        history.push("/login");
        // No user is signed in.

      }
    });
  };

  componentDidMount() {
    this.checkAuth();
    // if (this.props.versions) {
    //   // this.props.fetchVersion(this.props.match.params.version_id,this.props.match.params.form_id)
    // } else {
    //   this.props.fetchAdminForm(this.props.match.params.id);
    // }



  }

  constructor(props) {
    super(props);
    this.state = {
      form: "General_Details",
      buttons_disabled: false

    };
  }


  componentDidUpdate() {

    $("document").ready(() => {
      $('button').remove();
      $('.educational-links').remove();
      $('input').prop('disabled', true);
      $('select').prop('disabled', true);
      $('textarea').prop('disabled', true);
    });
  }

  changeForm = (form) => {
    this.setState({ form: form });
  };

  savePDF = () => {
    const input = document.querySelector("#root");
    html2canvas(input, { scale: "1" })
      .then((canvas) => {
        var imgData = canvas.toDataURL("image/png", 1);
        var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
        var pdfWidth = pdf.internal.pageSize.getWidth();
        var pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST');
        pdf.save("FactFind.pdf");
      });
  };

  renderURL = () => {
    if (this.props.versions) {
      return (`versions/${this.props.match.params.version_id}/${this.props.match.params.form_id}`);
    } else {
      return (`adminform/${this.props.match.params.id}`);
    }
  };

  renderDate = () => {
    if ('Header' in this.props.data && 'date' in this.props.data.Header) {
      return `Version Date: ${this.props.data.Header.date}`;
    }
  };

  renderContent = () => {
    if (this.props.id == 'no-form') {
      return ('No-form');
    } else if (this.props.id != null) {
      return (
        <div>
          <Helmet>
            <title>Admin Form - Mortgage Choice Sydney CBD</title>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" integrity="sha512-8bHTC73gkZ7rZ7vpqUQThUDhqcNFyYi2xgDgPDHc+GXVGHXq+xPjynxIopALmOPqzo9JZj0k6OqqewdGO3EsrQ==" crossorigin="anonymous" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/components/grid.min.css" integrity="sha512-RtktBL0Hhw9K2e2rGLZGadK8cF6Q0wKxRUkfh4pJszwnarmh3GbqHCiLm3UZFA1dRiFqtPkVrcby0hXWsqoDNA==" crossorigin="anonymous" />
            <link rel="stylesheet" href={process.env.PUBLIC_URL + `/css/style.css`} />
          </Helmet>
          <section className="main-wrap">
            <Header percent="15" changeForm={this.changeForm} show="admin"></Header>
            <General_Details showButtons="false" changeForm={this.changeForm}></General_Details>
            <Address_3y showButtons="false" changeForm={this.changeForm}></Address_3y>
            <Employment_Details showButtons="false" changeForm={this.changeForm}></Employment_Details>
            <Income showButtons="false" changeForm={this.changeForm}></Income>
            <Liabilities_Debts showButtons="false" changeForm={this.changeForm}></Liabilities_Debts>
            <Assets showButtons="false" changeForm={this.changeForm}></Assets>
            <Loan_Requirements showButtons="false" changeForm={this.changeForm}></Loan_Requirements>
            <span style={{ "float": "right" }}>{this.props.versions ? `Version Date: ${this.props.date}` : this.renderDate()}</span>

          </section>
        </div>
      );
    } else {
      return (<div className="form-loading">
        <Helmet>
          <title>Admin Form - Mortgage Choice Sydney CBD</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" integrity="sha512-8bHTC73gkZ7rZ7vpqUQThUDhqcNFyYi2xgDgPDHc+GXVGHXq+xPjynxIopALmOPqzo9JZj0k6OqqewdGO3EsrQ==" crossorigin="anonymous" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/components/grid.min.css" integrity="sha512-RtktBL0Hhw9K2e2rGLZGadK8cF6Q0wKxRUkfh4pJszwnarmh3GbqHCiLm3UZFA1dRiFqtPkVrcby0hXWsqoDNA==" crossorigin="anonymous" />
          <link rel="stylesheet" href={process.env.PUBLIC_URL + `/css/style.css`} />
        </Helmet>
        <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="" />
      </div>);
    }
  };
  render() {
    return (
      this.renderContent()
    );
  }
}

const mapStateToProps = (state) => {
  return { id: state.id, date: state.data.date, data: state.data };
};

export default connect(mapStateToProps, { fetchAdminForm, fetchVersion })(AdminForm);
