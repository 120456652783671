import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import Nav from './Nav'
import { fetchDocCollection, setID } from '../actions'
export class ClientBankStatements extends Component {

    constructor(props) {
        super(props)

        this.state = {
            active_applicant: null
        }
    }

    componentDidMount() {
        // this.fetchDocumentCollections()
        this.props.fetchDocCollection(this.props.match.params.id)
        this.props.setID(this.props.match.params.id)
    }


    renderApplicants = () => {
        const applicants = []
        if (this.props.bankstatements.data != null) {
            Object.keys(this.props.bankstatements.data.applicants).forEach(key => {
                // if(this.props.bankstatements.data.applicants[key].requested_documents != undefined){
                applicants.push(
                    <div className="dashboard-module file-upload-item">
                        <h2>{this.props.bankstatements.data.applicants[key].name}</h2>
                        <p style={{ "marginTop": "7px", marginBottom: "14px" }}>It would be preferred that you use this link to provide your statements to us securely and efficiently. This web link has been developed in conjunction with the banks purely for loan applications.</p>
                        <button onClick={() => {
                            this.setState({ active_applicant: key })
                        }} class="ui button dashboard-module-button" type="button">Start Bank Statement Collection</button>
                    </div>
                )
                // }
            })
        }
        return applicants;
    }

    renderContent = () => {
        if (this.state.active_applicant == null) {
            return this.renderApplicants()
        } else {
            var code = this.props.bankstatements.data.applicants[this.state.active_applicant].code

            return (
                <div style={{
                    width: "100%"
                }}>
                    <div className='bankstatement-info'>
                    <h1>Providing your income and expenses couldn't be easier! </h1>
<p>We've partnered with <a href="https://www.illion.com.au/">illion</a> to get your bank statements directly from your bank so you don't have to manually download these one by one! We collect 6 months of bank statements.</p>
<p><b> Your login details are never stored and are securely discarded once they have been encrypted and passed to the financial institution.</b></p>
<p><b>Please ensure you upload all your accounts (transaction accounts, savings accounts, loan accounts, credit card facilities, personal loan facilities etc.) even if they aren't used.</b></p>
<p><b>Should you have accounts with multiple institutions, simply complete the verification process with each institution.</b></p>
<p>This is a one-time service and only happens with your consent. To continue please agree to the below user terms/conditions and privacy policy.</p>

                    </div>
                    <div className='iframe-container'>
                        <iframe src={`https://bankstatements.com.au/iframe/start/FTVH-${code}`}></iframe>

                    </div>
                </div>
            )
            // var requested_files = []
            // Object.keys(this.props.document_collection.data.applicants[this.state.active_applicant].requested_documents).forEach(key =>{
            //     requested_files.push(<FileUploader data={this.props.document_collection.data.applicants[this.state.active_applicant].requested_documents[key]}  params = {this.props.match.params} applicant_id = {this.state.active_applicant}/>)
            // })
            // console.log(requested_files[0].props.data.index)
            // var sorted = requested_files.sort((a,b) =>(a.props.data.index > b.props.data.index) ? 1: -1);

            // return sorted

        }
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Fact Find - Mortgage Choice Sydney CBD</title>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" integrity="sha512-8bHTC73gkZ7rZ7vpqUQThUDhqcNFyYi2xgDgPDHc+GXVGHXq+xPjynxIopALmOPqzo9JZj0k6OqqewdGO3EsrQ==" crossorigin="anonymous" />
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/components/grid.min.css" integrity="sha512-RtktBL0Hhw9K2e2rGLZGadK8cF6Q0wKxRUkfh4pJszwnarmh3GbqHCiLm3UZFA1dRiFqtPkVrcby0hXWsqoDNA==" crossorigin="anonymous" />
                    <link rel="stylesheet" href={process.env.PUBLIC_URL + `/css/style.css`} />

                </Helmet>
                <Nav showProgress="false"></Nav>
                <section className="main-wrap">
                    <div className="file-upload-row">
                        {/* <FileUploader params = {this.props.match.params} /> */}

                        {
                            this.renderContent()
                        }





                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    bankstatements: state.bankstatements
})

const mapDispatchToProps = {
    fetchDocCollection, setID
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientBankStatements)