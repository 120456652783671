import React from 'react';
import {connect} from 'react-redux'
import {updateData} from '../../actions'
import HeldByDropDown from './form_elements/HeldByDropDown.js'

import CurrencyInput from 'react-currency-input-field';
import DatePicker from './form_elements/DatePicker.js'

class Assets extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      test:"Thomas Adam",
      properties:{
        
      },
      home_contents_value:"",
      savings:{
      },
      shares:{
      },
      supers:{

      },
      vehicles:{

      },
      assets:{

      }

    }

  }
  componentDidMount() {
    window.scrollTo(0, 0)
    if('Asset_Details' in this.props.data){
      const property = {...this.state};
      Object.keys(this.props.data['Asset_Details']).map(key =>{
        property[key] = this.props.data['Asset_Details'][key];
      })
      this.setState(property)
    }
  }

    componentDidUpdate(prevProps,prevState){
      this.updateFields()
      clearTimeout(prevState.timeoutID)
    }

    updateFields = () =>{

      // Update Held By;
      Object.keys(this.state.properties).forEach(key =>{
        var property = {...this.state.properties}
        if((this.state.properties[key] != undefined  && this.state.properties[key].payout !== "") && (this.state.properties[key].payout == false || this.state.properties[key].payout == true) ){
          if(this.state.properties[key].payout == true ){
            property[key].payout = "Yes"
          }else if(this.state.properties[key].payout == false){
            property[key].payout = "No"
          }
          this.setState({properties:property})
        } 
        })
    }

    formChange = (e) =>{
      const update = async () =>{
        this.props.updateData({state:this.state,type:"Asset_Details"})
      }
      const timeoutID = setTimeout(function() {
        update()
      },250)
      this.setState({timeoutID:timeoutID})
    }
    handleChange = (e,field,key,type,currency) =>{
      var value = 0;
      if(currency){
        value = e;
      }else{
        value = e.target.value;
      }
        const property = {...this.state[type]}
        property[key][field] = value
        this.setState({[type]:property})

    }
    
    handlePayout = (e,key,type) =>{
      const property = {...this.state[type]}
      if(property[key].payout == true){
        property[key].payout = false;
      }else{
        property[key].payout = true;
      }
      this.setState({[type]:property})
    }

    handleSubmit = (e) =>{
      e.preventDefault()
      this.props.updateData({state:this.state,type:"Asset_Details"})
      this.props.changeForm("Loan_Requirements")
    }

    handleHeldByChange = (e,field,key,type) =>{
      const property = {...this.state[type]}
      if(property[key][field] == true){
        property[key][field] = false;
      }else{
        property[key][field] = true;
      }
      this.setState({[type]:property})
    }

    handleDropDownChange = (value,key,type) =>{
      console.log(value)
      const property = {...this.state[type]};
      property[key].held_by = value
      this.setState({[type]:property});
    }

    renderProperties = () =>{
      var keys = []
      var properties = []
      Object.keys(this.state.properties).map(key =>{
          keys.push(key)
      })
      keys.forEach((key,index) =>{
        properties.push(            
          <tr>
            <td colspan="8"><input type="text" placeholder="Address" value={this.state.properties[key].address || ''} onChange={(e) =>this.handleChange(e,'address',key,"properties")} name="" defaultValue="" /></td>
            <td class="text-center" rowSpan="2"><button className="ui negative small icon button" type="button" onClick={(e) =>{this.deleteField(key,"properties")}}><i class="trash alternate outline icon"></i></button></td>
      
          </tr>
            )
            properties.push(
            <tr>

            <td>
              <select className="ui dropdown form-dropdown" value = {this.state.properties[key].investment || ''} onChange={(e) =>this.handleChange(e,'investment',key,"properties")}>
                <option defaultValue = ""></option>
                <option defaultValue = "Owner Occupied">Owner Occupied</option>
                <option defaultValue = "Investment Property">Investment Property</option>
              </select> 
              </td>
              <td>
                <div class="flex-input">
                  <CurrencyInput style={{"minWidth":"100px"}} prefix="$" decimalsLimit={0} value={this.state.properties[key].value || ''}  onValueChange={(value, name) => this.handleChange(value,'value',key,"properties",true)}  name="" defaultValue={0} />                
                </div>
              </td>
              <td><input type="text" name="" value={this.state.properties[key].lender || ''} onChange={(e) =>this.handleChange(e,'lender',key,"properties")} defaultValue="" /></td>
              <td>
                <div class="flex-input">
                  <CurrencyInput prefix="$" decimalsLimit={0} value={this.state.properties[key].owing || ''}  onValueChange={(value, name) => this.handleChange(value,'owing',key,"properties",true)}  name="" defaultValue={0} />                
                </div>
              </td>
              <td>
                <div class="flex-input">
                  <CurrencyInput prefix="$" decimalsLimit={0} value={this.state.properties[key].repayment || ''}  onValueChange={(value, name) => this.handleChange(value,'repayment',key,"properties",true)}  name="" defaultValue={0} />                

                </div>
              </td>

              <td>
                <div class="flex-input">
                  <CurrencyInput className={`${this.state.properties[key].investment == "Owner Occupied" ? `investment-disabled` : ""}`} disabled={this.state.properties[key].investment == "Owner Occupied" ? true :false} prefix="$" decimalsLimit={0} value={this.state.properties[key].rental_income || ''}  onValueChange={(value, name) => this.handleChange(value,'rental_income',key,"properties",true)}  name="" defaultValue={0} />                
                </div>
              </td>


              {/* <td class="text-center"><input onClick={(e) => this.handlePayout(e,key,"properties")} checked={this.state.properties.[key].payout === true} type="radio" defaultValue="true" /></td> */}
              <td data-tooltip="Are you intending to use the new loan proceeds to clear this facility?">
                <select value={this.state.properties[key].payout || ""} onChange={(e) =>this.handleChange(e,'payout',key,"properties")} className="ui dropdown form-dropdown" style={{"minWidth":"75px"}}>
                  <option></option>
                  <option>Yes</option>
                  <option>No</option>

                </select>
              </td>
              
              {/* <td class="text-center"><input checked={this.state.properties[key].held_by_app1 === true} onClick={(e) =>this.handleHeldByChange(e,'held_by_app1',key,"properties")} type="radio" defaultValue="true" /></td>
              <td class="text-center"><input type="radio" checked={this.state.properties[key].held_by_app2 === true} onClick={(e) =>this.handleHeldByChange(e,'held_by_app2',key,"properties")} type="radio" defaultValue="true" /></td> */}
              <td>
                <HeldByDropDown oldValues={{app1:this.state.properties[key].held_by_app1,app2:this.state.properties[key].held_by_app2}} keys = {{key:key,type:"properties"}} value={this.state.properties[key].held_by} changeFunction = {this.handleDropDownChange} joint={true} required={true} ></HeldByDropDown>
              </td>
            </tr>
          )
      })
      return properties;
    }

    addNewField = (type,object) =>{
      var highestKey = 0
      Object.keys(this.state[type]).map(key =>{
          if(key > highestKey){
            highestKey = key;
          }
      })
      highestKey +=1;
      var newField = {...this.state[type]}
      var object = {}
      if(type == 'properties'){
        object = {
          variable:highestKey,
          address:"",
          rental_income:"",
          value:"",
          lender:"",
          owing:"",
          repayment:"",
          investment:"",
          payout:"",
          held_by_app1:false,
          held_by_app2:false,
          held_by:""
        }
      }else if(type == 'savings'){
        object = {
          variable:highestKey,
          bank:"",
          savings_account_type:"",
          account_no:"",
          amount:"",
          held_by_app1:false,
          held_by_app2:false,
          held_by:""
        }
      }else if(type == 'shares'){
        object = {
          variable:highestKey,
          held_with:"",
          no:"",
          value:"",
          held_by_app1:false,
          held_by_app2:false,
          held_by:""
        }
      }else if(type == 'supers'){
        object = {
          variable:highestKey,
          company:"",
          value:"",
          salary_sacrifice:"",
          yes_amount:"",
          yes_percent:"",
          held_by_app1:false,
          held_by_app2:false,
          held_by:""
        }
      }else if(type == 'vehicles'){
        object = {
          variable:highestKey,
          make:"",
          year:"",
          value:"",
          leased_owned:"",
          // lease_expiry_day:"",
          // lease_expiry_month:"",
          // lease_expiry_year:"",
          held_by_app1:false,
          held_by_app2:false,
          held_by:""
        }
      }
      newField[highestKey] = object
      this.setState({[type]:newField})
    }

    deleteField = (deleteKey,type) =>{
      var property = {...this.state[type]}
      delete property[deleteKey]
      this.setState({[type]:property})
      this.props.updateData({state:this.state,type:"Asset_Details"})
    }


    renderSavings = () =>{
      var keys = []
      var savings = []
      Object.keys(this.state.savings).map(key =>{
          keys.push(key)
      })
      keys.forEach((key,index) =>{
        savings.push(            
        <tr>
          <td><input type="text" value={this.state.savings[key].bank || ''} onChange={(e) =>this.handleChange(e,'bank',key,"savings")} name="" defaultValue="" /></td>
          {/* <td><input type="text" value={this.state.savings[key].savings_account_type || ''} onChange={(e) =>this.handleChange(e,'savings_account_type',key,"savings")} name="" defaultValue="" /></td> */}
          <td><input type="text" name="" value={this.state.savings[key].account_no || ''} onChange={(e) =>this.handleChange(e,'account_no',key,"savings")} defaultValue="" /></td>
          <td><CurrencyInput prefix="$" decimalsLimit={0} value={this.state.savings[key].amount || ''}  onValueChange={(value, name) => this.handleChange(value,'amount',key,"savings",true)}  name="" defaultValue={0} />                </td>
          {/* <td class="text-center"><input checked={this.state.savings[key].held_by_app1 === true} onClick={(e) =>this.handleHeldByChange(e,'held_by_app1',key,"savings")} type="radio" defaultValue="true" /></td>
          <td class="text-center"><input type="radio" checked={this.state.savings[key].held_by_app2 === true} onClick={(e) =>this.handleHeldByChange(e,'held_by_app2',key,"savings")} type="radio" defaultValue="true" /></td> */}
          <td>
            <HeldByDropDown oldValues={{app1:this.state.savings[key].held_by_app1,app2:this.state.savings[key].held_by_app2}} keys = {{key:key,type:"savings"}} value={this.state.savings[key].held_by} changeFunction = {this.handleDropDownChange} joint={true} required={true} ></HeldByDropDown>
          </td>
          <td class="text-center"><button className="ui negative small icon button" type="button" onClick={(e) =>{this.deleteField(key,"savings")}}><i class="trash alternate outline icon"></i></button></td>
        </tr>
          )
      })
      return savings;
    }

    renderShares = () =>{
      var keys = []
      var shares = []
      Object.keys(this.state.shares).map(key =>{
          keys.push(key)
      })
      keys.forEach((key,index) =>{
        shares.push(            
          <tr>
          <td><input type="text" value={this.state.shares[key].held_with || ''} onChange={(e) =>this.handleChange(e,'held_with',key,"shares")} name="" defaultValue="" /></td>
          {/* <td><input type="text" value={this.state.shares[key].no || ''} onChange={(e) =>this.handleChange(e,'no',key,"shares")} name="" defaultValue="" /></td> */}
          <td><CurrencyInput prefix="$" decimalsLimit={0} value={this.state.shares[key].value || ''}  onValueChange={(value, name) => this.handleChange(value,'value',key,"shares",true)}  name="" defaultValue={0} />                </td>

          {/* <td class="text-center"><input checked={this.state.shares[key].held_by_app1 === true} onClick={(e) =>this.handleHeldByChange(e,'held_by_app1',key,"shares")} type="radio" defaultValue="true" /></td>
            <td class="text-center"><input type="radio" checked={this.state.shares[key].held_by_app2 === true} onClick={(e) =>this.handleHeldByChange(e,'held_by_app2',key,"shares")} type="radio" defaultValue="true" /></td> */}
            <td>
            <HeldByDropDown oldValues={{app1:this.state.shares[key].held_by_app1,app2:this.state.shares[key].held_by_app2}} keys = {{key:key,type:"shares"}} value={this.state.shares[key].held_by} changeFunction = {this.handleDropDownChange} joint={true} required={true} ></HeldByDropDown>
          </td>
            <td class="text-center"><button className="ui negative small icon button" type="button" onClick={(e) =>{this.deleteField(key,"shares")}}><i class="trash alternate outline icon"></i></button></td>
          </tr>
          )
      })
      return shares;
    }

    renderSuper = () =>{
      var keys = []
      var supers = []
      Object.keys(this.state.supers).map(key =>{
          keys.push(key)
      })
      keys.forEach((key,index) =>{
        supers.push(            
        <tr>
        <td><input type="text" value={this.state.supers[key].company || ''} onChange={(e) =>this.handleChange(e,'company',key,"supers")} name="" defaultValue="" /></td>
        <td>
          <div class="flex-input">
            <CurrencyInput prefix="$" decimalsLimit={0} value={this.state.supers[key].value || ''}  onValueChange={(value, name) => this.handleChange(value,'value',key,"supers",true)}  name="" defaultValue={0} />
          </div>
        </td>
        <td>
                <select defaultValue="" required className="ui dropdown form-dropdown" value = {this.state.supers[key].salary_sacrifice || ''} onChange={(e) =>this.handleChange(e,'salary_sacrifice',key,"supers")}>
                  <option defaultValue = ""></option>
                  <option defaultValue = "Yes">Yes</option>
                  <option defaultValue = "No">No</option>
                </select>
                </td>
        <td>
          <div class="flex-input">

            <CurrencyInput prefix="$" decimalsLimit={0} value={this.state.supers[key].yes_amount || ''}  onValueChange={(value, name) => this.handleChange(value,'yes_amount',key,"supers",true)}  name="" defaultValue={0} />

          </div>
        </td>


        {/* <td class="text-center"><input checked={this.state.supers[key].held_by_app1 === true} onClick={(e) =>this.handleHeldByChange(e,'held_by_app1',key,"supers")} type="radio" defaultValue="true" /></td>
          <td class="text-center"><input type="radio" checked={this.state.supers[key].held_by_app2 === true} onClick={(e) =>this.handleHeldByChange(e,'held_by_app2',key,"supers")} type="radio" defaultValue="true" /></td> */}
          <td>
            <HeldByDropDown oldValues={{app1:this.state.supers[key].held_by_app1,app2:this.state.supers[key].held_by_app2}} keys = {{key:key,type:"supers"}} value={this.state.supers[key].held_by} changeFunction = {this.handleDropDownChange} joint={true} required={true} ></HeldByDropDown>
          </td>
          <td class="text-center"><button className="ui negative small icon button" type="button" onClick={(e) =>{this.deleteField(key,"supers")}}><i class="trash alternate outline icon"></i></button></td>

        </tr>
                  )
      })
      return supers;
    }

    renderButtons = () =>{
      if(this.props.showButtons == 'false'){
        return(null)
      }else{
        return(
          <div className="">
          <button className="ui button primary " onClick={(e) =>{this.props.changeForm("Liabilities_Debts")}}>Previous Page</button>
          <button className="ui button primary " type="submit" style={{float:"right"}}>Next Page</button>
        </div>
        )
      }
    }
    renderCars = () =>{
      var keys = []
      var cars = []
      Object.keys(this.state.vehicles).map(key =>{
        keys.push(key)
      })
      keys.forEach((key,index) =>{
        cars.push(            
          <tr>
          <td><input type="text" value={this.state.vehicles[key].make || ''} onChange={(e) =>this.handleChange(e,'make',key,"vehicles")} name="" defaultValue="" /></td>
          <td><input type="text" value={this.state.vehicles[key].year || ''} onChange={(e) =>this.handleChange(e,'year',key,"vehicles")} name="" defaultValue="" /></td>
          <td><CurrencyInput style={{"minWidth":"100px"}} prefix="$" decimalsLimit={0} value={this.state.vehicles[key].value || ''}  onValueChange={(value, name) => this.handleChange(value,'value',key,"vehicles",true)}  name="" defaultValue={0} /></td>
          <td>
            <select required value={this.state.vehicles[key].leased_owned || ""} onChange={(e) =>this.handleChange(e,'leased_owned',key,"vehicles")} className="ui dropdown form-dropdown" style={{"minWidth":"75px"}}>
              <option></option>
              <option >Leased</option>
              <option>Owned</option>
            </select>

          </td>


           <td><input class="w20" type="text" name="" defaultValue=""  value={this.state.vehicles[key].lease_expiry_day || ''} onChange={(e) =>this.handleChange(e,'lease_expiry_day',key,"vehicles")} /> / <input class="w20" value={this.state.vehicles[key].lease_expiry_month || ''} onChange={(e) =>this.handleChange(e,'lease_expiry_month',key,"vehicles")} type="text" name="" defaultValue="" /> / <input class="w20 year-input" value={this.state.vehicles[key].lease_expiry_year || ''} onChange={(e) =>this.handleChange(e,'lease_expiry_year',key,"vehicles")} type="text" name="" defaultValue="" /></td>
            <td>
              <HeldByDropDown oldValues={{app1:this.state.vehicles[key].held_by_app1,app2:this.state.vehicles[key].held_by_app2}} keys = {{key:key,type:"vehicles"}} value={this.state.vehicles[key].held_by} changeFunction = {this.handleDropDownChange} joint={true} required={true} ></HeldByDropDown>
            </td>
            <td class="text-center"><button className="ui negative small icon button" type="button" onClick={(e) =>{this.deleteField(key,"vehicles")}}><i class="trash alternate outline icon"></i></button></td>
          </tr>
          )
      })

      return cars;
    }

    leaseDateChange = (value,applicant_number,type,field,key) =>{
      console.log(value,applicant_number,type,field,key)
      const property = {...this.state.vehicles}

      if(type != null){
        property[key][field][type] = value;
      }else{
        property[key][field] = value;
      }

      this.setState({vehicles:property})
    }

    renderMajorAssets = () =>{
      var keys = []
      var assets = []
      Object.keys(this.state.assets).map(key =>{
        keys.push(key)
      })
      keys.forEach((key,index) =>{
        assets.push(            
          <tr>
            <td colspan="5"><input type="text" value={this.state.assets[key].name || ''}onChange={(e) =>this.handleChange(e,'name',key,"assets")} name="" defaultValue="" /></td>
            <td colspan="5"><CurrencyInput prefix="$" decimalsLimit={0} value={this.state.assets[key].value || ''}  onValueChange={(value, name) => this.handleChange(value,'value',key,"assets",true)}  name="" defaultValue={0} /></td>
            <td colspan="5"><HeldByDropDown oldValues={{app1:this.state.assets[key].held_by_app1,app2:this.state.assets[key].held_by_app2}} keys = {{key:key,type:"assets"}} value={this.state.assets[key].held_by} changeFunction = {this.handleDropDownChange} joint={true} required={true} ></HeldByDropDown></td>
            <td  colspan="1" class="text-center"><button className="ui negative small icon button" type="button" onClick={(e) =>{this.deleteField(key,"assets")}}><i class="trash alternate outline icon"></i></button></td>

        </tr>
          )
      })
      return assets;

    }

    render(){
        return(
            <div>
              <form onSubmit = {(e) =>{this.handleSubmit(e)}} onChange = {this.formChange}>
        <table class="table4">
          <thead>
            <tr>
              <th colspan="12">Current Properties</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{width:`20%`}}>Owner Occupied / Investment</td>
              <td style={{width:`${30/4}%`}}>Value</td>
              <td style={{width:`15%`}} >Lender</td>
              <td style={{width:`15%`}}>Amount owing</td>
              <td style={{width:`${30/4}%`}}>Monthly repayment</td>
              <td style={{width:`${30/4}%`}}>Weekly Rental Income</td>
              <td style={{width:`${30/4}%`}}>Payout / close?</td>
              <td style={{width:`20%`}}>Held By?</td>
              <td colSpan="2"></td>
            </tr>
            {this.renderProperties()}
            <tr>
              <td colspan="4" className="add-field-row"><button className="ui button add-field" type="button" onClick={(e) =>{this.addNewField("properties")}}><i class="plus circle icon"></i>Add Property</button></td>
            </tr>
          </tbody>
        </table>

        <table class="table6">
          <thead>
          <tr>
              <th colspan="13">Home Contents</th>
            </tr>
          </thead>

          <tbody>
          <tr>
              <td colspan="2">Estimated value of home contents</td>
              <td>              <CurrencyInput prefix="$" decimalsLimit={0} value={this.state.home_contents_value || ''}  onValueChange={(value, name) => this.setState({home_contents_value:value})}  name="" defaultValue={0} />
</td>
                          </tr>
          </tbody>
        </table>
        <table class="table5">

        <thead>
            <tr>
              <th colspan="6">Savings / Other</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowspan="1" style={{width:`${80/3}%`}}>Bank / Institution</td>
              <td rowspan="1" style={{width:`${80/3}%`}}>Account no.</td>
              <td rowspan="1" style={{width:`${80/3}%`}}>Amount</td>
              <td rowspan="1" style={{width:"20%"}}>Held by</td>
              <td rowspan="1"></td>
            </tr>

            {this.renderSavings()}
            <td colspan="4" className="add-field-row"><button className="ui button add-field" type="button" onClick={(e) =>{this.addNewField("savings")}}><i class="plus circle icon"></i>Add Savings</button></td>
          </tbody>
        </table>

        <table class="table6">
        <thead>
            <tr>
              <th colspan="12">Shares</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{width:"40%"}}>Shares held with</td>
              <td style={{width:"40%"}}>Value</td>
              <td style={{width:"20%"}} >Held by</td>
              <td ></td>
            </tr>
            {this.renderShares()}
            <td colspan="4" className="add-field-row"><button className="ui button add-field" type="button" onClick={(e) =>{this.addNewField("shares")}}><i class="plus circle icon"></i>Add Shares</button></td>

          </tbody>
        </table>

        <table class="table6">
        <thead>
            <tr>
              <th colspan="14">Superannuation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{width:"20%"}}>Superannuation Company</td>
              <td style={{width:"20%"}}>Value</td>
              <td style={{width:"20%"}}>Salary Sacrifice Income to Super?</td>
              <td style={{width:"20%"}}>If yes, amount per month?</td>
              <td style={{width:"20%"}}>Held by</td>
              <td ></td>
            </tr>
            {this.renderSuper()}
            <td colspan="4" className="add-field-row"><button className="ui button add-field" type="button" onClick={(e) =>{this.addNewField("supers")}}><i class="plus circle icon"></i>Add Super</button></td>

          </tbody>
        </table>

        <table class="table6">
        <thead>
            <tr>
              <th colspan="14">Vehicles</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{width:"20%"}}>Car Make and model</td>
              <td style={{width:"10%"}}>Year</td>
              <td style={{width:"10%"}}>Value</td>
              <td style={{width:"20%"}}>Leased / Owned</td>
              <td style={{width:"20%"}}>Lease Expiry date</td>
              <td style={{width:"20%"}}>Held by</td>
              <td ></td>
            </tr>
            {this.renderCars()}
            <td colspan="4" className="add-field-row"><button className="ui button add-field" type="button" onClick={(e) =>{this.addNewField("vehicles")}}><i class="plus circle icon"></i>Add Vehicle</button></td>

          </tbody>
        </table>

        <table class="table6">
        <thead>
            <tr>
              <th colspan="16">Other Major Assets</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="5" style={{width:"40%"}}>Asset Name</td>
              <td colspan="5" style={{width:"40%"}}>Asset Value</td>
              <td colspan="5" style={{width:"20%"}}>Held By</td>
              <td colspan="1"></td>
            </tr>
            {this.renderMajorAssets()}
            <td colspan="4" className="add-field-row"><button className="ui button add-field" type="button" onClick={(e) =>{this.addNewField("assets")}}><i class="plus circle icon"></i>Add Asset</button></td>
          </tbody>
        </table>
          {this.renderButtons()}
          </form>
            </div>
        )

    }
}


const mapStateToProps = (state) =>{
  return {data:state.data}
}

export default connect(mapStateToProps,{updateData})(Assets)