import update from 'immutability-helper';

export default (state = {
    events:[],
    last_event:null
},action) => {
    switch(action.type){
      case 'SET_EVENTS':
        return update(state,{
         events:{$set:action.payload}
        })
      case 'SET_LAST_EVENT':
        return update(state,{
          last_event:{$set:action.payload}
        })
      default:
        return state
    }
  }
  