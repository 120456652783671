import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchApplication } from "../../actions";
import { CopyToClipboard } from 'react-copy-to-clipboard';


import history from "../../history";
import { withRouter } from "react-router-dom";
import moment from "moment";

export class ClientEntry extends Component {
    componentDidMount() {
        console.log(this.props.client_data);
    }

    clientPage = () => {
        history.push("/admin/application/" + this.props.client_data.id);
    };


    renderAvatar = () => {
        const males = [
            "001-boy.svg",
            "004-boy-1.svg",
            "007-boy-2.svg",
            "008-boy-3.svg",
            "009-boy-4.svg",
            "011-boy-5.svg",
            "015-boy-6.svg",
            "016-boy-7.svg",
            "024-boy-9.svg",
            "026-boy-10.svg",
        ];
        const females = [
            "002-girl.svg",
            "003-girl-1.svg",
            "005-girl-2.svg",
            "006-girl-3.svg",
            "007-girl-2.svg",
            "010-girl-4.svg",
            "012-girl-5.svg",
            "013-girl-6.svg",
            "014-girl-7.svg",
            "017-girl-8.svg",
            "018-girl-9.svg",
        ];
        var avatar = males[Math.floor(Math.random() * males.length)];
        if (this.props.client_data.completed == true) {
            const applicant1 = this.props.client_data.data.General_Details.applicant1;
            if (applicant1.title == "Mrs" || applicant1.title == "Ms") {
                var avatar = females[Math.floor(Math.random() * females.length)];
            }
        }
        return avatar;
    };

    renderDate = () => {
        if (this.props.client_data.created) {
            var date = moment(this.props.client_data.created).format("DD/MM/YYYY");
            return date;

            // return Date(this.props.client_data.created).toLocaleDateString()
        } else {
            return undefined;
        }
    };

    renderLastUpdate = () => {
        if (this.props.client_data.last_updated) {
            var time_from_update = moment(this.props.client_data.last_updated).fromNow();
            return time_from_update;

        } else if (this.props.client_data.created) {
            var time_from_creation = moment(this.props.client_data.created).fromNow();
            return time_from_creation;
        } else {
            return undefined;
        }
    };

    renderProgressBar = () => {
        return (
            <div class="progress">
                <div class="progress-bar" role="progressbar" style={{ width: "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        );
    };

    renderName = () => {
        const applicant_keys = Object.keys(this.props.client_data.applicants);
        var string = "";

        if (applicant_keys.length == 1) {
            string = this.props.client_data.applicants[applicant_keys[0]].name;
        } else if (applicant_keys.length == 2) {
            string = `${this.props.client_data.applicants[applicant_keys[0]].name} & ${this.props.client_data.applicants[applicant_keys[1]].name}`;
        } else if (applicant_keys.length >= 3) {
            var names = [];
            applicant_keys.forEach(key => {
                names.push(this.props.client_data.applicants[key].name);
            });
            names.pop();
            string = `${names.join(", ")} & ${this.props.client_data.applicants[applicant_keys[applicant_keys.length - 1]].name}`;
        }
        return string;
    };
    render() {
        return (
            <tr>
                <td class="">
                    <div class="d-flex align-items-center">
                        <div class="symbol symbol-50 symbol-light mr-4">
                            <span class="symbol-label">
                                <img onClick={(e) => { this.clientPage(); }} src={process.env.PUBLIC_URL + `/metronic/assets/media/svg/avatars/${this.renderAvatar()}`} class="h-75 align-self-end client-image" alt="" />
                            </span>
                        </div>
                        <div>
                            <a onClick={(e) => { this.clientPage(); }} class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg client-name">{this.renderName()}</a>
                            <span class="text-muted font-weight-bold d-block">{this.props.name}</span>
                        </div>
                    </div>
                </td>

                <td>
                    <span class="text-dark-75 font-bold d-block font-size-sm">{this.renderLastUpdate() || "01/01/2021"}</span>

                </td>

                <td>
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{this.renderDate() || "01/01/2021"}</span>
                    <span class="text-muted font-weight-bold">{this.props.client_data.created_by || "Ryan Pappas"}</span>
                </td>



                <td className="text-right">
                    <span class={`label label-lg label-light-${this.props.client_data.requested_info.id == true ? "success" : "info"} label-inline`}>{this.props.client_data.requested_info.id == true ? "Requested" : "Not Requested"}</span>
                </td>

                <td className="text-right">
                    <span class={`label label-lg label-light-${this.props.client_data.requested_info.docs == true ? "success" : "info"} label-inline`}>{this.props.client_data.requested_info.docs == true ? "Requested" : "Not Requested"}</span>
                </td>

                <td className="text-right">
                    <span class={`label label-lg label-light-${this.props.client_data.requested_info.fact_find == true ? "success" : "info"} label-inline`}>{this.props.client_data.requested_info.fact_find == true ? "Requested" : "Not Requested"}</span>
                </td>

            </tr>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
    fetchApplication
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientEntry);
