import React, { Component } from 'react'
import { connect } from 'react-redux'

export class SubHeader extends Component {
	render() {
		return (
			<div>
            
			{/* <!--begin::Subheader--> */}
									<div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
										<div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
			
											{/* <!--begin::Info--> */}
											<div class="d-flex align-items-center flex-wrap mr-1">
			
												{/* <!--begin::Page Heading--> */}
												<div class="d-flex align-items-baseline flex-wrap mr-5">
			
													{/* <!--begin::Page Title--> */}
													<h5 class="text-dark font-weight-bold my-1 mr-5">{this.props.name}</h5>
			
													{/* <!--end::Page Title--> */}
												</div>
			
												{/* <!--end::Page Heading--> */}
											</div>
			
											{/* <!--end::Info--> */}
			
											{/* <!--begin::Toolbar--> */}
											<div class="d-flex align-items-center">
			
												{/* <!--begin::Actions--> */}
												{/* <a href="#" class="btn btn-light-primary font-weight-bolder btn-sm">Actions</a> */}
			
												{/* <!--end::Actions--> */}
			
												{/* <!--begin::Dropdown--> */}
												<div class="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" data-placement="left">
													<a href="#" class="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<span class="svg-icon svg-icon-success svg-icon-2x">
			
															{/* <!--begin::Svg Icon | path:assets/media/svg/icons/Files/File-plus.svg--> */}
			
			
															{/* <!--end::Svg Icon--> */}
														</span>
													</a>
													<div class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0">
			
														{/* <!--[html-partial:begin:{"id":"demo1/dist/inc/view/partials/content/dropdowns/dropdown-1","page":"index"}]/--> */}
			
														{/* <!--begin::Navigation--> */}
														<ul class="navi navi-hover">
															<li class="navi-header font-weight-bold py-4">
																<span class="font-size-lg">Choose Label:</span>
																<i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..."></i>
															</li>
															<li class="navi-separator mb-3 opacity-70"></li>
															<li class="navi-item">
																<a href="#" class="navi-link">
																	<span class="navi-text">
																		<span class="label label-xl label-inline label-light-success">Customer</span>
																	</span>
																</a>
															</li>
															<li class="navi-item">
																<a href="#" class="navi-link">
																	<span class="navi-text">
																		<span class="label label-xl label-inline label-light-danger">Partner</span>
																	</span>
																</a>
															</li>
															<li class="navi-item">
																<a href="#" class="navi-link">
																	<span class="navi-text">
																		<span class="label label-xl label-inline label-light-warning">Suplier</span>
																	</span>
																</a>
															</li>
															<li class="navi-item">
																<a href="#" class="navi-link">
																	<span class="navi-text">
																		<span class="label label-xl label-inline label-light-primary">Member</span>
																	</span>
																</a>
															</li>
															<li class="navi-item">
																<a href="#" class="navi-link">
																	<span class="navi-text">
																		<span class="label label-xl label-inline label-light-dark">Staff</span>
																	</span>
																</a>
															</li>
															<li class="navi-separator mt-3 opacity-70"></li>
															<li class="navi-footer py-4">
																<a class="btn btn-clean font-weight-bold btn-sm" href="#">
																	<i class="ki ki-plus icon-sm"></i>Add new</a>
															</li>
														</ul>
			
														{/* <!--end::Navigation--> */}
			
														{/* <!--[html-partial:end:{"id":"demo1/dist/inc/view/partials/content/dropdowns/dropdown-1","page":"index"}]/--> */}
													</div>
												</div>
			
												{/* {/* <!--end::Dropdown--> */}
											</div>
			
											{/* <!--end::Toolbar--> */}
										</div>
									</div>
			
									{/* <!--end::Subheader--> */}
					</div>
		)
	}
}

const mapStateToProps = (state) => ({
	
})

const mapDispatchToProps = {
	
}

export default connect(mapStateToProps, mapDispatchToProps)(SubHeader)
