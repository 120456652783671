import update from 'immutability-helper';


const INIT_STATE = {
    name:"",
    email:"",
    token:null
}


export default (state = INIT_STATE,action) => {
    switch(action.type){
        case 'SET_USER':
            return update(state,{
                name:{$set:action.payload.name},
                email:{$set:action.payload.email},
                token:{$set:action.payload.token}
            })

      default:
        return state
    }
  }
  