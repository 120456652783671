import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CopyToClipboard } from "react-copy-to-clipboard";
import AddClientModal from './AddClientModal';
import DeleteClientModal from './DeleteClientModal';
import EditClientModal from './EditClientModal';
import server from '../../api/server';

export class ApplicantList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            buttonDown: false,
            showClientModal: false,
            copied: false,
            editClient: { name: "", email: "", mobile: "" },
            deleteClient: { name: "" },
            editClientId: "",
            applicant_error: true,
            failing_applicants: []
        };
    }

    renderAvatar = () => {
        const males = [
            "001-boy.svg",
            "004-boy-1.svg",
            "007-boy-2.svg",
            "008-boy-3.svg",
            "009-boy-4.svg",
            "011-boy-5.svg",
            "015-boy-6.svg",
            "016-boy-7.svg",
            "024-boy-9.svg",
            "026-boy-10.svg",
        ];
        const females = [
            "002-girl.svg",
            "003-girl-1.svg",
            "005-girl-2.svg",
            "006-girl-3.svg",
            "007-girl-2.svg",
            "010-girl-4.svg",
            "012-girl-5.svg",
            "013-girl-6.svg",
            "014-girl-7.svg",
            "017-girl-8.svg",
            "018-girl-9.svg",
        ];
        var avatar = males[Math.floor(Math.random() * males.length)];
        // if(this.props.client_data.completed == true){
        //     const applicant1 = this.props.client_data.data.General_Details.applicant1;
        //     if(applicant1.title == "Mrs" || applicant1.title == "Ms"){
        //         var avatar = females[Math.floor(Math.random() * females.length)];
        //     }
        // }
        return avatar;
    };

    componentDidMount() {
        this.checkApplicants();
    }

    checkApplicants = async () => {
        var response = await server.post("/admin/checkApplicants", { application_id: `application-${this.props.id}`, applicants: this.props.applicants });

        if (response && response.data && response.data.pass == false) {
            this.setState({ failing_applicants: response.data.fails });
        } else if (response.data.pass == true) {
            this.setState({ failing_applicants: [] });
        }
    };


    renderErrorMessage = () => {
        if (this.state.failing_applicants.length > 0) {
            var message = "The following applicants are currently attached to another application: ";
            this.state.failing_applicants.forEach((id) => {
                message += `${this.props.applicants[id].name}, `;
            });

            return <span className='text-danger font-bold'>{message}</span>;
        }

        return null;
    };

    renderApplicants = () => {
        const applicants = [];
        if (this.props.applicants) {
            Object.keys(this.props.applicants).forEach(key => {
                applicants.push(
                    <tr>
                        <td class="">
                            <div class="d-flex align-items-center">
                                <div class="symbol symbol-50 symbol-light mr-4">
                                    <span class="symbol-label">
                                        <img src={process.env.PUBLIC_URL + `/metronic/assets/media/svg/avatars/${this.renderAvatar()}`} class="h-75 align-self-end" alt="" />
                                    </span>
                                </div>
                                <div>
                                    <span class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{this.props.applicants[key].name}</span>
                                    <span class="text-muted font-weight-bold d-block">{this.props.email}</span>
                                </div>
                            </div>
                        </td>

                        <td>
                            <span className="font-weight-bold">{this.props.applicants[key].email}</span>
                        </td>

                        <td>
                            {/* <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{this.renderDate() || "01/01/2021"}</span> */}
                            <span class=" font-weight-bold">{this.props.applicants[key].mobile}</span>
                        </td>


                        <td className="text-right">
                            <button data-toggle="modal"  data-target="#EditClientModal" class="btn btn-icon btn-info mr-1" onClick={(e) => { 
                                
                                window.$("#EditClientModal").modal("show");
                                this.setState({ editClient: this.props.applicants[key], editClientId: key }); 
                            }}><i class="fas fa-edit"></i></button>
                            <button data-toggle="modal" data-target="#DeleteClientModal" class="btn btn-icon btn-danger" onClick={(e) => { 
                                this.setState({ deleteClient: this.props.applicants[key] });
window.$("#DeleteClientModal").modal("show");
 }}><i class="fas fa-trash"></i></button>
                        </td>


                    </tr>
                );
            });
        }

        return applicants;

    };


    render() {
        return (
            <div className="card card-custom card-stretch gutter-b">
                <AddClientModal />
                <EditClientModal client_id={this.state.editClientId} client={this.state.editClient} moveClient={async () => {
                    var response = await server.post("/admin/moveClient", {
                        application_id: `application-${this.props.id}`,
                        firebase_id: this.state.editClient.firebase_id,
                    });

                    if (response && response.data.success) {
                        this.checkApplicants();
                    }
                }} />
                <DeleteClientModal client={this.state.deleteClient} />
                <div class="card-header border-0 py-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark">Clients</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-sm">This is a list of all borrowers linked to this application.</span>
                    </h3>
                    <div class="card-toolbar">
                        <button onClick={() => { window.$("#AddClientModal").modal("show"); }} data-toggle="modal" data-target="#AddClientModal" className="btn btn-sm btn-warning mr-3" >Add Client</button>
                        {/* <CopyToClipboard text={`${process.env.REACT_APP_DOMAIN}dashboard/application-${this.props.id}`}> */}
                        <a className='btn btn-sm btn-success mr-3' target="_blank" href={`/dashboard/application-` + this.props.id}>Dashboard</a>
                        <CopyToClipboard text={`${process.env.REACT_APP_DOMAIN}client`}>
                            <button onMouseDown={(e) => { this.setState({ buttonDown: true }); }} onMouseUp={(e) => { this.setState({ buttonDown: false }); }} onClick={(e) => { this.setState({ copied: true }); }} title data-placement="left" data-original-title="Copy Client Link" data-toggle="tooltip" title="Copy Client Link" className={`btn btn-sm btn-${this.state.buttonDown == false ? "info" : "success"}`}>{this.state.copied ? "Copied!" : "Copy Client Link"}</button>
                        </CopyToClipboard>


                        {/* <button data-toggle="modal" data-target="#ClientAddModal"  class="btn btn-info font-weight-bolder font-size-sm mr-3">New Client</button> */}
                        {/* <button title="Refresh Clients" onClick={(e) =>{this.refreshForms()}} data-placement="left" data-original-title="Refresh Clients" data-toggle="tooltip" class="btn btn-icon btn-success btn-hover-primary"><i class="ki ki-reload"></i></button> */}
                    </div>
                </div>


                <div className="card-body pt-0 pb-3">
                    <div className="tab-content">
                        <div className="table-responsive">

                            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                                <thead>
                                    <tr class="text-left text-uppercase">
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th></th>
                                    </tr>
                                </thead>



                                <tbody>
                                    {this.renderApplicants()}
                                </tbody>
                            </table>


                        </div>
                    </div>

                    {this.renderErrorMessage()}
                </div>




            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    applicants: state.admin.active_application.application.applicants,
    id: state.admin.active_application.form.id
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantList);
