import React, { Component } from 'react';
import { connect } from 'react-redux';
import Documents from './elements/Documents';
import RequestDocCollection from './elements/RequestDocCollection';
import EditDocCollectionModal from './elements/EditDocCollectionModal';
import server from '../../api/server';




export class DocumentCollection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active_applicant: null
        };
    }




    renderApplicants = () => {
        var applicants = [];
        Object.keys(this.props.documents.applicants).forEach(key => {
            applicants.push(
                <Documents applicant_id={key} />
            );
            // ))
        });

        return applicants;
    };

    render() {
        if (this.props.documents != null) {
            return (
                <div className="row ">
                    <EditDocCollectionModal />
                    <div className="col-lg-12">
                        <div className="card card-custom gutter-b">
                            <div class="card-header card-header-tabs-line">
                                <div class="card-title">
                                    <h3 class="card-label font-weight-bolder text-dark">Document Collection</h3>
                                </div>
                                <div class="card-toolbar">
                                    <button onClick={() => { window.$("#EditDocCollectionModal").modal("show"); window.$('.modal-backdrop').remove(); }} className="btn btn-warning mr-3" >Edit Document Collection</button>
                                    <button className='btn btn-info mr-5' onClick={async () => {
                                        var response = await server.post("/docs/all", { file_upload_id: this.props.documents.id, id: this.props.user.token, path: this.props.documents.id }, { responseType: "blob" });
                                        let blob = new Blob([response.data], {
                                            type: "application/zip"
                                            //               OR
                                            // type:"application/zip, application/octet-stream"
                                        });
                                        let objectUrl = URL.createObjectURL(blob);
                                        let link = document.createElement('a');
                                        link.href = objectUrl;
                                        link.download = `${this.props.active_application.application.id}.zip`;
                                        link.click();

                                    }}>Download All</button>
                                    {/* <ul class="nav nav-tabs nav-bold nav-tabs-line">
                                    {this.renderApplicants()}
                                </ul> */}
                                </div>
                            </div>

                            <div className="card-body">
                                {this.renderApplicants()}
                                {this.state.active_applicant != null ? <Documents applicant_id={this.state.active_applicant} /> : null}

                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <RequestDocCollection />
            );
        }

    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    documents: state.admin.active_application.documents,
    active_application: state.admin.active_application,
    organisation: state.organisation
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCollection);