import React from 'react';
import {connect} from 'react-redux'

import ActiveForms from './admin/ActiveForms.js'
import NewForm from './admin/NewForm.js'
import ArchivedForms from './admin/ArchivedForms.js'
import {fetchForm} from '../actions'
import firebase from './Firebase.js'
import history from '../history'
import {Helmet} from "react-helmet"

class Admin extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            menu:"Active_Forms",
            loggedin:false
        }
      }

    
    componentDidMount() {
        console.log(this.props)
        this.checkAuth()
    }

    changeMenu = (menu) =>{
        this.setState({menu:menu})
    }

    checkAuth = () =>{
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                console.log('logged in.')
                this.setState({loggedin:true})
              // User is signed in.
            } else {
                history.push("/login")
              // No user is signed in.
            }
          });
    }



    renderAdminContent = () =>{
        if(this.state.menu == 'Active_Forms'){
            return(
                <div>
                    <ActiveForms></ActiveForms>
                </div>
            )
        }else if(this.state.menu == 'New_Forms'){
            return(
                <div>
                    <NewForm changeMenu={this.changeMenu}></NewForm>
                </div>
            )
        }else if(this.state.menu == 'Archived_Forms'){
            return(
                <div>
                    <ArchivedForms></ArchivedForms>
                </div>
            )
        }
    }

    renderHeader = () =>{
        if(this.state.menu == 'Active_Forms'){
            return("Active Forms")
        }else if(this.state.menu == 'New_Forms'){
            return("New Form")
        }else if(this.state.menu == 'Archived_Forms'){
            return("Archived Forms")
        }
    }

    render(){
        if(this.state.loggedin){
            return(
                
                <div>
                    <Helmet>
                        <title>Admin - Mortgage Choice Sydney CBD</title>
                        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" integrity="sha512-8bHTC73gkZ7rZ7vpqUQThUDhqcNFyYi2xgDgPDHc+GXVGHXq+xPjynxIopALmOPqzo9JZj0k6OqqewdGO3EsrQ==" crossorigin="anonymous" />
                        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/components/grid.min.css" integrity="sha512-RtktBL0Hhw9K2e2rGLZGadK8cF6Q0wKxRUkfh4pJszwnarmh3GbqHCiLm3UZFA1dRiFqtPkVrcby0hXWsqoDNA==" crossorigin="anonymous" />
                        <link rel="stylesheet" href={process.env.PUBLIC_URL + `/css/style.css`}/> 
                    </Helmet>
                    <nav class="ui top fixed inverted menu">
                        <div class="left menu">
                            <a href="#" class="header item">
                            Mortgage Choice Sydney CBD
                            </a>
                        </div>
    
                        <div class="right menu">
                            <div class="ui dropdown item" tabindex="0">
                            <i class="user cirlce icon"></i>
                            <div class="menu" tabindex="-1">
                                <a href="#" class="item">
                                <i class="info circle icon"></i> Profile</a>
                                <a href="#" class="item">
                                <i class="wrench icon"></i>
                                Settings</a>
                                <a href="#" class="item">
                                <i class="sign-out icon"></i>
                                Logout
                                </a>
                            </div>
                            </div>
                        </div>
                    </nav>
                    <div class="ui sidebar visible inverted vertical menu sidebar-menu" id="admin-sidebar">
                        <div class="item">
                            <div class="admin-menu-header">
                            Administration
                            </div>
                            <div class="menu">
                            <a class="item" onClick={(e) => {this.changeMenu("Active_Forms")}}>
                                <div><i class="cogs icon"></i> Active Forms</div>
                            </a>
                            <a class="item" onClick={(e) => {this.changeMenu("New_Forms")}}>
                                <div><i class="cogs icon"></i> New Form</div>
                            </a>
                            <a class="item"  onClick={(e) => {this.changeMenu("Archived_Forms")}}>
                                <div><i class="users icon"></i> Archived Forms</div>
                            </a>
                            </div>
                        </div>
                    </div>
    
                    <div className="admin-container">
                        <div className="admin-header">
                            <h1>{this.renderHeader()}</h1>
                        </div>
                        <div className="admin-main-content">
                            {this.renderAdminContent()}
                        </div>
                    </div>
                </div>
            )
        }else{
            return(
                <div>
                                                <Helmet>
            <title>Admin - Mortgage Choice Sydney CBD</title>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" integrity="sha512-8bHTC73gkZ7rZ7vpqUQThUDhqcNFyYi2xgDgPDHc+GXVGHXq+xPjynxIopALmOPqzo9JZj0k6OqqewdGO3EsrQ==" crossorigin="anonymous" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/components/grid.min.css" integrity="sha512-RtktBL0Hhw9K2e2rGLZGadK8cF6Q0wKxRUkfh4pJszwnarmh3GbqHCiLm3UZFA1dRiFqtPkVrcby0hXWsqoDNA==" crossorigin="anonymous" />
            <link rel="stylesheet" href={process.env.PUBLIC_URL + `/css/style.css`}/> 
        </Helmet>
                    <div className="form-loading">
                        <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="" />
                    </div>
                </div>
            )
        }

    }

}

const mapStateToProps = (state) =>{
  return {id:state.id}
}

export default connect(mapStateToProps,{fetchForm})(Admin)
