import update from 'immutability-helper';
const INIT_STATE = {
    page:"applications",
    active_application:{},
    applications:[]
}


export default (state = INIT_STATE,action) => {
    switch(action.type){
      case 'CHANGE_ADMIN_PAGE':
        return update(state,{
          page:{$set:action.payload}
        })
      case 'SET_CLIENT_DATA':
        return update(state,{
          active_client:{$set:action.payload}
        })
      case "SET_ADMIN_APPLICATIONS":
        return update(state,{
          applications:{$set:action.payload}
        })
      case "SET_APPLICATION_DATA":
        return update(state,{
          active_application:{$set:action.payload}
        })
      case "REFRESH_VOI_DATA":
        return update(state,{
          active_application:{
            VOI:{$set:action.payload}
          }
        })
        case "SET_BANKSTATEMENTS_ADMIN":
          return update(state,{
            active_application:{
              bankstatements:{$set:action.payload}
            }
          })
      default:
        return state
    }
  }
  