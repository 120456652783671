import React from 'react';
import {connect} from 'react-redux'
import {updateData} from '../../actions'
import CurrencyInput from 'react-currency-input-field';
import DatePicker from './form_elements/DatePicker.js'


class Employment_Details extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      errorMessage:"",
      applicant1:{
      },
      applicant2:{
      },
      other_income_details:"",
    }
  }


  componentDidMount() {
    window.scrollTo(0, 0)
    console.log(this.props.data)
    if('Employment_Details' in this.props.data){
      const property = {...this.state};
      console.log(this.props.data)
      Object.keys(this.props.data['Employment_Details']).map(key =>{
        if(key != "errorMessage"){
          property[key] = this.props.data['Employment_Details'][key];
        }
      })
      this.setState(property)
    }
  }

    componentDidUpdate(prevProps,prevState){
      clearTimeout(prevState.timeoutID)
    }

    formChange = (e) =>{
      const update = async () =>{
        this.props.updateData({state:this.state,type:"Employment_Details"})
      }
      const timeoutID = setTimeout(function() {
        update()
      },250)
      this.setState({timeoutID:timeoutID})
    }

    handleInputChange = (e,applicant,field,currency) =>{
      var value = 0
      if(currency){
        value = e;
      }else{
        value = e.target.value
      }
      if(applicant == 1){
        const property = {...this.state.applicant1};
        property[field] = value
        this.setState({applicant1:property})
      }else if(applicant == 2){
        const property = {...this.state.applicant2};
        property[field] = value
        this.setState({applicant2:property})
      }
    }

    handleSubmit = (e) =>{
      e.preventDefault()

      const checks = this.checkHistory();
      console.log(checks)
      if(!checks[0]){
        window.scrollTo(0, 0)
        this.setState({errorMessage:"Please provide atleast 3 years of previous employment history for Applicant 1."})
      }else if(!checks[1]){
        window.scrollTo(0, 0)
        this.setState({errorMessage:"Please provide atleast 3 years of previous employment history for Applicant 2."})

      }else{
        this.props.updateData({state:this.state,type:"Employment_Details"})
        this.props.changeForm("Income")
      }
    }

    renderButtons = () =>{
      if(this.props.showButtons == 'false'){
        return(null)
      }else{
        return(
          <div className="">
          <button className="ui button primary " onClick={(e) =>{this.props.changeForm("Address_3y")}}>Previous Page</button>
          <button tabIndex="9999" className="ui button primary " type="submit" style={{float:"right"}}>Next Page</button>
        </div>
        )
      }
    }

    renderErrors = () =>{
      if(this.state.errorMessage){
          return(
              <div class="ui red message">{this.state.errorMessage}</div>
          )
      }else{

      }
    }

    handlePreviousEmploymentsChange = (e,key,applicant,field,currency) =>{
      var value = 0
      if(currency){
        value = e;
      }else{
        value = e.target.value
      }
      if(applicant == 1){
        const property = {...this.state.applicant1};
        property[key][field] = value
        this.setState({applicant1:property})
      }else if(applicant == 2){
        const property = {...this.state.applicant2};
        property[key][field] = value
        this.setState({applicant2:property})
      }
    }

    fieldsRequired = (key,applicant) =>{
      if(applicant == 1 && this.state.applicant1[key].position != "" && this.state.applicant1[key].position != undefined){
        return true
      }else if(applicant == 2 && this.state.applicant2[key].position != "" && this.state.applicant2[key].position != undefined){
        return true
      }
      // Make required if Previous Position is Inputed

    }

    renderPreviousEmployments = () =>{
      var keys = []
      var previous_employments = []
      Object.keys(this.state.applicant1).map(key =>{
        if(key.startsWith("previousemployment")){
          keys.push(key)
        }
      })
      keys.forEach((key,index) =>{
      previous_employments.push(
        <tr class="form-spacer"></tr>
      )
        previous_employments.push(
          <tr>
          <td><strong>Previous position / occupation {index+1}</strong> <button className="ui negative small icon button" type="button" onClick={(e) =>{this.deleteField(key)}}><i class="trash alternate outline icon"></i></button></td>
          <td><input tabIndex={`${index+1}01`} type="text" value={this.state.applicant1[key].position || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','position')} name="" defaultValue="" /></td>
          <td><input tabIndex={`${index+1}10`} type="text" value={this.state.applicant2[key].position || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','position')} name="" defaultValue="" /></td>
        </tr>
        )

        previous_employments.push(
          <tr>
          <td>Previous Income Gross</td>
          <td>
            <div class="flex-input priceInput ml-0">
              <CurrencyInput tabIndex={`${index+1}02`} prefix="$" decimalsLimit={0} required={(this.state.applicant1[key].income_amount_net == "" || this.state.applicant1[key].income_amount_net == undefined )&& this.fieldsRequired(key,1)  ? true :false} value={this.state.applicant1[key].income_amount_gross || ""} onValueChange={(value, name) => this.handlePreviousEmploymentsChange(value,key,'1','income_amount_gross',true)}  name="" defaultValue={0} />
            </div>

            <div className="flex-input" style={{display:"inline-flex", width:"50%"}}>
              <select required={this.fieldsRequired(key,1)} className="ui dropdown form-dropdown" value = {this.state.applicant1[key].income_gross_interval || ''}onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','income_gross_interval')}>
                <option defaultValue = ""></option>
                <option defaultValue = "A">Annually</option>
                <option defaultValue = "M">Monthly</option>
                <option defaultValue = "F">Fortnightly</option>
                <option defaultValue = "W">Weekly</option>
              </select>
            </div>


          </td>
          <td>
            <div class="flex-input priceInput ml-0">
              <CurrencyInput tabIndex={`${index+1}11`} required={this.fieldsRequired(key,2)} prefix="$" decimalsLimit={0} value={this.state.applicant2[key].income_amount_gross || ""} onValueChange={(value, name) => this.handlePreviousEmploymentsChange(value,key,'2','income_amount_gross',true)}  name="" />

            </div>

            <div className="flex-input" style={{display:"inline-flex", width:"50%"}}>
              <select required={this.fieldsRequired(key,2)} className="ui dropdown form-dropdown" value = {this.state.applicant2[key].income_gross_interval || ''}onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','income_gross_interval')}>
                <option defaultValue = ""></option>
                <option defaultValue = "A">Annually</option>
                <option defaultValue = "M">Monthly</option>
                <option defaultValue = "F">Fortnightly</option>
                <option defaultValue = "W">Weekly</option>
              </select>
            </div>


          </td>
        </tr>
        )

        previous_employments.push(
          <tr>
          <td>Previous Income Net</td>
          <td>
            <div class="flex-input priceInput ml-0">
              <CurrencyInput tabIndex={`${index+1}03`} prefix="$" required={(this.state.applicant1[key].income_amount_gross == "" || this.state.applicant1[key].income_amount_gross == undefined)  && this.fieldsRequired(key,1)  ? true :false} decimalsLimit={0} value={this.state.applicant1[key].income_amount_net || ""} onValueChange={(value, name) => this.handlePreviousEmploymentsChange(value,key,'1','income_amount_net',true)}  name="" />
            </div>
            <div className="flex-input" style={{display:"inline-flex", width:"50%"}}>
              <select className="ui dropdown form-dropdown" value = {this.state.applicant1[key].income_net_interval || ''}onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','income_net_interval')}>
                <option defaultValue = ""></option>
                <option defaultValue = "A">Annually</option>
                <option defaultValue = "M">Monthly</option>
                <option defaultValue = "F">Fortnightly</option>
                <option defaultValue = "W">Weekly</option>
              </select>
            </div>


          </td>
          <td>
            <div class="flex-input priceInput ml-0">
              <CurrencyInput tabIndex={`${index+1}12`} prefix="$" decimalsLimit={0} value={this.state.applicant2[key].income_amount_net || ""} onValueChange={(value, name) => this.handlePreviousEmploymentsChange(value,key,'2','income_amount_net',true)}  name="" />

            </div>

            <div className="flex-input" style={{display:"inline-flex", width:"50%"}}>
              <select className="ui dropdown form-dropdown" value = {this.state.applicant2[key].income_net_interval || ''}onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','income_net_interval')}>
                <option defaultValue = ""></option>
                <option defaultValue = "A">Annually</option>
                <option defaultValue = "M">Monthly</option>
                <option defaultValue = "F">Fortnightly</option>
                <option defaultValue = "W">Weekly</option>
              </select>
            </div>


          </td>
        </tr>
        )

        previous_employments.push(
          <tr>
          <td>Name and address of previous employer</td>
          <td>
            <div class="flex-input">
              <label for="">Name</label>
              <input tabIndex={`${index+1}04`} required={this.fieldsRequired(key,1)} type="text" name="" defaultValue="" value={this.state.applicant1[key].employer_name || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employer_name')}  />
            </div>
          </td>
          <td>
            <div class="flex-input">
              <label for="">Name</label>
              <input tabIndex={`${index+1}13`} required={this.fieldsRequired(key,2)} type="text" name="" defaultValue="" value={this.state.applicant2[key].employer_name || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employer_name')}  />
            </div>
          </td>
        </tr>
        )

        previous_employments.push(
          <tr>
          <td>&nbsp;</td>
          <td>
            <div class="flex-input">
              <label for="">Street</label>
              <input tabIndex={`${index+1}05`} required={this.fieldsRequired(key,1)} type="text" name="" defaultValue="" value={this.state.applicant1[key].street || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','street')}  />
            </div>
          </td>
          <td>
            <div class="flex-input">
              <label for="">Street</label>
              <input tabIndex={`${index+1}14`} required={this.fieldsRequired(key,2)} type="text" name="" defaultValue="" value={this.state.applicant2[key].street || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','street')} />
            </div>
          </td>
        </tr>
        )

        previous_employments.push(
          <tr>
          <td>&nbsp;</td>
          <td>
            <div class="flex-input">
              <label for="">Suburb</label>
              <input tabIndex={`${index+1}06`} required={this.fieldsRequired(key,1)} type="text" name="" defaultValue="" value={this.state.applicant1[key].suburb || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','suburb')} />
            </div>
          </td>
          <td>
            <div class="flex-input">
              <label for="">Suburb</label>
              <input tabIndex={`${index+1}15`} required={this.fieldsRequired(key,2)} type="text" name="" defaultValue="" value={this.state.applicant2[key].suburb || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','suburb')} />
            </div>
          </td>
        </tr>
        )

        previous_employments.push(
          <tr>
          <td>&nbsp;</td>
          <td>
            <div class="grid4060">
              <div class="flex-input">
                    <label for="">State</label>
                    <select required={this.fieldsRequired(key,1)} className="ui dropdown form-dropdown" value={this.state.applicant1[key].state || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','state')}>
                        <option defaultValue = ""></option>
                        <option defaultValue = "NSW">NSW</option>
                        <option defaultValue = "QLD">QLD</option>
                        <option defaultValue = "SA">SA</option>
                        <option defaultValue = "VIC">VIC</option>
                        <option defaultValue = "WA">WA</option>
                        <option defaultValue = "ACT">ACT</option>
                        <option defaultValue = "NT">NT</option>
                      </select> 
                  </div>
              <div class="flex-input">
                <label for="">Postcode</label>
                <input tabIndex={`${index+1}07`} required={this.fieldsRequired(key,1)} type="text" name="" defaultValue="" value={this.state.applicant1[key].postcode || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','postcode')} />
              </div>
            </div>
          </td>
          <td>
            <div class="grid4060">
            <div class="flex-input">
                    <label for="">State</label>
                    <select className="ui dropdown form-dropdown" required={this.fieldsRequired(key,2)} value={this.state.applicant2[key].state || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','state')}>
                        <option defaultValue = ""></option>
                        <option defaultValue = "NSW">NSW</option>
                        <option defaultValue = "QLD">QLD</option>
                        <option defaultValue = "SA">SA</option>
                        <option defaultValue = "VIC">VIC</option>
                        <option defaultValue = "WA">WA</option>
                        <option defaultValue = "ACT">ACT</option>
                        <option defaultValue = "NT">NT</option>
                      </select> 
                  </div>
              <div class="flex-input">
                <label for="">Postcode</label>
                <input tabIndex={`${index+1}16`} type="text" required={this.fieldsRequired(key,2)} name="" defaultValue="" value={this.state.applicant2[key].postcode || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','postcode')} />
              </div>
            </div>
          </td>
        </tr>
        )

        previous_employments.push(
          <tr>
          <td>Previous employer contact name and phone no.</td>
          <td>
            <div class="flex-input">
              <label for="">Name</label>
              <input tabIndex={`${index+1}08`} required={this.fieldsRequired(key,1)} type="text" name="" defaultValue="" value={this.state.applicant1[key].employer_contact_name || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employer_contact_name')} />
            </div>
            <div class="flex-input mt-5">
              <label for="">Phone</label>
              <input tabIndex={`${index+1}09`} required={this.fieldsRequired(key,1)} type="text" name="" defaultValue="" value={this.state.applicant1[key].employer_contact_phone|| ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employer_contact_phone')} />
            </div>
          </td>
          <td>
            <div class="flex-input">
              <label for="">Name</label>
              <input tabIndex={`${index+1}17`} required={this.fieldsRequired(key,2)} type="text" name="" defaultValue="" value={this.state.applicant2[key].employer_contact_name || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employer_contact_name')} />
            </div>
            <div class="flex-input mt-5">
              <label for="">Phone</label>
              <input tabIndex={`${index+1}18`} required={this.fieldsRequired(key,2)} type="text" name="" defaultValue="" value={this.state.applicant2[key].employer_contact_phone|| ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employer_contact_phone')} />
            </div>
          </td>
        </tr>
        )

        previous_employments.push(
          <tr>
          <td>Date started</td>
          {
                  "employment_start_day" in this.state.applicant1[key] && !("date_started" in this.state.applicant1[key]) && this.props.showButtons == "false" ?
                  <td><input required class="w20" type="text" name="" defaultValue="" value={this.state.applicant1[key].employment_start_day || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employment_start_day')} /> / <input required class="w20" value={this.state.applicant1[key].employment_start_month || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employment_start_month')} type="text" name="" defaultValue="" /> / <input required value={this.state.applicant1[key].employment_start_year || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employment_start_year')} class="w20" type="text" name="" defaultValue="" />
                  </td>:
                  <td><DatePicker field="date_started" required={this.fieldsRequired(key,1)} dateChange={this.previousEmploymentDateChange} applicant_number={1} objectKey={key} value = {this.state.applicant1[key].date_started}></DatePicker>
                  </td>
            }

            {
                  "employment_start_day" in this.state.applicant2[key] && !("date_started" in this.state.applicant2[key]) && this.props.showButtons == "false" ?
                  <td><input class="w20" type="text" name="" defaultValue="" value={this.state.applicant2[key].employment_start_day || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employment_start_day')} /> / <input class="w20" value={this.state.applicant2[key].employment_start_month || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employment_start_month')} type="text" name="" defaultValue="" /> / <input value={this.state.applicant2[key].employment_start_year || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employment_start_year')} class="w20" type="text" name="" defaultValue="" />
                  </td>:
                  <td><DatePicker field="date_started" required={this.fieldsRequired(key,2)}dateChange={this.previousEmploymentDateChange} applicant_number={2} objectKey={key} value = {this.state.applicant2[key].date_started}></DatePicker>
                  </td>
            }


          {/* <td>
            <input required class="w20" type="text" name="" defaultValue="" value={this.state.applicant1[key].employment_start_day || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employment_start_day')} /> / <input required class="w20" value={this.state.applicant1[key].employment_start_month || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employment_start_month')} type="text" name="" defaultValue="" /> / <input required value={this.state.applicant1[key].employment_start_year || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employment_start_year')} class="w20" type="text" name="" defaultValue="" />
  
          </td>
  
          <td>
          <input class="w20" type="text" name="" defaultValue="" value={this.state.applicant2[key].employment_start_day || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employment_start_day')} /> / <input class="w20" value={this.state.applicant2[key].employment_start_month || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employment_start_month')} type="text" name="" defaultValue="" /> / <input value={this.state.applicant2[key].employment_start_year || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employment_start_year')} class="w20" type="text" name="" defaultValue="" />
          </td> */}
        </tr>
        )

        previous_employments.push(
          <tr>
          <td>Employment Status</td>

          <td>
            <select required={this.fieldsRequired(key,1)} className="ui dropdown form-dropdown" value = {this.state.applicant1[key].employment_status || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employment_status')}>
              <option defaultValue = ""></option>
              <option defaultValue = "Full Time">Full Time</option>
              <option defaultValue = "Part Time">Part Time</option>
              <option defaultValue = "Casual">Casual</option>
              <option defaultValue = "Contract">Contract</option>
            </select> 
          </td>

          <td>
            <select required={this.fieldsRequired(key,2)} className="ui dropdown form-dropdown" value = {this.state.applicant2[key].employment_status || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employment_status')}>
              <option defaultValue = ""></option>
              <option defaultValue = "Full Time">Full Time</option>
              <option defaultValue = "Part Time">Part Time</option>
              <option defaultValue = "Casual">Casual</option>
              <option defaultValue = "Contract">Contract</option>
            </select> 
          </td>
        </tr>
        )

        previous_employments.push(
          <tr>
          <td>Date ended</td>

          {
                  "employment_end_day" in this.state.applicant1[key] && !("date_ended" in this.state.applicant1[key]) && this.props.showButtons == "false" ?
                  <td>            <input required class="w20" type="text" name="" defaultValue="" value={this.state.applicant1[key].employment_end_day || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employment_end_day')} /> / <input required class="w20" value={this.state.applicant1[key].employment_end_month || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employment_end_month')} type="text" name="" defaultValue="" /> / <input required value={this.state.applicant1[key].employment_end_year || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employment_end_year')} class="w20" type="text" name="" defaultValue="" />
                  </td>:
                  <td><DatePicker field="date_ended" required={this.fieldsRequired(key,1)} dateChange={this.previousEmploymentDateChange} applicant_number={1} objectKey={key} value = {this.state.applicant1[key].date_ended}></DatePicker>
                  </td>
            }

            {
                  "employment_end_day" in this.state.applicant2[key] && !("date_ended" in this.state.applicant2[key]) && this.props.showButtons == "false" ?
                  <td>
                  <input class="w20" type="text" name="" defaultValue="" value={this.state.applicant2[key].employment_end_day || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employment_end_day')} /> / <input class="w20" value={this.state.applicant2[key].employment_end_month || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employment_end_month')} type="text" name="" defaultValue="" /> / <input value={this.state.applicant2[key].employment_end_year || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employment_end_year')} class="w20" type="text" name="" defaultValue="" />
                  </td>:
                  <td><DatePicker field="date_ended" required={this.fieldsRequired(key,2)} dateChange={this.previousEmploymentDateChange} applicant_number={2} objectKey={key} value = {this.state.applicant2[key].date_ended}></DatePicker>
                  </td>
            }


          {/* <td>
            <input required class="w20" type="text" name="" defaultValue="" value={this.state.applicant1[key].employment_end_day || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employment_end_day')} /> / <input required class="w20" value={this.state.applicant1[key].employment_end_month || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employment_end_month')} type="text" name="" defaultValue="" /> / <input required value={this.state.applicant1[key].employment_end_year || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'1','employment_end_year')} class="w20" type="text" name="" defaultValue="" />
          </td>
  
          <td>
          <input class="w20" type="text" name="" defaultValue="" value={this.state.applicant2[key].employment_end_day || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employment_end_day')} /> / <input class="w20" value={this.state.applicant2[key].employment_end_month || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employment_end_month')} type="text" name="" defaultValue="" /> / <input value={this.state.applicant2[key].employment_end_year || ''} onChange={(e) =>this.handlePreviousEmploymentsChange(e,key,'2','employment_end_year')} class="w20" type="text" name="" defaultValue="" />
          </td> */}
        </tr>
        )
      })
      return previous_employments
    }

    addNewField = () =>{
      var keys = []
      Object.keys(this.state.applicant1).map(key =>{
        if(key.startsWith("previousemployment")){
          keys.push(key)
        }
      })
      var variable = `previousemployment_${keys.length+1}`
      var applicant1 = {...this.state.applicant1}
      var applicant2 = {...this.state.applicant2}
      
      applicant1[variable] = {
        variable:keys.length+1,
        position:"",
        income_amount_gross:"",
        income_gross_interval:"",
        income_amount_net:"",
        income_net_interval:"",
        employer_name:"",
        employer_street:"",
        employer_suburb:"",
        employer_state:"",
        employer_postcode:"",
        employer_contact_name:"",
        employer_contact_phone:"",
        employment_start_day:"",
        employment_start_month:"",
        employment_start_year:"",
        employment_status:"",
        employment_end_day:"",
        employment_end_month:"",
        employment_end_year:"",
        date_started:{
          day:"",
          month:"",
          year:""
        },
        date_ended:{
          day:"",
          month:"",
          year:""
        }
      }

      applicant2[variable] = {
        variable:keys.length+1,
        position:"",
        income_amount_gross:"",
        income_gross_interval:"",
        income_amount_net:"",
        income_net_interval:"",
        employer_name:"",
        employer_street:"",
        employer_suburb:"",
        employer_state:"",
        employer_postcode:"",
        employer_contact_name:"",
        employer_contact_phone:"",
        employment_status:"",
        date_started:{
          day:"",
          month:"",
          year:""
        },
        date_ended:{
          day:"",
          month:"",
          year:""
        }
      }

      this.setState({applicant1:applicant1,applicant2:applicant2})

    }

    deleteField = (deleteKey) =>{
      var keys = []
      Object.keys(this.state.applicant1).map(key =>{
        if(key.startsWith("previousemployment")){
          keys.push(key)
        }
      })
      var applicant1 = {...this.state.applicant1}
      var applicant2 = {...this.state.applicant2}

      delete applicant1[deleteKey]
      delete applicant2[deleteKey]
      this.setState({applicant1:applicant1,applicant2:applicant2})
      this.props.updateData({state:this.state,type:"Employment_Details"})

    }

    dateChange = (value,applicant_number,type,field) =>{
      var applicant = `applicant${applicant_number}`
      const property = {...this.state[applicant]};
      if(type != null){
        property[field][type] = value;
      }else{
        property[field] = value;
      }
      this.setState({[applicant]:property})
    
    }

    previousEmploymentDateChange = (value,applicant_number,type,field,key) =>{
      var applicant = `applicant${applicant_number}`
      const property = {...this.state[applicant]}

      if(type != null){
        property[key][field][type] = value;
      }else{
        property[key][field] = value;
      }

      this.setState({[applicant]:property})
    }


    checkHistory = () =>{
      // Check Applicant 1 AND Applicant 2;
      var app1_flag = false;
      var app2_flag = true;

      var app2_required = false;
      if(this.props.data.General_Details.applicant2.given_name != undefined){
        app2_required = true;
        app2_flag = false;
      }
      var targetYear = new Date().getFullYear() - 2;
      var previous_employments = []

      Object.keys(this.state.applicant1).forEach(key =>{
        if(key.startsWith('previousemployment')){
          previous_employments.push(key);
        }
      })

      console.log(this.state.applicant1.employment_start)
      if(this.state.applicant1.employment_start != undefined && this.state.applicant1.employment_start.year <= targetYear ){
        app1_flag = true;
      }

      if(app2_required && this.state.applicant2.employment_start != undefined && this.state.applicant2.employment_start.year <= targetYear ){
        app2_flag = true;
      }
      
      previous_employments.forEach(prev_employment =>{
        if(this.state.applicant1[prev_employment].date_started != undefined && this.state.applicant1[prev_employment].date_started.year != "" && this.state.applicant1[prev_employment].date_started.year <= targetYear){
          app1_flag = true;
        }

        if(app2_required && this.state.applicant2[prev_employment].date_started != undefined && this.state.applicant2[prev_employment].date_started.year != "" && this.state.applicant2[prev_employment].date_started.year <= targetYear){

          app2_flag = true;
        }

      })
      return [app1_flag,app2_flag]
    }

    // Make required if Previons Position is inputed.


    render(){
        return(
            <div>
              {this.renderErrors()}
              <form onSubmit = {(e) =>{this.handleSubmit(e)}} onChange = {this.formChange}>
        <table class="table">
          <thead>
            <tr>
              <th colspan="3">Current Employment Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td><b>Applicant 1</b></td>
              <td><b>Applicant 2</b></td>
            </tr>

            <tr>
              <td>Current position / occupation</td>
              <td><input tabindex="1"  required type="text" value={this.state.applicant1.position || ''} onChange={(e) =>this.handleInputChange(e,'1','position')} name="" defaultValue="" /></td>
              <td><input type="text" value={this.state.applicant2.position || ''} onChange={(e) =>this.handleInputChange(e,'2','position')} name="" defaultValue="" /></td>
            </tr>

            <tr>
              <td>Income Gross</td>
              <td>
                <div class="flex-input priceInput ml-0">
                  <CurrencyInput tabindex="2"  prefix="$" required={this.state.applicant1.income_amount_net == "" || this.state.applicant1.income_amount_net == undefined  ? true :false} decimalsLimit={0} value={this.state.applicant1.income_amount_gross || ""} onValueChange={(value, name) => this.handleInputChange(value,'1','income_amount_gross',true)}  name="" defaultValue={0} />
                </div>

                <div className="flex-input" style={{display:"inline-flex", width:"50%"}}>
                  <select required className="ui dropdown form-dropdown" value = {this.state.applicant1.income_gross_interval || ''}onChange={(e) =>this.handleInputChange(e,'1','income_gross_interval')}>
                    <option defaultValue = ""></option>
                    <option defaultValue = "A">Annually</option>
                    <option defaultValue = "M">Monthly</option>
                    <option defaultValue = "F">Fortnightly</option>
                    <option defaultValue = "W">Weekly</option>
                  </select>
                </div>
              </td>
              <td>
                <div class="flex-input priceInput ml-0">
                  <CurrencyInput prefix="$" decimalsLimit={0} value={this.state.applicant2.income_amount_gross || ""} onValueChange={(value, name) => this.handleInputChange(value,'2','income_amount_gross',true)}  name="" defaultValue={0} />

                </div>
                <div className="flex-input" style={{display:"inline-flex", width:"50%"}}>
                  <select className="ui dropdown form-dropdown" value = {this.state.applicant2.income_gross_interval || ''}onChange={(e) =>this.handleInputChange(e,'2','income_gross_interval')}>
                    <option defaultValue = ""></option>
                    <option defaultValue = "A">Annually</option>
                    <option defaultValue = "M">Monthly</option>
                    <option defaultValue = "F">Fortnightly</option>
                    <option defaultValue = "W">Weekly</option>
                  </select>
                </div>
              </td>
            </tr>

            <tr>
              <td>Income Net</td>
              <td>
                <div class="flex-input priceInput ml-0">
                  <CurrencyInput tabindex="3"  required={this.state.applicant1.income_amount_gross == "" || this.state.applicant1.income_amount_gross == undefined  ? true :false} prefix="$" decimalsLimit={0} value={this.state.applicant1.income_amount_net || ""} onValueChange={(value, name) => this.handleInputChange(value,'1','income_amount_net',true)}  name="" defaultValue={0} />

                </div>

                <div className="flex-input" style={{display:"inline-flex", width:"50%"}}>
                  <select className="ui dropdown form-dropdown" value = {this.state.applicant1.income_net_interval || ''}onChange={(e) =>this.handleInputChange(e,'1','income_net_interval')}>
                    <option defaultValue = ""></option>
                    <option defaultValue = "A">Annually</option>
                    <option defaultValue = "M">Monthly</option>
                    <option defaultValue = "F">Fortnightly</option>
                    <option defaultValue = "W">Weekly</option>
                  </select>
                </div>
              </td>
              <td>
                <div class="flex-input priceInput ml-0">
                  <CurrencyInput prefix="$" decimalsLimit={0} value={this.state.applicant2.income_amount_net || ""} onValueChange={(value, name) => this.handleInputChange(value,'2','income_amount_net',true)}  name="" defaultValue={0} />

                </div>

                <div className="flex-input" style={{display:"inline-flex", width:"50%"}}>
                  <select className="ui dropdown form-dropdown" value = {this.state.applicant2.income_net_interval || ''}onChange={(e) =>this.handleInputChange(e,'2','income_net_interval')}>
                    <option defaultValue = ""></option>
                    <option defaultValue = "A">Annually</option>
                    <option defaultValue = "M">Monthly</option>
                    <option defaultValue = "F">Fortnightly</option>
                    <option defaultValue = "W">Weekly</option>
                  </select>
                </div>


              </td>
            </tr>

            <tr>
              <td>Name and address of current employer</td>
              <td>
                <div class="flex-input">
                  <label for="">Name</label>
                  <input tabindex="4"  required type="text" name="" defaultValue="" value={this.state.applicant1.employer_name || ''} onChange={(e) =>this.handleInputChange(e,'1','employer_name')}  />
                </div>
              </td>
              <td>
                <div class="flex-input">
                  <label for="">Name</label>
                  <input type="text" name="" defaultValue="" value={this.state.applicant2.employer_name || ''} onChange={(e) =>this.handleInputChange(e,'2','employer_name')}  />
                </div>
              </td>
            </tr>

            <tr>
              <td>&nbsp;</td>
              <td>
                <div class="flex-input">
                  <label for="">Street</label>
                  <input tabindex="5"  required type="text" name="" defaultValue="" value={this.state.applicant1.street || ''} onChange={(e) =>this.handleInputChange(e,'1','street')}  />
                </div>
              </td>
              <td>
                <div class="flex-input">
                  <label for="">Street</label>
                  <input type="text" name="" defaultValue="" value={this.state.applicant2.street || ''} onChange={(e) =>this.handleInputChange(e,'2','street')} />
                </div>
              </td>
            </tr>

            <tr>
              <td>&nbsp;</td>
              <td>
                <div class="flex-input">
                  <label for="">Suburb</label>
                  <input tabindex="6"  required type="text" name="" defaultValue="" value={this.state.applicant1.suburb || ''} onChange={(e) =>this.handleInputChange(e,'1','suburb')} />
                </div>
              </td>
              <td>
                <div class="flex-input">
                  <label for="">Suburb</label>
                  <input type="text" name="" defaultValue="" value={this.state.applicant2.suburb || ''} onChange={(e) =>this.handleInputChange(e,'2','suburb')} />
                </div>
              </td>
            </tr>

            <tr>
              <td>&nbsp;</td>
              <td>
                <div class="grid4060">
                <div class="flex-input">
                    <label for="">State</label>
                    <select className="ui dropdown form-dropdown" value = {this.state.applicant1.state || ''} onChange={(e) =>this.handleInputChange(e,'1','state')}>
                        <option defaultValue = ""></option>
                        <option defaultValue = "NSW">NSW</option>
                        <option defaultValue = "QLD">QLD</option>
                        <option defaultValue = "SA">SA</option>
                        <option defaultValue = "VIC">VIC</option>
                        <option defaultValue = "WA">WA</option>
                        <option defaultValue = "ACT">ACT</option>
                        <option defaultValue = "NT">NT</option>
                      </select> 
                  </div>
                  <div class="flex-input">
                    <label for="">Postcode</label>
                    <input tabindex="7"  type="text" name="" defaultValue="" value={this.state.applicant1.postcode || ''} onChange={(e) =>this.handleInputChange(e,'1','postcode')} />
                  </div>
                </div>
              </td>
              <td>
                <div class="grid4060">
                <div class="flex-input">
                    <label for="">State</label>
                    <select className="ui dropdown form-dropdown" value = {this.state.applicant2.state || ''} onChange={(e) =>this.handleInputChange(e,'2','state')}>
                        <option defaultValue = ""></option>
                        <option defaultValue = "NSW">NSW</option>
                        <option defaultValue = "QLD">QLD</option>
                        <option defaultValue = "SA">SA</option>
                        <option defaultValue = "VIC">VIC</option>
                        <option defaultValue = "WA">WA</option>
                        <option defaultValue = "ACT">ACT</option>
                        <option defaultValue = "NT">NT</option>
                      </select> 
                  </div>
                  <div class="flex-input">
                    <label for="">Postcode</label>
                    <input type="text" name="" defaultValue="" value={this.state.applicant2.postcode || ''} onChange={(e) =>this.handleInputChange(e,'2','postcode')} />
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>Employer contact name and phone no.</td>
              <td>
                <div class="flex-input">
                  <label for="">Name</label>
                  <input tabindex="8"  required type="text" name="" defaultValue="" value={this.state.applicant1.employer_contact_name || ''} onChange={(e) =>this.handleInputChange(e,'1','employer_contact_name')} />
                </div>
                <div class="flex-input mt-5">
                  <label for="">Phone</label>
                  <input tabindex="9"   type="text" name="" defaultValue="" value={this.state.applicant1.employer_contact_phone|| ''} onChange={(e) =>this.handleInputChange(e,'1','employer_contact_phone')} />
                </div>
              </td>
              <td>
                <div class="flex-input">
                  <label for="">Name</label>
                  <input   type="text" name="" defaultValue="" value={this.state.applicant2.employer_contact_name || ''} onChange={(e) =>this.handleInputChange(e,'2','employer_contact_name')} />
                </div>
                <div class="flex-input mt-5">
                  <label for="">Phone</label>
                  <input type="text" name="" defaultValue="" value={this.state.applicant2.employer_contact_phone|| ''} onChange={(e) =>this.handleInputChange(e,'2','employer_contact_phone')} />
                </div>
              </td>
            </tr>

            <tr>
              <td>Date started</td>
              {
                  "employment_start_day" in this.state.applicant1&& !("employment_start" in this.state.applicant1) && this.props.showButtons == "false" ?
                  <td><input placeholder="DD" required class="w20" type="text" name="" defaultValue="" value={this.state.applicant1.employment_start_day || ''} onChange={(e) =>this.handleInputChange(e,'1','employment_start_day')} /> / <input placeholder="MM" required class="w20" value={this.state.applicant1.employment_start_month || ''} onChange={(e) =>this.handleInputChange(e,'1','employment_start_month')} type="text" name="" defaultValue="" /> / <input placeholder="YYYY" required value={this.state.applicant1.employment_start_year || ''} onChange={(e) =>this.handleInputChange(e,'1','employment_start_year')} class="w20" type="text" name="" defaultValue="" />
                  </td>:
                  <td><DatePicker field="employment_start" required={true} dateChange={this.dateChange} applicant_number={1} value = {this.state.applicant1.employment_start}></DatePicker>
                  </td>
            }

            {
                  "employment_start_day" in this.state.applicant2 && !("moved_in" in this.state.applicant2) && this.props.showButtons == "false" ?
                <td><input placeholder="DD" class="w20" type="text" name="" defaultValue="" value={this.state.applicant2.employment_start_day || ''} onChange={(e) =>this.handleInputChange(e,'2','employment_start_day')} /> / <input placeholder="MM" class="w20" value={this.state.applicant2.employment_start_month || ''} onChange={(e) =>this.handleInputChange(e,'2','employment_start_month')} type="text" name="" defaultValue="" /> / <input placeholder="YYYY" value={this.state.applicant2.employment_start_year || ''} onChange={(e) =>this.handleInputChange(e,'2','employment_start_year')} class="w20" type="text" name="" defaultValue="" />
                </td>:
                  <td><DatePicker field="employment_start" required={false} dateChange={this.dateChange} applicant_number={2}  value = {this.state.applicant2.employment_start}></DatePicker>
                  </td>
            }


              {/* <td>
                <input placeholder="DD" required class="w20" type="text" name="" defaultValue="" value={this.state.applicant1.employment_start_day || ''} onChange={(e) =>this.handleInputChange(e,'1','employment_start_day')} /> / <input placeholder="MM" required class="w20" value={this.state.applicant1.employment_start_month || ''} onChange={(e) =>this.handleInputChange(e,'1','employment_start_month')} type="text" name="" defaultValue="" /> / <input placeholder="YYYY" required value={this.state.applicant1.employment_start_year || ''} onChange={(e) =>this.handleInputChange(e,'1','employment_start_year')} class="w20" type="text" name="" defaultValue="" />
              </td>

              <td>
                <input placeholder="DD" class="w20" type="text" name="" defaultValue="" value={this.state.applicant2.employment_start_day || ''} onChange={(e) =>this.handleInputChange(e,'2','employment_start_day')} /> / <input placeholder="MM" class="w20" value={this.state.applicant2.employment_start_month || ''} onChange={(e) =>this.handleInputChange(e,'2','employment_start_month')} type="text" name="" defaultValue="" /> / <input placeholder="YYYY" value={this.state.applicant2.employment_start_year || ''} onChange={(e) =>this.handleInputChange(e,'2','employment_start_year')} class="w20" type="text" name="" defaultValue="" />
              </td> */}
            </tr>

            <tr>
              <td>Employment Status</td>

              <td>
                <select className="ui dropdown form-dropdown" value = {this.state.applicant1.employment_status || ''} onChange={(e) =>this.handleInputChange(e,'1','employment_status')}>
                  <option defaultValue = ""></option>
                  <option defaultValue = "Full Time">Full Time</option>
                  <option defaultValue = "Part Time">Part Time</option>
                  <option defaultValue = "Casual">Casual</option>
                  <option defaultValue = "Contract">Contract</option>
                </select> 
              </td>

              <td>
                <select className="ui dropdown form-dropdown" value = {this.state.applicant2.employment_status || ''} onChange={(e) =>this.handleInputChange(e,'2','employment_status')}>
                  <option defaultValue = ""></option>
                  <option defaultValue = "Full Time">Full Time</option>
                  <option defaultValue = "Part Time">Part Time</option>
                  <option defaultValue = "Casual">Casual</option>
                  <option defaultValue = "Contract">Contract</option>
                </select> 
              </td>
            </tr>

            <tr>
              <td>On probation?</td>
              <td>
                <span>
                  <input tabindex="10"  type="radio" id="Yes5" checked={this.state.applicant1.probation === "Yes"} onChange={(e) =>this.handleInputChange(e,'1','probation')} name="onprobation" defaultValue="Yes" />
                  <label for="Yes5">Yes</label>
                </span>
                <div class="flex-input priceInput mb-0">
                  <label for="">Time left:</label>
                  <input type="text" name="" value={this.state.applicant1.probation_time_left || ''} onChange={(e) =>this.handleInputChange(e,'1','probation_time_left')} defaultValue="" />
                </div>
                <span>
                  <input type="radio" id="No5" checked={this.state.applicant1.probation === "No"} onChange={(e) =>this.handleInputChange(e,'1','probation')} name="onprobation" defaultValue="No" />
                  <label for="No5">No</label>
                </span>
              </td>
              <td>
                <span>
                  <input type="radio" checked={this.state.applicant2.probation === "Yes"} onChange={(e) =>this.handleInputChange(e,'2','probation')} id="Yes6" name="onprobation1" defaultValue="Yes" />
                  <label for="Yes6">Yes</label>
                </span>
                <div class="flex-input priceInput mb-0">
                  <label for="">Time left:</label>
                  <input type="text" name="" defaultValue="" value={this.state.applicant2.probation_time_left || ''} onChange={(e) =>this.handleInputChange(e,'2','probation_time_left')}  />
                </div>
                <span>
                  <input type="radio" checked={this.state.applicant2.probation === "No"} onChange={(e) =>this.handleInputChange(e,'2','probation')} id="No6" name="onprobation1" defaultValue="No" />
                  <label for="No6">No</label>
                </span>
              </td>
            </tr>
            </tbody>
        </table>

        <table class="table">
        <thead>
            <tr>
              <th colspan="3">Previous Employment - 3 Year History</th>
            </tr>
          </thead>
          <tbody>
          {this.renderPreviousEmployments()}
          <button className="ui button add-field" type="button" onClick={(e) =>{this.addNewField()}}><i class="plus circle icon"></i>Add Previous Employment</button>

          </tbody>
        </table>
            
           

        <table class="table">
          <thead>
            <tr>
              <th>Other Income details <small>(if applicable)</small> - Bonuses, Commission, Fringe Benefits, Centrelink etc (specify and estimate value)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><textarea lines="5" style={{width:"100%",height:"150px"}} value={this.state.other_income_details || ''} onChange={(e) =>this.setState({other_income_details:e.target.value})} type="text" name="" defaultValue="" /></td>
            </tr>
          </tbody>
        </table>
          {this.renderButtons()}
          </form>
            </div>
        )

    }
}


const mapStateToProps = (state) =>{
  return {data:state.data}
}

export default connect(mapStateToProps,{updateData})(Employment_Details)