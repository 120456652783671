import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addClient } from '../../../actions'

import $ from "jquery";
import 'bootstrap'
import server from '../../../api/server';


export class ResetDataModal extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            factfind: false,
            voi: false,
            files: false,
            bankstatements:false,
        }
    }

    formSubmit = async (e) =>{
        e.preventDefault()

        var response = await server.post("/admin/application/reset",{id:this.props.user.token,data:this.state,application_id:this.props.admin.active_application.application.id})
        this.setState({
            factfind: false,
            voi: false,
            files: false
       })
       window.location.reload()

        window.$("#ResetDataModal").modal("hide")
        window.$('.modal-backdrop').remove()
    }
    
    render() {
        return (
            <div>
            <div class="modal fade" id="ResetDataModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog " role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Reset Data</h5>
                            <button onClick={() => { window.$("#ResetDataModal").modal("hide"); window.$('.modal-backdrop').remove(); }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <i aria-hidden="true" class="ki ki-close"></i>
                            </button>
                        </div>
                        <form onSubmit={(e) =>{this.formSubmit(e)}}>
                        <div class="modal-body">
                            <div>
                                <p>Select the fields you wish to reset the data for.</p>
                            <div className=' mb-3'>
                        <div className='checkbox-list'>

                            <label class="checkbox">
                                <input value={this.state.voi} type="checkbox" name={`reset_voi`} checked={this.state.voi == true ? "checked" : ""} onChange={(e) => {
                                    this.setState({ voi: !this.state.voi })
                                }} />
                                <span></span>
                                Verification of Identity
                            </label>

                            <label class="checkbox">
                                <input value={this.state.files} type="checkbox" name={`reset_files`} checked={this.state.files == true ? "checked" : ""} onChange={(e) => {
                                    this.setState({ files: !this.state.files })
                                }} />
                                <span></span>
                                Document Collection
                            </label>

                            <label class="checkbox">
                                <input value={this.state.bankstatements} type="checkbox" name={`reset_bank`} checked={this.state.bankstatements == true ? "checked" : ""} onChange={(e) => {
                                    this.setState({ bankstatements: !this.state.bankstatements })
                                }} />
                                <span></span>
                                Bank Statements
                            </label>

                        </div>

                    </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal" onClick={() => { window.$("#ResetDataModal").modal("hide"); window.$('.modal-backdrop').remove(); }}>Close</button>
                            <button type="submit" class="btn btn-primary font-weight-bold">Reset Data</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user:state.user,
    admin:state.admin
})

const mapDispatchToProps = {
    addClient
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetDataModal)
