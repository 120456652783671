import update from 'immutability-helper';


const INIT_STATE = {
    id: null,
    name: "",
    file_upload_settings: {
        files: []
    }
}


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_ORGANISATION':
            return update(state, {
                $set:action.payload
                // name: { $set: action.payload.name },
                // email: { $set: action.payload.email },
                // token: { $set: action.payload.token }
            })

        default:
            return state
    }
}
