import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Document, Page } from 'react-pdf';
import dummyPDF from "./dummy.pdf";
import server from '../../../api/server';
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { pdfjs } from 'react-pdf';
import "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();
export class BankStatementPDF extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loaded: false
        };
    }


    componentDidMount() {
        this.fetchPDFData();
    }

    fetchPDFData = async () => {
        var data = await server.post("/bankstatements/pdf", {
            uri: this.props.uri,
            applicant_id: this.props.applicant_id,
            application_id: this.props.active_application.application.id
        }, { responseType: "blob" });

        var blob = new Blob([data.data], { type: 'application/pdf' });
        var url = URL.createObjectURL(blob);
        this.setState({
            data: url,
            loaded: true
        });
        // var blob = new Blob([buffer], { type: 'application/pdf' });


        // var buffer = Buffer.from(data.data.data, 'base64');
        // var blob = new Blob([buffer], { type: 'application/pdf' });
        // var url = URL.createObjectURL(blob);
        // this.setState({
        //     data: url,
        //     loaded: true
        // });
    };


    render() {
        return (
            <div className='hover:cursor-pointer hover:opacity:70 h-[250px] hover:opacity-70'>
                <Document file={this.state.data} onClick={() => {
                    // Download File
                    var link = document.createElement('a');
                    link.href = this.state.data;
                    var name = this.props.uri.split("/").pop();
                    link.download = name;
                    link.click();
                }} >

                    <Page renderTextLayer={false} height="250" pageNumber={1} />
                </Document>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    active_application: state.admin.active_application,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BankStatementPDF);