import React, { Component } from 'react'
import { connect } from 'react-redux'
import BankStatements from './elements/BankStatements'
import RequestDocCollection from './elements/RequestDocCollection'
import EditDocCollectionModal from './elements/EditDocCollectionModal'
import server from '../../api/server'
import RequestBankStatements from './RequestBankStatements'




export class BankStatementCollection extends Component {

    constructor(props) {
        super(props)

        this.state = {
            active_applicant:null
        }
    }




    renderApplicants = () =>{
        var applicants = []
        Object.keys(this.props.bankstatements.applicants).forEach(key =>{
            applicants.push(
                <BankStatements applicant_id={key} bankstatements={true} />
            )
            // ))
        })

        return applicants;
    }

    render() {
        if (this.props.bankstatements != null) {
            return (
                <div className="row ">
                    <EditDocCollectionModal/>
                <div className="col-lg-12">
                    <div className="card card-custom gutter-b">
                        <div class="card-header card-header-tabs-line">
                            <div class="card-title">
                                <h3 class="card-label font-weight-bolder text-dark">Bank Statements</h3>
                            </div>
                            <div class="card-toolbar">
                                <button className='btn btn-info mr-5' onClick={async () =>{
                                var response = await server.post("/bankstatements/applicant/all", {
                                    id: this.props.user.token, 
                                    path: `${this.props.active_application.application.id}`,
                                   bankstatement_id: this.props.bankstatements.id,
                                   }, { responseType: "blob" });
                               let blob = new Blob([response.data], {
                                   type: "application/zip"
                                   //               OR
                                   // type:"application/zip, application/octet-stream"
                               });
                               let objectUrl = URL.createObjectURL(blob);
                               let link = document.createElement('a');
                               link.href = objectUrl;
                               link.download = `${this.props.active_application.application.name}_bank_statements.zip`;
                               link.click();
                                        
                                }}>Download All</button>
                                {/* <ul class="nav nav-tabs nav-bold nav-tabs-line">
                                    {this.renderApplicants()}
                                </ul> */}
                            </div>
                        </div>

                        <div className="card-body">
                                {this.renderApplicants()}
                                {this.state.active_applicant != null ? <BankStatements applicant_id={this.state.active_applicant} /> : null}

                        </div>
                    </div>
                </div>
            </div>
            )
        } else {
            return (
                <RequestBankStatements/>
            )
        }

    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    bankstatements: state.admin.active_application.bankstatements,
    active_application: state.admin.active_application,
    organisation:state.organisation
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BankStatementCollection)