import React from 'react';
import {connect} from 'react-redux'

import {Link} from "react-router-dom"

class Nav extends React.Component{
  componentDidMount() {
      console.log(this.props.form)
  }

  constructor(props){
    super(props)
    this.state = {
        isHovering:false
    }
  }

  componentDidUpdate = ()=>{
  }

  handleMouseHover = () => {
    this.setState(this.toggleHoverState);
  }

  toggleHoverState(state) {
    return {
      isHovering: !state.isHovering,
    };
  }

  renderDropDown = () =>{
      if(this.state.isHovering){
        return(
            <div className="header-dropdown">
                <div className="header-dropdown-header">
                    <small>Your Brokers</small>
                    <p>Ryan & Paul Pappas</p>
                </div>
    
                <div className="header-dropdown-content">
                    <p>
                    Loan Gateway is a secure site to share your personal details needed so we can assist with your enquiry. This is your unique link, so please don't share this with anyone else. You can return at anytime to update your details. 
                                                </p>
    
                    <p>
                        Give me a call if you need any further assistance!
                    </p>
                </div>
    
                <div className="header-dropdown-footer">
                    <Link className="" to={`/privacy`} target="_blank">Privacy Policy</Link>
                    <Link className="" to={"/security"} target="_blank">Security</Link>
                </div>
            </div>
            )

      }else{
          return(null)
      }
  }

  renderProgress = () =>{
      var progress_bars = []
      var progress_bar_count =0
      if(this.props.form == "General_Details"){progress_bar_count = 1}
      if(this.props.form == "Address_3y"){progress_bar_count = 2}
      if(this.props.form == "Employment_Details"){progress_bar_count = 3}
      if(this.props.form == "Income"){progress_bar_count = 4}
      if(this.props.form == "Liabilities_Debts"){progress_bar_count = 5}
      if(this.props.form == "Assets"){progress_bar_count = 6}
      if(this.props.form == "Loan_Requirements"){progress_bar_count = 7}
  
      
    for (let index = 0; index < progress_bar_count; index++) {
        progress_bars.push(<li><div className="progress-completed"></div></li>)
    }

    if(progress_bar_count <7){
        for (let index = 0; index < (7-progress_bar_count); index++) {
            progress_bars.push(<li></li>)
            
        }
    }
      
      if(this.props.showProgress == "true"){
        return(
            <div className="top-menu-progress">
                <ul>
                    {progress_bars}
                </ul>
            </div>
          )
      }else{
          return(null)
      }
  }


render(){
  return(
      <div className="top-menu">
          <div className="top-menu-container">
              <div className="logo-container left-item">
                <Link to={`/dashboard/${this.props.id}`}><img style={{width:"145px"}} src={process.env.PUBLIC_URL + '/images/logo-small.png'}></img></Link>
              </div>

              <div className="broker-container" onMouseEnter={this.handleMouseHover} onMouseLeave={this.handleMouseHover}>
                  <div className="broker">
                    <img className="broker-image" src={process.env.PUBLIC_URL + '/images/brokers.png'}></img>

                  </div>

                  {this.renderDropDown()}
              </div>


              <div className="right-item">
                  <div>
                  <span style={{fontWeight:"100"}}>Need help?</span><button className="circular basic ui button">Call 02 9231 0511</button>
                  </div>
              </div>
          </div>

          {this.renderProgress()}

            {/* <div className="left item logo-item">
                <img className="menu-logo" src={process.env.PUBLIC_URL + '/images/logo-small.svg'}></img>
            </div>

            <div className="item">
                <div className="broker-image-container">
                    <img className="broker-image" src={process.env.PUBLIC_URL + '/images/ryan.pappas.jpg'}></img>
                </div>
            </div>
            <div className="right item">
                <span>Test</span>
            </div> */}
      </div>

  )
}
}

const mapStateToProps = (state) =>{
  return {id:state.id}
}

export default connect(mapStateToProps,{})(Nav)
