import React from 'react';
import { connect } from 'react-redux';

import { fetchForms } from '../../actions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import server from '../../api/server';
import firebase from './../Firebase.js';
import history from '../../history';


class NewForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: "Active_Forms",
            full_name: "",
            email: "",
            loading: false
        };
    }
    componentDidMount() {

    }

    formSubmit = async (e) => {
        e.preventDefault();
        if (this.state.full_name != "" && this.state.email != "") {
            firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(async (idToken) => {
                this.setState({ loading: true, archiveStatus: true });
                await server.post("/admin/newForm", { id: idToken, name: this.state.full_name, email: this.state.email });
                this.props.changeMenu('Active_Forms');
            }).catch(function (error) {
                history.push("/login");
            });
        }
    };

    render() {
        console.log(this.props);
        return (
            <div>
                <h1>New Form</h1>

                <form class="ui form" onSubmit={(e) => { this.formSubmit(e); }}>
                    <div class="field">
                        <label>Full Name</label>
                        <input required type="text" value={this.state.full_name} onChange={(e) => { this.setState({ full_name: e.target.value }); }} name="full-name" placeholder="Full Name"></input>
                    </div>
                    <div class="field">
                        <label>Email</label>
                        <input required type="text" value={this.state.email} name="email" onChange={(e) => { this.setState({ email: e.target.value }); }} placeholder="email"></input>
                    </div>
                    <button className={`ui button ${this.state.loading ? 'disabled' : ''}`} type="submit">Create</button>
                </form>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return { forms: state.forms };
};

export default connect(mapStateToProps, { fetchForms })(NewForm);
