import React from 'react';
import {connect} from 'react-redux'
import firebase from './Firebase.js'

import Header from './form/Header'
import General_Details from './form/General_Details'
import Address_3y from './form/Address_3_year_history';
import Employment_Details from './form/Employment_Details';
import Income from './form/Income'
import Liabilities_Debts from './form/Liabilities_Debts'
import Assets from './form/Assets'
import Loan_Requirements from './form/Loan_Requirements'
import Nav from './Nav'
import {fetchForm} from '../actions'
import {Helmet} from "react-helmet";

class Form extends React.Component{
  componentDidMount() {
    this.props.fetchForm(this.props.match.params.id)
  }

  constructor(props){
    super(props)
    this.state = {
      form:"General_Details",

    }
  }

  componentDidUpdate(){
    this.props.fetchForm(this.props.match.params.id)
  }

  changeForm = (form) =>{
    this.setState({form:form})
  }


  renderContent = () =>{
    if(this.props.id == 'no-form'){
      return('No-form')
    }else if(this.props.id != null){
      if(this.state.form== "General_Details"){
        return(
          <div>
            <section className="main-wrap">
            <Header show ="true" percent="15" changeForm={this.changeForm}></Header>
            <General_Details changeForm={this.changeForm}></General_Details>
            </section>
          </div>
        )
      }else if(this.state.form == "Address_3y"){
        return(
          <div>
            <section className="main-wrap">
            <Header percent="30" changeForm={this.changeForm}></Header>
            <Address_3y changeForm={this.changeForm}></Address_3y>
            </section>
          </div>
        )
      }else if(this.state.form == "Employment_Details"){
        return(
          <div>
            <section className="main-wrap">
            <Header percent="45" changeForm={this.changeForm}></Header>
            <Employment_Details changeForm={this.changeForm}></Employment_Details>
            </section>
          </div>
        )
      }else if(this.state.form == "Income"){
        return(
          <div>
            <section className="main-wrap">
            <Header percent="60" changeForm={this.changeForm}></Header>
            <Income changeForm={this.changeForm}></Income>
            </section>
          </div>
        )
      }else if(this.state.form == "Liabilities_Debts"){
        return(
          <div>
            <section className="main-wrap">
            <Header percent="75" changeForm={this.changeForm}></Header>
            <Liabilities_Debts changeForm={this.changeForm}></Liabilities_Debts>
            </section>
          </div>
        )
      }else if(this.state.form == "Assets"){
        return(
          <div>
            <section className="main-wrap">
            <Header percent="90" changeForm={this.changeForm}></Header>
            <Assets changeForm={this.changeForm}></Assets>
            </section>
          </div>
        )
      }else if(this.state.form == "Loan_Requirements"){
        return(
          <div>
            <section className="main-wrap">
            <Header percent="100" changeForm={this.changeForm}></Header>
            <Loan_Requirements changeForm={this.changeForm}></Loan_Requirements>
            </section>
          </div>
        )
      }else if(this.state.form == "Completed_Form"){
        return(
        <div className="form-loading" >
          <div className="form-complete">
          <img style={{height:"150px",display:"block",margin:"auto"}} src={process.env.PUBLIC_URL + '/images/logo.png'} alt="" />
          <h1 style={{textAlign:"center"}}>Thank you!</h1>
          </div>

      </div>)
      }
    }else{
      return(<div className="form-loading">
        <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="" />
      </div>)
    }
  }
render(){
  return(
    <div>
      <Helmet>
        <title>Fact Find - Mortgage Choice Sydney CBD</title>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" integrity="sha512-8bHTC73gkZ7rZ7vpqUQThUDhqcNFyYi2xgDgPDHc+GXVGHXq+xPjynxIopALmOPqzo9JZj0k6OqqewdGO3EsrQ==" crossorigin="anonymous" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/components/grid.min.css" integrity="sha512-RtktBL0Hhw9K2e2rGLZGadK8cF6Q0wKxRUkfh4pJszwnarmh3GbqHCiLm3UZFA1dRiFqtPkVrcby0hXWsqoDNA==" crossorigin="anonymous" />
            <link rel="stylesheet" href={process.env.PUBLIC_URL + `/css/style.css`}/> 

      </Helmet>
      <Nav showProgress="true" form={this.state.form}></Nav>
      <div className="top-left-buttons">
      {/* <a className="circular basic ui button" href={`${process.env.REACT_APP_DOMAIN}pdfs/Credit_Guide.pdf`} target="_blank">Credit Guide</a>
      <a className="circular basic ui button" href={`${process.env.REACT_APP_DOMAIN}pdfs/Privacy_Policy.pdf`} target="_blank">Privacy Policy</a> */}
        {/* <div style={{float:"right"}}>
          <span style={{fontWeight:"100"}}>Need help?</span><button className="circular basic ui button">Call 0415 772 392</button>

        </div> */}
      </div>      
      {this.renderContent()}
      <div style={{opacity:"0.7",textAlign:"center",padding:"15px"}}>
        <span>
        Copyright 2022 CBD Mortgage Advisors Pty Ltd | ABN 87 636 147 496 | Suite 2, Level 2, 88 Pitt Street SYDNEY NSW 2000
        </span>
      </div>
    </div>
  )
}
}

const mapStateToProps = (state) =>{
  return {id:state.id}
}

export default connect(mapStateToProps,{fetchForm})(Form)
