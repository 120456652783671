import React, { Component } from "react";
import { connect } from "react-redux";

import { Helmet } from "react-helmet";
import  Aside  from "./newadmin/Aside";
import { Header } from "./newadmin/Header";
import { SubHeader } from "./newadmin/SubHeader";
import  Applications  from "./newadmin/Applications";

import {fetchForm,fetchApplications} from '../actions'
import firebase from './Firebase.js'
import history from '../history'
import  ClientPage from "./newadmin/ClientPage";
import { DashboardContainer } from "./newadmin/DashboardContainer";



export class NewAdmin extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             loggedin:false
        }
    }




    renderHelmet = () =>{

        return(
            <Helmet defer={false}>

            <meta charset="utf-8" />
            <title>Admin | LoanGateway</title>
            <meta name="description" content="Page with empty content" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <link rel="canonical" href="https://keenthemes.com/metronic" />

            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"
            />

            <link
                href={
                    process.env.PUBLIC_URL +
                    `/metronic/assets/plugins/custom/fullcalendar/fullcalendar.bundle.css`
                }
                rel="stylesheet"
                type="text/css"
            />

            <link
                href={
                    process.env.PUBLIC_URL +
                    "/metronic/assets/plugins/global/plugins.bundle.css"
                }
                rel="stylesheet"
                type="text/css"
            />
            <link
                href={
                    process.env.PUBLIC_URL +
                    "/metronic/assets/plugins/custom/prismjs/prismjs.bundle.css"
                }
                rel="stylesheet"
                type="text/css"
            />

            <link
                href={
                    process.env.PUBLIC_URL +
                    "/metronic/assets/css/themes/layout/header/base/light.css"
                }
                rel="stylesheet"
                type="text/css"
            />
            <link
                href={
                    process.env.PUBLIC_URL +
                    "/metronic/assets/css/themes/layout/header/menu/light.css"
                }
                rel="stylesheet"
                type="text/css"
            />
            <link
                href={
                    process.env.PUBLIC_URL +
                    "/metronic/assets/css/themes/layout/brand/dark.css"
                }
                rel="stylesheet"
                type="text/css"
            />
            <link
                href={
                    process.env.PUBLIC_URL +
                    "/metronic/assets/css/themes/layout/aside/dark.css"
                }
                rel="stylesheet"
                type="text/css"
            />
                                        <link
                href={
                    process.env.PUBLIC_URL + "/metronic/assets/css/style.bundle.css"
                }
                rel="stylesheet"
                type="text/css"
            />

            <link rel="shortcut icon" href="/assets/media/logos/favicon.ico" />
            
        </Helmet>
        )
    }
    
    componentDidMount() {

        this.checkAuth()
        history.listen(location =>{
            console.log(location)
        })
    }

    changeMenu = (menu) =>{
        this.setState({menu:menu})
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(this.state,prevState)
    }

    checkAuth = () =>{
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                const token = await user.getIdToken();
                console.log(token)
                this.setState({loggedin:true})
                this.props.fetchApplications(token)
              // User is signed in.
            } else {
                history.push("/login")
              // No user is signed in.
            }
          });
    }

    renderPage = () =>{
        if(this.props.admin.page == "applications"){
            return(<Applications/>)
        }else if(this.props.admin.page == "client_page"){
            return(<ClientPage></ClientPage>)
        }
    }
 

    render() {
        if(this.state.loggedin){
            return (
                <DashboardContainer>
                </DashboardContainer>
            );
        }else{
            return(
                <div className="page-loading-enabled page-loading">               
                    {/* {this.renderHelmet()}      */}
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    admin:state.admin
});

const mapDispatchToProps = {
    fetchApplications
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAdmin);
