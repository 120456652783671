import React from 'react';
import {connect} from 'react-redux'

import ActiveForms from './admin/ActiveForms.js'
import NewForm from './admin/NewForm.js'
import ArchivedForms from './admin/ArchivedForms.js'
import {fetchForm} from '../actions'
import firebase from './Firebase.js'
import history from '../history'
import {Helmet} from "react-helmet"

class HomePage extends React.Component{
    componentDidMount() {

    }

    render(){
        return(
            
            <div className="form-loading">
                    <Helmet>
                        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" integrity="sha512-8bHTC73gkZ7rZ7vpqUQThUDhqcNFyYi2xgDgPDHc+GXVGHXq+xPjynxIopALmOPqzo9JZj0k6OqqewdGO3EsrQ==" crossorigin="anonymous" />
                        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/components/grid.min.css" integrity="sha512-RtktBL0Hhw9K2e2rGLZGadK8cF6Q0wKxRUkfh4pJszwnarmh3GbqHCiLm3UZFA1dRiFqtPkVrcby0hXWsqoDNA==" crossorigin="anonymous" />
                        <link rel="stylesheet" href={process.env.PUBLIC_URL + `/css/style.css`}/> 
                    </Helmet>
                <div className="form-complete">
                    <img style={{height:"150px",display:"block",margin:"auto"}} src={process.env.PUBLIC_URL + '/images/logo.png'} alt="" />
                    <h1 style={{textAlign:"center"}}>Contact ryan.pappas@mortgagechoice.com.au for a unique link.</h1>
                </div>
            </div>
        )

    }

}

const mapStateToProps = (state) =>{
  return {id:state.id}
}

export default connect(mapStateToProps,{fetchForm})(HomePage)
