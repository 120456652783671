import React, { Component } from 'react'
import { connect } from 'react-redux'
import server from "../../api/server"
import {updateBankStatements} from "../../actions"
export class RequestBankStatements extends Component {

    constructor(props) {
      super(props)
    
      this.state = {
         
      }
    }

    
	requestVOI = async () => {
		this.setState({requestDisable:true})
		const response = await server.post("/bankstatements/create", {id:this.props.user.token, application_id: this.props.id });
		// this.props.setVOIData(response.data);
        this.props.updateBankStatements(response.data)
		this.setState({requestDisable:false})
	}


  render() {
    return (
        <div className="row ">
        <div className="col-lg-12">
            <div className="card gutter-b">
                <div className="card-header">
                    <h3 className="card-label font-weight-bolder text-dark">Bank Statements</h3>
                    <span class="text-muted font-weight-bold font-size-sm mt-1">Bank Statements</span>


                </div>

                <div className="card-body">
                    <p>Click the below link to activate the illion BankStatements portal for your clients. Once you click the button below, the bank statements portal will appear on their client dashboard. </p>
                    <button disabled={this.state.requestDisable} className={`btn  btn-info ${this.state.requestDisable == true ? "spinner spinner-white spinner-right" : null}`}  onClick={(e) => { this.requestVOI() }}>Request Bank Statements</button>
                </div>
            </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => ({
    id: state.admin.active_application.application.id,
    user:state.user
})

const mapDispatchToProps = {
    updateBankStatements
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestBankStatements)