import React, { Component } from 'react'
import { connect } from 'react-redux'

import Nav from "../Nav"

import { Helmet } from "react-helmet"

import {fetchVOIData,fetchForm,updateVOIData} from "../../actions";
import server from "../../api/server"
import  VOIBox  from './VOIBox';

export class VOIClientSide extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             mobile:"",
             disabled:false
        }
    }
    
    componentDidMount() {
        // this.props.fetchForm(this.props.match.params.id)
        console.log(this.props)
        this.props.fetchForm(this.props.match.params.id)
        this.props.fetchVOIData(this.props.match.params.id);
    }

    startVOI =async  (applicant_id,name,identification_id) =>{
        this.setState({disabled:true})
        console.log(this.props.id)
        const response = await server.post("/startvoi",{id:applicant_id,mobile:this.state.mobile,name:name,identification_id:identification_id,application_id:this.props.id});
        this.props.updateVOIData(response.data);
        this.setState({disabled:false})
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.VOI != this.props.VOI){
            
        }
    }

    renderApplicants = () =>{
        const applicants = []
        if(this.props.VOI != null){
            Object.keys(this.props.VOI.applicants).forEach(key =>{
                if(this.props.VOI.applicants[key].transaction_id != undefined){
                    if(this.props.VOI.applicants[key].completed == false){
                        console.log(this.props.VOI.applicants[key])
                        applicants.push(
                            <div className="dashboard-module">
                                <div>
                                <h2>{this.props.VOI.applicants[key].name}</h2>
                                <p style={{"marginTop":"7px",marginBottom:"14px"}}>Please follow the instructions sent to your phone.</p>
                                <p style={{"marginTop":"7px",marginBottom:"14px"}}>If you did not recieve a message, click the button below on a <strong>mobile device</strong> to complete your verification of identity.</p>
                                <div>
                                <a href={`https://loangateway.au.idkit.com/${this.props.VOI.applicants[key].transaction_id}`} class="ui button dashboard-module-button" target="_blank" style={{width:"100%"}}>Complete Verification of Identity</a>
                                </div>
                                </div>
                            </div>
                        )
                    }else{
                        applicants.push(
                            <div className="dashboard-module">
                                <div>
                                    <h2>{this.props.VOI.applicants[key].name}</h2>
                                    <p style={{"marginTop":"7px",marginBottom:"14px"}}>Thank you for completing your Verification of Identity.</p>
                            
                                </div>
                            
                            </div>
                        )
                    }
                }else{
                    applicants.push(
                        <VOIBox applicant={this.props.VOI.applicants[key]}/>
                    )
                }

            })
        }
        return applicants;
    }


    render() {
        return (
            <div>
                <Helmet>
                    <title>Fact Find - Mortgage Choice Sydney CBD</title>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" integrity="sha512-8bHTC73gkZ7rZ7vpqUQThUDhqcNFyYi2xgDgPDHc+GXVGHXq+xPjynxIopALmOPqzo9JZj0k6OqqewdGO3EsrQ==" crossorigin="anonymous" />
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/components/grid.min.css" integrity="sha512-RtktBL0Hhw9K2e2rGLZGadK8cF6Q0wKxRUkfh4pJszwnarmh3GbqHCiLm3UZFA1dRiFqtPkVrcby0hXWsqoDNA==" crossorigin="anonymous" />
                    <link rel="stylesheet" href={process.env.PUBLIC_URL + `/css/style.css`} />

                </Helmet>
                <Nav showProgress="false"></Nav>

                <div>
                    <section className="main-wrap">
                        <div className="dashboard-container">
                            {this.renderApplicants()}
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
   VOI:state.VOI,
   id:state.id
})

const mapDispatchToProps = {
    fetchVOIData,
    fetchForm,
    updateVOIData
}

export default connect(mapStateToProps, mapDispatchToProps)(VOIClientSide)
