import React, { Component } from 'react'
import { connect } from 'react-redux'

export class Header extends Component {
    render() {
        return (
            
<div id="kt_header" class="header header-fixed">

    {/* <!--begin::Container--> */}
    <div class="container">

        {/* <!--begin::Left--> */}
        <div class="d-none d-lg-flex align-items-center mr-3">

            {/* <!--begin::Logo--> */}
            <a href="index.html" class="mr-20">
                <img alt="Logo" src={process.env.PUBLIC_URL + "/images/logos/light_transparent.png"} class="logo-default max-h-35px" />
            </a>

            {/* <!--end::Logo--> */}
        </div>

        {/* <!--end::Left--> */}

        {/* <!--begin::Topbar--> */}
        <div class="topbar topbar-minimize">



            {/* <!--end::Search--> */}

 
            {/* <!--end::Notifications--> */}


            {/* <!--end::Quick Actions--> */}


            {/* <!--begin::User--> */}
            <div class="dropdown">

                {/* <!--begin::Toggle--> */}
                <div class="topbar-item" data-toggle="dropdown" data-offset="0px,0px">
                    <div class="btn btn-icon btn-clean h-40px w-40px btn-dropdown">
                    <i class="fa fa-comment-alt"></i>
                    </div>
                </div>

                {/* <!--end::Toggle--> */}

                {/* <!--begin::Dropdown--> */}
                <div class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
                    <i className="fa far fa-user"></i>
                    {/* <!--[html-partial:include:{"file":"partials/_extras/dropdown/user.html"}]/--> */}
                </div>

                {/* <!--end::Dropdown--> */}
            </div>

            {/* <!--end::User--> */}
        </div>

        {/* <!--end::Topbar--> */}
    </div>

    {/* <!--end::Container--> */} */}
</div>
        )
    }
}

const mapStateToProps = (state) => ({
    requested_info:state.requested_info
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
