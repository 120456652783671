import React, { Component } from 'react';
import { connect } from 'react-redux';

import { refreshVOIData } from "../../actions";

import { Base64 } from 'js-base64';

import server from "../../api/server";

function calcCrow(lat1, lon1, lat2, lon2) {
	var R = 6371; // km
	var dLat = toRad(lat2 - lat1);
	var dLon = toRad(lon2 - lon1);
	var lat1 = toRad(lat1);
	var lat2 = toRad(lat2);

	var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	var d = R * c;
	return d;
}

function toRad(Value) {
	return Value * Math.PI / 180;
}


export class VOIData extends Component {
	constructor(props) {
		super(props);

		this.state = {
			menu: "overview"
		};
	}

	componentDidMount() {
		this.props.refreshVOIData(this.props.VOI.applicants[this.props.applicant_id].transaction_id);
	}

	renderUserDetails = () => {
		const details = [];
		const user_details = this.props.VOI.applicants[this.props.applicant_id].data.user_details;

		Object.keys(user_details).forEach(key => {
			var tag = key;
			tag = tag.split("_").join(" ");
			// If is an object or array return
			if (typeof user_details[key] == "object" || user_details[key] == null) {
				return;
			}
			if (key != "address" && user_details[key] != null) {
				details.push(
					<tr>
						<td >
							<span class="text-dark-75 font-weight-bolder d-block font-size-lg text-capitalize">{tag}</span>
						</td>
						<td className=""><span>{user_details[key]}</span></td>
					</tr>
				);
			}


		});

		return details;
	};

	renderBreakdown = () => {
		const features = this.props.VOI.applicants[this.props.applicant_id].data.likeness_features;
		const items = [];
		Object.keys(features).forEach(key => {
			var tag = key;
			tag = tag.split("_").join(" ");
			items.push(
				<div className="col-lg-4">
					<div className={`bg-${features[key] == "Pass" ? "success" : "danger"} text-white p-3 mr-1 d-flex justify-content-center ml-auto text-capitalize mb-3`} style={{ display: "flex", width: "100%" }}><span>{tag}</span></div>
				</div>
			);
		});
		return (
			<div className="row">
				{items}
			</div>
		);
	};

	downloadReport = async (name) => {
		this.setState({ pdfDisabled: true });
		const response = await server.post("/voipdf", { id: this.props.user.token, transaction_id: this.props.VOI.applicants[this.props.applicant_id].transaction_id });
		const string = "data:application/pdf," + Base64.encode(response.data.binary);

		function base64toPDF(data) {
			var bufferArray = base64ToArrayBuffer(data);
			var blobStore = new Blob([bufferArray], { type: "application/pdf" });
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blobStore);
				return;
			}
			var data = window.URL.createObjectURL(blobStore);
			var link = document.createElement('a');
			document.body.appendChild(link);
			link.href = data;
			link.download = `${name}.pdf`;
			link.click();
			window.URL.revokeObjectURL(data);
			link.remove();
		}

		function base64ToArrayBuffer(data) {
			var bString = window.atob(data);
			var bLength = bString.length;
			var bytes = new Uint8Array(bLength);
			for (var i = 0; i < bLength; i++) {
				var ascii = bString.charCodeAt(i);
				bytes[i] = ascii;
			}
			return bytes;
		};

		base64toPDF(response.data.binary);
		this.setState({ pdfDisabled: false });
		// this.setState({url:"data:application/pdf," + Base64.encode(response.data.binary)})
	};


	renderVOIContent = () => {
		if (this.state.menu == "overview") {
			return (
				<div className="tab-pan fade show active">
					<div className="d-flex">
						<h3>{this.props.VOI.applicants[this.props.applicant_id].data.user_details.first_name} {this.props.VOI.applicants[this.props.applicant_id].data.user_details.middle_name} {this.props.VOI.applicants[this.props.applicant_id].data.user_details.last_name}</h3>
					</div>
					<div className="row">
						<div className="col-lg-6">

							<div className="row">
								<div className="col-lg-6 mt-2">
									<span className="font-weight-boldest mb-1" style={{ opacity: "0.5", display: "block" }}>DOB</span>
									<span className="font-weight-normal" style={{ display: "block" }}>{this.props.VOI.applicants[this.props.applicant_id].data.user_details.date_of_birth}</span>
								</div>

								<div className="col-lg-6 mt-2">
									<span className="font-weight-boldest mb-1" style={{ opacity: "0.5", display: "block" }}>Number</span>
									<span className="font-weight-normal" style={{ display: "block" }}>61 0415772392</span>
								</div>

								<div className="col-lg-6 mt-2">
									<span className="font-weight-boldest mb-1" style={{ opacity: "0.5", display: "block" }}>Address</span>
									<span className="font-weight-normal" style={{ display: "block" }}>{this.props.VOI.applicants[this.props.applicant_id].data.user_details.address ? this.props.VOI.applicants[this.props.applicant_id].data.user_details.address.full_address : "Not Available"}</span>
								</div>

								<div className="col-lg-6 mt-2">
									<span className="font-weight-boldest mb-1" style={{ opacity: "0.5", display: "block" }}>Report</span>

									<button disabled={this.state.pdfDisabled} className={`btn  btn-success ${this.state.pdfDisabled == true ? "spinner spinner-white spinner-right" : null}`} onClick={(e) => { this.downloadReport(`${this.props.VOI.applicants[this.props.applicant_id].data.user_details.first_name} ${this.props.VOI.applicants[this.props.applicant_id].data.user_details.middle_name} ${this.props.VOI.applicants[this.props.applicant_id].data.user_details.last_name}`); }}>Download Report</button>

								</div>
							</div>
						</div>

						<div className="col-lg-6">
							<div className="">
								<div class={`bg-${this.props.VOI.applicants[this.props.applicant_id].data.results.documents == "PASSED" ? 'success' : 'danger'} text-white p-3 mr-1 d-flex justify-content-center ml-auto`} style={{ display: "flex", width: "50%" }}><span>Documents</span></div>
								<div class={`bg-${this.props.VOI.applicants[this.props.applicant_id].data.results.facematch == "PASSED" ? 'success' : 'danger'} text-white p-3 mr-1 mt-2 d-flex justify-content-center ml-auto`} style={{ display: "flex", width: "50%" }}><span>Face</span></div>
								<div class={`bg-${this.props.VOI.applicants[this.props.applicant_id].data.results.liveness == "PASSED" ? 'success' : 'danger'} text-white p-3 mr-1 mt-2 d-flex justify-content-center ml-auto`} style={{ display: "flex", width: "50%" }}><span>Liveness</span></div>

							</div>
						</div>
					</div>
				</div>
			);
		} else if (this.state.menu == "docs") {
			const applicantData = this.props.VOI.applicants[this.props.applicant_id].data;
			const documents = applicantData.documents || [];

			return (
				<div className="tab-pane fade show active" id="VOI_Documents">
					<div className="row">
						<div className="col-lg-6">
							{documents.map((document, index) => (
								<div key={index} className="mb-5">
									<h4 className="mb-3">Document {index + 1}</h4>
									{document.front_document && (
										<>
											<img src={document.front_document.url} style={{ width: "100%" }} alt={`Front of document ${index + 1}`} />
											<p className="text-center mt-1">Front</p>
										</>
									)}
									{document.back_document && (
										<div>
											<img src={document.back_document.url} className="mt-5" style={{ width: "100%" }} alt={`Back of document ${index + 1}`} />
											<p className="text-center mt-1">Back</p>
										</div>
									)}
								</div>
							))}
						</div>

						<div className="col-lg-6">
							<table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
								<thead>
									<tr className="text-left text-uppercase">
										<th>Document Info</th>
										<th className="text-right"></th>
									</tr>
								</thead>
								<tbody>
									{this.renderUserDetails()}
								</tbody>
							</table>
							{applicantData.spoof_result && (
								<div className="bg-success text-white p-5 d-flex justify-content-center" style={{ display: "flex" }}>
									<span>{applicantData.spoof_result}</span>
								</div>
							)}
						</div>
					</div>
				</div>
			);
		} else if (this.state.menu == "face") {
			return (
				<div className="tab-pane fade show active">					<div className="row">
					<div className="col-lg-6">
						<div className="">
							<div>
								<p className="text-center">Face from ID Document</p>
							</div>
							<div className="d-flex justify-content-center">
								<img style={{ width: "250px", height: "300px" }} src={this.props.VOI.applicants[this.props.applicant_id].data.documents[0].face_photo.url}></img>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div>
							<p className="text-center">Face from Liveness Video</p>
						</div>
						<div className="d-flex justify-content-center">
							<img style={{ width: "250px", height: "300px" }} src={this.props.VOI.applicants[this.props.applicant_id].data.facematch_best_frame.url}></img>
						</div>
					</div>
				</div>


					<div className="row justify-content-center mt-5">
						<div className="col-lg-10">
							<div className="row">
								{this.renderBreakdown()}
							</div>
							{/* {this.renderBreakdown()} */}
							{/* <div className="d-flex mt-1">
								<div class="bg-success text-white p-2 mr-1 d-flex justify-content-center" style={{display:"flex",width:"33%"}}><span>Right</span></div>
								<div class="bg-success text-white p-2 mr-1 d-flex justify-content-center" style={{display:"flex",width:"33%"}}><span>DETAIL CHECK</span></div>
								<div class="bg-success text-white p-2 d-flex justify-content-center" style={{display:"flex",width:"33%"}}><span>DETAIL CHECK</span></div>
							</div> */}
						</div>
					</div>
				</div>
			);
		} else if (this.state.menu == 'data') {

			const rows = [];
			const fieldDetails = this.props.VOI.applicants[this.props.applicant_id].data.data_provider.DATA_CONNECT.data.fieldDetails;


			if (fieldDetails != undefined) {
				Object.keys(fieldDetails).forEach(key => {
					var tag = key;
					tag = tag.match(/[A-Z][a-z]+/g).join(" ");
					// if (key != "address" && user_details[key] != null) {
					rows.push(
						<tr>
							<td >
								<span class="text-dark-75 font-weight-bolder d-block font-size-lg text-capitalize">{tag}</span>
							</td>
							<td className=""><span className=''>{fieldDetails[key]}</span></td>
						</tr>
					);
					// }


				});
			} else {
				return (<div>
					<p>No Data</p>
				</div>);
			}

			const dataRows = [];
			const dataDetails = this.props.VOI.applicants[this.props.applicant_id].data.device_info;

			if (dataDetails == undefined || dataDetails == null) {
				return <div>
					<p>No Data</p>
				</div>;
			}


			Object.keys(dataDetails).forEach(key => {
				var tag = key;
				var value = dataDetails[key];
				if (key == 'vpn') {
					value = value ? "Yes" : "No";
				}
				// if (key != "address" && user_details[key] != null) {
				dataRows.push(
					<tr>
						<td >
							<span class="text-dark-75 font-weight-bolder d-block font-size-lg text-capitalize">{tag}</span>
						</td>
						<td className=""><span className=''>{value}</span></td>
					</tr>
				);
			});

			if (dataDetails.geolocation != null && dataDetails.ip_location != null) {
				var geolocations = dataDetails.geolocation.split(",");
				var ip_location = dataDetails.ip_location.split(",");
				var distanceBetween = calcCrow(geolocations[0], geolocations[1], ip_location[0], ip_location[1]);
				dataRows.push(
					<tr>
						<td >
							<span class="text-dark-75 font-weight-bolder d-block font-size-lg text-capitalize">Distance (IP vs Location)</span>
						</td>
						<td className=""><span className=''>{distanceBetween.toFixed(2)}km</span></td>
					</tr>
				);
			}


			// return details;
			return (
				<div className="tab-pan fade show active">
					<div className="row">

						<div className='col-lg-6'>
							<table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
								<thead>
									<tr class="text-left text-uppercase">
										<th>Data Matching</th>
										<th className="text-right"></th>
									</tr>
								</thead>



								<tbody>
									{rows}
								</tbody>
							</table>
						</div>

						<div className='col-lg-6'>
							<table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
								<thead>
									<tr class="text-left text-uppercase">
										<th>Device Information</th>
										<th className="text-right"></th>
									</tr>
								</thead>



								<tbody>
									{dataRows}
								</tbody>
							</table>
						</div>

					</div>
				</div>
			);
		}
	};


	render() {
		return (
			<div className="card card-custom card-stretch gutter-b">
				<div className="card-header card-header-tabs-line">
					<div className="card-title">
						<span className="card-label font-weight-bolder text-dark">{this.props.VOI.applicants[this.props.applicant_id].data.user_details.first_name} {this.props.VOI.applicants[this.props.applicant_id].data.user_details.middle_name} {this.props.VOI.applicants[this.props.applicant_id].data.user_details.last_name}</span>
					</div>
					<div className="card-toolbar">
						<ul className="nav nav-tabs nav-bold nav-tabs-line">
							<li class="nav-item cursor-pointer">
								<a class={`nav-link ${this.state.menu == "overview" ? "active" : ""}`} onClick={(e) => { this.setState({ menu: "overview" }); }}>
									<span class="nav-icon">
										<i class="flaticon2-chat-1"></i>
									</span>
									<span class="nav-text">Overview</span>
								</a>
							</li>

							<li class="nav-item cursor-pointer">
								<a class={`nav-link ${this.state.menu == "docs" ? "active" : ""}`} onClick={(e) => { this.setState({ menu: "docs" }); }}>
									<span class="nav-icon">
										<i class="far fa-file"></i>
									</span>
									<span class="nav-text">Documents</span>
								</a>
							</li>

							<li class="nav-item cursor-pointer">
								<a class={`nav-link ${this.state.menu == "face" ? "active" : ""}`} onClick={(e) => { this.setState({ menu: "face" }); }}>
									<span class="nav-icon">
										<i class="far fa-smile"></i>
									</span>
									<span class="nav-text">Face</span>
								</a>
							</li>


							{'data_provider' in this.props.VOI.applicants[this.props.applicant_id].data ?
								<li class="nav-item cursor-pointer">
									<a class={`nav-link ${this.state.menu == "data" ? "active" : ""}`} onClick={(e) => { this.setState({ menu: "data" }); }}>
										<span class="nav-icon">
											<i class="far fa-id-card"></i>
										</span>
										<span class="nav-text">Data</span>
									</a>
								</li>
								: null}
						</ul>
					</div>
				</div>

				<div className="card-body">
					<div class={`bg-${this.props.VOI.applicants[this.props.applicant_id].data.results.overall == "PASSED" ? "success" : "danger"} text-white p-5 d-flex justify-content-center mb-5`}><span>{this.props.VOI.applicants[this.props.applicant_id].data.status} - {this.props.VOI.applicants[this.props.applicant_id].data.results.overall}</span></div>

					{this.renderVOIContent()}
				</div>


			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	VOI: state.admin.active_application.VOI,
	user: state.user
});

const mapDispatchToProps = {
	refreshVOIData
};

export default connect(mapStateToProps, mapDispatchToProps)(VOIData);
