import history from '../history';
import server from '../api/server';
import axios from 'axios';
import firebase from '../components/Firebase.js';
import Cookies from 'universal-cookie';

export const updateData = (updateData) => {
  return async (dispatch, getState) => {
    const id = getState().id;
    delete updateData.state.timeoutID;
    const data = {
      data: updateData.state,
      application_id: id,
      type: updateData.type
    };
    const response = server.post('/factfindform', data);
  };
};

export const checkAuth = (nextFunction) => {
  return async (dispatch, getState) => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        const response = await server.post("/admin/checkAuth", { id: token });
        if (response.data != false) {
          response.data.user.token = token;
          dispatch({ type: "SET_USER", payload: response.data.user });
          dispatch({ type: "SET_ORGANISATION", payload: response.data.organisation });
          dispatch({ type: "SET_AUTH", payload: true });
          dispatch(fetchApplications(token));
          dispatch(fetchEvents());
          if (nextFunction != undefined) {
            dispatch(nextFunction);
          }
          // dispatch()
        } else {
          history.push("/login");
        }
      } else {
        history.push("/login");
        // No user is signed in.
      }
    });
  };
};

export const fetchAdminForm = (id) => {
  return async (dispatch, getState) => {
    const response = await server.post('/admin/fetchAdminForm', { application_id: id });
    if (response.data) {
      if ('data' in response.data) {
        dispatch({ type: "SET_INITIAL_DATA", payload: response.data.data });
      }

      if ('requested_info' in response.data) {
        // dispatch({ type: "SET_REQUESTED_INFO", payload: response.data.requested_info });
      }
      await dispatch({ type: "SET_ID", payload: id });

    } else {
      // dispatch({ type: "SET_ID", payload: 'no-form' });
    }
  };
};


export const fetchForm = (id) => {
  return async (dispatch, getState) => {
    const response = await server.post('/fetchform', { application_id: id });
    if (response.data) {
      if ('data' in response.data) {
        dispatch({ type: "SET_INITIAL_DATA", payload: response.data.data });
      }

      if ('requested_info' in response.data) {
        dispatch({ type: "SET_REQUESTED_INFO", payload: response.data.requested_info });
      }
      await dispatch({ type: "SET_ID", payload: id });

    } else {
      dispatch({ type: "SET_ID", payload: 'no-form' });
    }
  };
};
export const setID = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: "SET_ID", payload: id });
  };
};


export const fetchVOIData = (id) => {
  return async (dispatch, getState) => {
    const response = await server.post("/fetchVOIData", { application_id: id });
    dispatch({ type: "SET_VOI_DATA", payload: response.data });

  };
};

export const updateUploadedFiles = (data) => {
  return async (dispatch, getState) => {
    dispatch({ type: "SET_DOCUMENTSCOLLECTION", payload: data });

  };
};

export const fetchDocCollection = (id) => {
  return async (dispatch, getState) => {
    const response = await server.post("/documentCollection/fetch", { application_id: id });
    dispatch({ type: "SET_DOCUMENTSCOLLECTION", payload: response.data.docs });
    if (response.data.bankstatements != null) {
      dispatch({ type: "SET_BANKSTATEMENTS", payload: response.data.bankstatements });
    }
  };
};

export const updateVOIData = (data) => {
  return async (dispatch) => {
    dispatch({ type: "SET_VOI_DATA", payload: data });
  };
};

export const updateBankStatements = (data) => {
  return async (dispatch) => {
    dispatch({ type: "SET_BANKSTATEMENTS_ADMIN", payload: data });
  };
};

export const fetchVersion = (application_id, version_id, form_id) => {
  return async (dispatch, getState) => {
    const response = await server.post('/admin/version', { version_id: version_id, form_id: form_id, application_id: application_id });
    if (response.data) {
      if ('data' in response.data) {
        response.data.data.date = response.data.date;
        dispatch({ type: "SET_INITIAL_DATA", payload: response.data.data });
      }
      dispatch({ type: "SET_ID", payload: form_id });
    } else {
      dispatch({ type: "SET_ID", payload: "no-form" });
    }
  };
};

export const fetchForms = () => {
  return async (dispatch, getState) => {
    const response = await server.get('/forms');
    if (response.data) {
      dispatch({ type: "SET_FORMS", payload: response.data });
    }
  };
};

export const fetchAdminForms = (token) => {
  return async (dispatch, getState) => {
    const response = await server.post("/forms", { id: token });
    if (response.data !== false) {
      const array = response.data.reverse();
      dispatch({ type: "SET_ADMIN_FORMS", payload: array });
      // this.setState({all_clients:response.data,current_clients:response.data})
    } else {
      // history.push("/login")
    }
  };
};

export const fetchApplications = (token) => {
  return async (dispatch, getState) => {
    console.log(token);
    const response = await server.post("/admin/applications", { id: getState().user.token });
    if (response.data !== false) {
      const array = response.data.reverse();
      console.log(array);
      dispatch({ type: "SET_ADMIN_APPLICATIONS", payload: array });
    }

  };
};

export const fetchApplication = (id) => {
  return async (dispatch, getState) => {
    const response = await server.post("/admin/application/", { id: getState().user.token, application_id: id });
    if (response.data !== false) {
      const data = response.data;
      // dispatch(checkAuth(fetchApplication(id)))
      dispatch({ type: "SET_APPLICATION_DATA", payload: data });
    } else {
      dispatch(checkAuth(fetchApplication(id)));
    }
  };
};

export const addClient = (client) => {
  return async (dispatch, getState) => {
    const response = await server.post("/admin/applicant/create", { id: getState().user.token, client: client, application_id: getState().admin.active_application.application.id });
    if (response.data == true) {
      dispatch(fetchApplication(getState().admin.active_application.application.id));
    } else {
      dispatch(checkAuth(addClient(client)));
    }
  };
};

export const editClient = (client, id) => {
  return async (dispatch, getState) => {
    const response = await server.post("/admin/applicant/edit", { client: client, client_id: id, application_id: getState().admin.active_application.application.id });
    if (response.data == true) {
      dispatch(fetchApplication(getState().admin.active_application.application.id));
    } else {
      dispatch(checkAuth(addClient(client)));
    }
  };
};

export const fetchEvents = (client) => {
  return async (dispatch, getState) => {
    const response = await server.post("/admin/events", { id: getState().user.token, organisation_id: getState().organisation.id });

    if (response.data === false) {
      console.log(response.data, 'REPONSE DATA');
      dispatch(checkAuth(fetchEvents()));
    } else {
      dispatch({ type: "SET_EVENTS", payload: response.data });
    }
    // if(response.data == true){
    //   dispatch(fetchApplication(getState().admin.active_application.application.id))
    // }else{
    //   dispatch(checkAuth(addClient(client)))
    // }
  };
};

export const setLastEvent = (date) => {
  return async (dispatch, getState) => {
    var cookies = new Cookies();
    cookies.set("last_event", date);
    dispatch({ type: "SET_LAST_EVENT", payload: date });
    // if(response.data == true){
    //   dispatch(fetchApplication(getState().admin.active_application.application.id))
    // }else{
    //   dispatch(checkAuth(addClient(client)))
    // }
  };
};

export const deleteClient = (applicant_id) => {
  return async (dispatch, getState) => {

    const response = await server.post("/admin/applicant/delete", { id: getState().user.token, applicant_id: applicant_id, application_id: getState().admin.active_application.application.id });
    if (response.data == true) {
      window.location.reload();
      // dispatch(fetchApplication(getState().admin.active_application.application.id))
    } else {
      dispatch(checkAuth(deleteClient(applicant_id)));
    }
  };
};

export const refreshVOIData = (id) => {
  return async (dispatch, getState) => {
    const response = await server.post("/voi", { transaction_id: id, id: getState().user.token });
    if (response.data == false) {
      dispatch(checkAuth(refreshVOIData(id)));
    } else {
      dispatch({ type: "REFRESH_VOI_DATA", payload: response.data });
    }

  };
};


export const setVOIData = (voi_data) => {
  return async (dispatch) => {
    dispatch({ type: "REFRESH_VOI_DATA", payload: voi_data });
  };
};


export const logIn = (email, password) => {
  return async (dispatch, getState) => {
    await firebase.auth().signInWithEmailAndPassword(email, password)
      .then((user) => {
        dispatch({ type: "SET_AUTH", payload: true });
        // Signed in 
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
      });
  };
};

export const goToClientPage = (client_data) => {
  return async (dispatch, getState) => {
    dispatch({ type: "SET_CLIENT_DATA", payload: client_data });
    dispatch({ type: "CHANGE_ADMIN_PAGE", payload: "client_page" });
  };
};

export const changeAdminPage = (page) => {
  return async (dispatch, getState) => {
    dispatch({ type: "CHANGE_ADMIN_PAGE", payload: page });
  };
};

export const createApplication = (applicationData) => {
  return async (dispatch, getState) => {
    // firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(async (idToken) => {
    const response = await server.post("/admin/application/create", { id: getState().user.token, name: applicationData.name, applicants: applicationData.applicants, created_by: getState().user.name });
    if (response.data == false) {
      dispatch(checkAuth(createApplication(applicationData)));
    } else {
      dispatch(fetchApplications());
    }
    // }).catch(function(error) {
    //     history.push("/login")
    // });
  };
};

export const fetchRequestedInfo = (id) => {
  return async (dispatch, getState) => {
    const response = await server.post("/requestedInfo", { application_id: id });
    if (response.data) {
      dispatch({ type: "SET_REQUESTED_INFO", payload: response.data });
    }
  };
};

export const setOrganisation = (organisation) => {
  return async (dispatch, getState) => {
    dispatch({ type: "SET_ORGANISATION", payload: organisation });
  };
};