import React from 'react';
import {connect} from 'react-redux'



class AdminSearch extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            search_term:""
        }
      }


    componentDidMount() {


    }

    getName = (form) =>{
        var name = ""
        if(form.completed == true){
            name = `${form.data.General_Details.applicant1.given_name} ${form.data.General_Details.applicant1.family_name}`
        }

        if(form.completed && form.data.General_Details.applicant2 != undefined &&  'given_name' in form.data.General_Details.applicant2 && form.data.General_Details.applicant2.given_name != "" && 'family_name' in form.data.General_Details.applicant2 && form.data.General_Details.applicant2.family_name != "" ){
            name = name + ` & ${form.data.General_Details.applicant2.given_name} ${form.data.General_Details.applicant2.family_name}`
        }

        return name
    }

    getEmail = (form) =>{
        var email = "";
        if(form.data && "General_Details" in form.data && "applicant1" in form.data.General_Details && 'email' in form.data.General_Details.applicant1 ){
            email = form.data.General_Details.applicant1.email
        }
        return email
    }
    onInputChange = (e) =>{
        const search_term = e.target.value.toLowerCase()
        var search_results = []
        if(search_term != ""){
            this.props.forms.forEach(form =>{
                const name = this.getName(form) || form.name;
                const email = this.getEmail(form) || form.email;
                if(name.toLowerCase().includes(search_term) || email.toLowerCase().includes(search_term)){
                    search_results.push(form)
                }
            })
            this.props.setResults(search_results,true);
        }else{
            this.props.setResults(this.props.forms,false);
        }

        this.setState({search_term:e.target.value})


    }

  

    render(){
        return(
            <div>
            <form className="ui form">
                <h2>Search</h2>
                <input  className="admin-search" onChange={(e) =>{this.onInputChange(e)}} value={this.state.search_term}  type="text" placeholder="Search"></input>
            </form>    
            </div>
        )
    }

}



export default AdminSearch
