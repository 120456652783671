import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../api/server';
import firebase from './Firebase';
import { Helmet } from 'react-helmet';
import history from '../history';

export class ClientAuthRedirect extends Component {

    componentDidMount() {

        // Confirm the link is a sign-in with email link.
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            // Get email from localStorage
            var email = window.localStorage.getItem('emailForSignIn');

            // If no email in localStorage, try to get it from the URL
            if (!email) {
                const urlParams = new URLSearchParams(window.location.search);
                email = urlParams.get('email');
                
                // Decode the email and replace "%40" with "@"
                if (email) {
                    email = decodeURIComponent(email).replace("%40", "@");
                }
            }

            // If still no email, prompt the user
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }

            console.log(email, "EMAIL HERE");
            // The client SDK will parse the code from the link for you.
            firebase.auth().signInWithEmailLink(email, window.location.href)
                .then(async (result) => {
                    console.log(result);
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');
                    var response = await server.post("/client/auth/redirect");
                    if (response && response.data?.success != false) {
                        var application_id = response.data.application_id;
                        history.push(`/dashboard/${application_id}`);
                    }

                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                })
                .catch((error) => {
                    console.log(error);
                    // Else redirect to login page
                    history.push("/client");
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                });
        } else {
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    console.log('logged in.');
                    var response = await server.post("/client/auth/redirect");
                    if (response && response.data?.success != false) {
                        var application_id = response.data.application_id;
                        history.push(`/dashboard/${application_id}`);
                    }
                    // User is signed in.
                } else {

                    history.push("/client");
                    // No user is signed in.
                }
            });
        }
    }

    authRedirect = async () => {
        var response = await server.post("/client/auth/redirect");
    };
    render() {

        return (
            <div className="min-h-screen bg-gray-100">
                <Helmet>
                    <title>Loan Gateway - Mortgage Choice Sydney CBD</title>
                    <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
                </Helmet>
                <div className="flex flex-col items-center justify-center min-h-screen p-4">
                    <div className="text-center">
                        <img 
                            className="mx-auto w-full max-w-xs h-auto mb-8"
                            src={process.env.PUBLIC_URL + '/images/logos/dark_transparent.png'} 
                            alt="Loan Gateway Logo" 
                        />
                        <h2 className="text-2xl font-bold text-gray-800 mb-2">Redirecting...</h2>
                        <p className="text-gray-600">Please wait while we process your authentication.</p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ClientAuthRedirect);