import React, { Component } from 'react'
import { connect } from 'react-redux'

// import {changeAdminPage} from "../../actions"

import history from "../../history"


export class Aside extends Component {

    render() {
        return (
            <div class="aside aside-left aside-fixed d-flex flex-column flex-row-auto" id="kt_aside">
                <div class="brand flex-column-auto" id="kt_brand">


                    <a  class="brand-logo">
                        <img alt="Logo" style={{height:"30px"}} src={process.env.PUBLIC_URL + "/images/logos/light_transparent.png"}/>
                    </a>

                    <button class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
                        <span class="svg-icon svg-icon svg-icon-xl">


                        </span>
                    </button>


                </div>

                <div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
                    <div id="kt_aside_menu" class="aside-menu my-4" data-menu-vertical="1" data-menu-scroll="1" data-menu-dropdown-timeout="500">
                        <ul class="menu-nav">
                            <li class="menu-item" aria-haspopup="true">
                                <a  class="menu-link" onClick={(e) =>{history.push("/admin/applications")}}>
                                    <span class="svg-icon menu-icon">
                                        {/* Icon Here */}

                                        <i class="ki ki-bold-sort icon-md"></i>

                                    </span>
                                    <span class="menu-text">Dashboard</span>
                                </a>
                            </li>

                            <li class="menu-section">
                                <h4 class="menu-text">Applications</h4>
                                <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
                            </li>

                            <li class="menu-item" aria-haspopup="true">
                                <a onClick={(e) =>{history.push("/admin/applications")}} class="menu-link">
                                    <span class="svg-icon menu-icon">
                                    <i class="la la-users"></i>



                                    </span>
                                    <span class="menu-text">Applications</span>
                                </a>
                            </li>

                            <li class="menu-section">
                                <h4 class="menu-text">Settings</h4>
                                <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
                            </li>

                            <li class="menu-item" aria-haspopup="true">
                                <a onClick={(e) =>{history.push("/admin/settings/uploads")}} class="menu-link">
                                    <span class="svg-icon menu-icon">
                                    <i class="la la-upload"></i>



                                    </span>
                                    <span class="menu-text">Document Collection</span>
                                </a>
                            </li>

                            <li class="menu-item" aria-haspopup="true">
                                <a onClick={(e) =>{history.push("/admin/settings/billing")}} class="menu-link">
                                    <span class="svg-icon menu-icon">
                                    <i class="la la-file-invoice-dollar"></i>



                                    </span>
                                    <span class="menu-text">Billing</span>
                                </a>
                            </li>

                            <li class="menu-item" aria-haspopup="true">
                                <a onClick={(e) =>{history.push("/admin/settings/agents")}} class="menu-link">
                                    <span class="svg-icon menu-icon">
                                    <i class="la la-user"></i>



                                    </span>
                                    <span class="menu-text">Agents</span>
                                </a>
                            </li>


                            {/* <li class="menu-section">
                                <h4 class="menu-text">Settings</h4>
                                <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
                            </li> */}

                            {/* <li class="menu-item" aria-haspopup="true">
                                <a onClick={(e) =>{history.push("/newadmin/applications")}} class="menu-link">
                                    <span class="svg-icon menu-icon">
                                    <i class="la la-user-astronaut"></i>



                                    </span>
                                    <span class="menu-text">Users</span>
                                </a>
                            </li> */}

                        </ul>


                    </div>


                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Aside)
