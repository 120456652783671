import update from 'immutability-helper';
const INIT_STATE = {
    fact_find:false,
    id:false,
    docs:false
};


export default (state = INIT_STATE,action) => {
    switch(action.type){
      case 'SET_REQUESTED_INFO':
        return action.payload
      default:
        return state
    }
  }
  