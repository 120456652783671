import React from 'react';
import {connect} from 'react-redux'

import history from '../history'
import firebase from './Firebase.js'

class PrivacyPolicy extends React.Component{
    constructor(props){
        super(props)
        this.state = {

        }
      }
    componentDidMount() {
    }




    render(){
        return(
            <div>
                <iframe src="http://docs.google.com/gview?url=http://localhost:3000/pdfs/Credit_Guide.pdf&embedded=true" 
 frameborder="0" style={{width:"100%",height:"100vh"}}></iframe>
            </div>
        )
    }

}

const mapStateToProps = (state) =>{
  return {id:state.id}
}

export default connect(mapStateToProps)(PrivacyPolicy)
